export default {
  Region: "us-east-1",
  CognitoUserPoolId: "us-east-1_DbMolYlpd",
  CognitoClientId: "52c1r595esd9d7k3dd0id60k8b",
  IdentityPoolId: "us-east-1:f8cf5bea-be4b-41c4-b934-0f9624d09bd0",
  CognitoIdentityCredentialsKey:
    "cognito-idp.us-east-1.amazonaws.com/us-east-1_DbMolYlpd",
  S3APIVersion: ["2006-03-01"],
  S3BucketName: ["mqsa-doc-conversion-nodejs"],
};
