import React from "react";
import styled from "styled-components";
import { Button, Loader, Alert2SVG, SuccessSVG } from "../Containers";

export const PrintShopProgressbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  font-size: 15px;
  width: 230px;
  border: solid 1px rgb(49, 118, 242);
  border-radius: 7px;
  color: rgb(49, 118, 242);
  opacity: 0.8;
  height: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:not(:first-child) {
    margin-left: 15px;
  }
  svg {
    height: 24px;
    width: 24px;
    stroke: rgb(49, 118, 242);
    margin: unset !important;
    margin-right: 10px !important;
  }
`;

export const PrintShopProgressBarButton = ({
  hasSelected,
  status,
  SVG,
  children,
  ...rest
}) => (
  <StyledButton disabled={!hasSelected} {...rest}>
    {status === "pending" ? (
      <Loader />
    ) : status === "success" ? (
      <SuccessSVG />
    ) : status === "error" ? (
      <Alert2SVG />
    ) : SVG ? (
      <SVG height={29} width={29} />
    ) : (
      ""
    )}
    {children}
  </StyledButton>
);
