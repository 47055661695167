export default {
  facility: "Facility",
  facilities: "Facilities",
  printshop: "Print Shop",
  inquiries: "Inquiries and Logs",
  reports: "Reports",
  open: "Open Inquiries",
  ticket: "Ticket",
  unreconciled: "Unreconciled Payments",
  reconciled: "Reconciled Payments",
  invoice: "Invoice",
  cert: "Certificates & Letters",
  expirationLetters: "Expiration Letters",
  interim: "Interim Certificates",
  //
  fac_id: "Facility ID",
  fac_name: "Facility Name",
  cert_type: "Current Certificate Type",
  cert_num: "Certificate Number",
  mail_date: "Mail Date",
  bill_date: "Original Bill Date",
  late_notice_type: "Billing Status",
  inv_num: "Invoice Number",
  bill_amt: "Bill Amount",
  tkt_id: "Ticket ID"
};
