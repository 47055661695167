import React, { Fragment } from "react";
import NavMain from "./NavMain";
import NavBreadcrumbs from "./NavBreadcrumbs";

const Nav = () => (
  <Fragment>
    <NavMain />
    <NavBreadcrumbs />
  </Fragment>
);

export default Nav;
