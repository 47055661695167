import { EmailSVG, PasswordSVG } from "../Containers";
import AuthSignInSubmitHandler from "./AuthSignInSubmitHandler";

const AuthSignInSchema = ({ successSource, componentState }) => ({
  id: "sign-in",
  components: [
    [
      {
        component: "FormMessageSuccess",
        message:
          successSource === "reset-password" &&
          "Password reset successful. Please sign in with your new password.",
      },
      {
        component: "FormInputOne",
        type: "email",
        autoComplete: "username",
        name: "email",
        label: "Email Address",
        placeholder: "Enter Email Address",
        SVG: EmailSVG,
        required: true,
      },
      {
        component: "FormInputOne",
        type: "password",
        autoComplete: "current-password",
        name: "password",
        label: "Password",
        placeholder: "Enter Password",
        SVG: PasswordSVG,
        minLength: 8,
        required: true,
      },
      {
        component: "FormSubmitButton",
        flavor: "blueButtonBig",
        label: "SIGN IN",
        name: "submit_signin",
        title: "Submit sign up form.",
        onSubmit: {
          customHandler: (props) =>
            AuthSignInSubmitHandler({ ...props, componentState }),
        },
      },
      {
        component: "FormLinkOne",
        to: "/forgot-password",
        label: "Forgot your password?",
      },
      {
        component: "FormMessageError",
      },
    ],
  ],
});

export default AuthSignInSchema;
