const FacilityTableStyle = `
  table {
    width: 100%;
    border-spacing: 0;
    tr:last-child td {
      border-bottom: 0;
    }
    tbody tr:nth-child(odd) {
      background: rgba(221, 235, 255, 0.333998);
    }
    th,
    td {
      margin: 0;
      padding: 20px;
      text-align: left;
      border-bottom: 1px solid rgba(211, 211, 211, 0.40259);
      :last-child {
        border-right: 0;
      }
    }
  }
`;

export default FacilityTableStyle;
