import React from "react";

const TableExclamationError = ({ onClick, key, ...rest }) => ({
  Header: "Error?",
  accessor: (rest && rest.key) || "check_flag",
  width: 200,
  Cell: (e) => (
    <div>
      {e.value === "Y" && (
        <div style={{ color: "red", textAlign: "center" }}>!!</div>
      )}
    </div>
  ),
});

export default TableExclamationError;
