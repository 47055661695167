import React from "react";
import styled from "styled-components";

const StyledFacilityTitle = styled.h1`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #1f1f1f;
  text-align: center;
`;

const FacilityTitle = ({ fac_id, fac_name }) => (
  <StyledFacilityTitle>
    #{fac_id} - {fac_name}
  </StyledFacilityTitle>
);

export default FacilityTitle;
