import React, { Fragment } from "react";
import { PageTitle } from "../Containers";
import { DashboardCards, DashboardCard } from "../PageDashboard/DashboardCardStyled";
import { Button } from "../Containers";

const PageInquiriesLogs = (props) => {
  
  return (
    <Fragment>
      <PageTitle>Inquiries and Logs</PageTitle>
      <DashboardCards>
      <Button to="/inquiries/certification">
        <DashboardCard>
            Certification
        </DashboardCard>
      </Button>
      <Button href="http://mqsa.versatechinc.com/">
        <DashboardCard>
            Policy and Regulation
        </DashboardCard>
      </Button>
        </DashboardCards>
    </Fragment>
  );
};

export default PageInquiriesLogs;
