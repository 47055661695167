import React, { useState, Fragment } from "react";
import FacilityTitle from "./FacilityTitle";
import FacilityMenu from "./FacilityMenu";
import FacilityMainInformation from "./FacilityMainInformation";
import FacilityTemplate from "./FacilityTemplate";
import FacilityTemplateCertification from "./FacilityTemplateCertification";
import FacilityTemplateLetter from "./FacilityTemplateLetter";
import styled from "styled-components";

const FacilityMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;

function Facility({ data }) {
  const { fac_id, fac_name } =
    typeof data === "string" ? {} : data.facility_info;

  const nav = [
    {
      machine_name: "facilityInformation",
      label: "Facility Information",
    },
    {
      machine_name: "certification",
      label: "Certificate History",
    },
    {
      machine_name: "letter",
      label: "Letter History",
    },
  ];

  const [currentTab, setCurrentTab] = useState(nav[0].machine_name);
  const FacilityMainResolver = {
    facilityInformation: FacilityMainInformation,
    certification: (passProps) => (
      <FacilityTemplate
        template={FacilityTemplateCertification}
        {...passProps}
      />
    ),
    letter: (passProps) => (
      <FacilityTemplate template={FacilityTemplateLetter} {...passProps} />
    ),
  };

  const FacilityMain = FacilityMainResolver[currentTab];
  return (
    <Fragment>
      <FacilityTitle fac_id={fac_id} fac_name={fac_name} />
      <FacilityMenu
        nav={nav}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <FacilityMainContainer>
        <FacilityMain {...data} />
      </FacilityMainContainer>
    </Fragment>
  );
}

export default Facility;
