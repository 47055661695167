import React from "react";
import { Button } from "../Containers";
import constants from "../../constants";

const TableColumnFacId = (props) => ({
  Header: constants.labelAlias.fac_id,
  accessor: (props && props.key) || "fac_id",
  width: 100,
  Cell: (e) => (
    <Button
      flavor="blueLink"
      target={props && props.openNewTab === false ? "_self" : "_blank"}
      href={"/facilities/" + e.value}
      style={{ justifyContent: "flex-start" }}
    >
      {e.value}
    </Button>
  ),
});

export default TableColumnFacId;
