import styled from "styled-components";

export const StandardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StandardLabel = styled.label`
  text-align: left;
  margin-bottom: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #0e0e0e;
  mix-blend-mode: normal;
  &::after {
    content: ${({ excludeColon }) => (excludeColon === true ? "" : `":"`)};
  }
`;

export const HeaderLabel = styled.label`
  text-align: left;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  color: #0e0e0e;
  mix-blend-mode: normal;
`;

export const ErrorLabel = styled.label`
  text-align: left;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: red;
  mix-blend-mode: normal;
`;

export const StandardInputStyle = `
  position: relative;
  box-sizing: border-box;
  height: 28px;
  min-width: 140px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border: 0.5px solid #979797;
`;

export const StandardInput = styled.input`
  ${StandardInputStyle}
`;

export const StandardInputDiv = styled.div`
  ${StandardInputStyle}
`;
