import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Loader, Placeholder } from "../Containers";
import { fetcher, errorString } from "../../utils";
import constants from "../../constants";

const StyledDiv = styled.div`
  flex-grow: 1;
  padding: ${({ hideGutters }) => (hideGutters ? "0" : "15px 25px")};
  display: flex;
  flex-direction: column;
`;
/*
params would be an array of objects and authority and resource
*/
function GetMultipleComponent({
  hideGutters,
  params: _params,
  authority,
  resource,
  Component,
  ...rest
}) {
  const [pagePending, setPagePending] = useState(true);
  const [componentPending, setComponentPending] = useState(true);
  const [error, setError] = useState();
  const [params, setParams] = useState(_params);
  const [ignoreRequest, setIgnoreRequest] = useState();
  const [data, setData] = useState();
  const fetchData = () => {
    !data && setPagePending(true);
    setComponentPending(true);
    setError();
    Promise.all([
      fetcher.get({
        authority: authority[0],
        path: constants.api.path[resource[0]],
        params: params[0],
      }),
      fetcher.get({
        authority: authority[1],
        path: constants.api.path[resource[1]],
        params: params[1],
      }),
    ])
      .then(function (responses) {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map(function (response) {
            return response;
          })
        );
      })
      .then(function (data) {
        // Log the data to the console
        // You would do something with both sets of data here
        console.log(data);
        setData(data);
      })
      .catch(function (error) {
        // if there's an error, log it
        console.log(error);
        setError(error);
      })
      .finally(() => {
        !ignoreRequest && setPagePending();
        !ignoreRequest && setComponentPending();
      });
  };

  useEffect(() => {
    return () => setIgnoreRequest(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [params]);

  const errorTitle =
    "Error: " + (errorString(error) || "Failed to get data") + ".";

  return (
    <StyledDiv hideGutters={hideGutters}>
      {pagePending ? (
        <Loader height={250} width={250} />
      ) : error || !data ? (
        <Placeholder
          title={errorTitle}
          subtitle={
            <Button onClick={fetchData}>Click here to try again.</Button>
          }
        />
      ) : (
        <Component
          fetchData={fetchData}
          data={data}
          setData={setData}
          params={params}
          setParams={setParams}
          pending={componentPending}
          {...rest}
        />
      )}
    </StyledDiv>
  );
}

export default GetMultipleComponent;
