import React, { Fragment, useState } from "react";
import styled from "styled-components";
import FacilityTableStyle from "./FacilityTableStyle";
import { Button } from "../Containers";
import FacilityMainInformationPOCContactInfo from "./FacilityMainInformationPOCContactInfo";
import { TableCore, TableColumn } from "../Table";
import { fetcher } from "../../utils";
import constants from "../../constants";
import Loader from "../Containers/Loader";

const Styles = styled.div`
  padding: 25px 0;
  ${FacilityTableStyle}
`;

function FacilityMainInformationPOCTable({
  data = [],
  setData,
  setShowPOCForm,
}) {
  const [pending, setPending] = useState();
  const [deletedPOCs, setDeletedPOCs] = useState([]);
  const [contactInfoModal, setContactInfoModal] = useState();

  function deletePOC(pocID) {
    setPending(true);
    fetcher
      .post({
        authority: "test",
        path: constants.api.path.pointOfContact,
        data: {
          operation: "Delete",
          poc_record: {
            poc_id: pocID,
          },
        },
      })
      .then(() =>
        setDeletedPOCs((oldDeletedPOCs) => [...oldDeletedPOCs, pocID])
      )
      .catch((err) => {
        throw err;
      })
      .finally(setPending);
  }

  data = data.filter((i) => !deletedPOCs.includes(i.poc_id));

  const columns = React.useMemo(
    () => [
      TableColumn.view({
        onClick: (e) => setContactInfoModal(e.cell.row.original),
      }),
      {
        Header: "TITLE",
        accessor: "poc_title",
      },
      {
        Header: "NAME",
        accessor: "poc_full_name",
      },
      {
        Header: "EMAIL ADDRESS",
        accessor: "poc_email",
      },
      {
        Header: "POC TYPE",
        accessor: "poc_type",
      },
      {
        Header: "PRIMARY NUMBER",
        accessor: "poc_primary_phone",
      },
      {
        accessor: "poc_id",
        Cell: (e) =>
          e.row.original.poc_type === "Other" ? (
            <Button flavor="redLink" onClick={() => deletePOC(e.value)}>
              {pending ? "Deleting..." : "Delete"}
            </Button>
          ) : (
            ""
          ),
      },
    ],
    // eslint-disable-next-line
    [pending]
  );

  return (
    <Fragment>
      {pending ? <Loader height={250} width={250} />
      : 
  (<>
<Styles>
        <TableCore columns={columns} data={data}/>
      </Styles>
      <FacilityMainInformationPOCContactInfo
        data={contactInfoModal}
        closeModal={() => {
          setContactInfoModal();
          setShowPOCForm();
        }}
        setShowPOCForm={setShowPOCForm}
      />
      </>
  )
      }
      
    </Fragment>
  );
}

export default FacilityMainInformationPOCTable;
