import React from "react";
import { Button, PencilBlueSVG } from "../Containers";

const TableColumnPencil = ({ onClick, ...rest }) => {
  return {
    id: "edit",
    Cell: (e) => (
      <div>
        <Button onClick={() => onClick(e)}>
          <PencilBlueSVG />
        </Button>
      </div>
    ),
  };
};

export default TableColumnPencil;
