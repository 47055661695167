import api from "./api";
import application from "./application";
import aws from "./aws";
import labelAlias from "./labelAlias";
import printShop from "./printShop";

export default {
  api,
  application,
  aws,
  labelAlias,
  printShop,
};
