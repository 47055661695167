import React, { useState, useEffect } from "react";
import { useTable, useExpanded } from "react-table";
import TableHeaderWithSort from "./TableHeaderWithSort";
import TableColumn from "./TableColumn";

function TableCore({
  excludeSort,
  columns: initColumns,
  selectColumnProps,
  data,
  params,
  setParams,
  pending,
}) {
  const hasSubRows = data.some((i) => i.subRows && i.subRows.length);
  const getColumns = () =>
    [
      hasSubRows && TableColumn.expand,
      selectColumnProps && TableColumn.select(selectColumnProps),
      ...initColumns,
    ].filter(Boolean);

  const [columns, setColumns] = useState(getColumns());

  useEffect(() => {
    setColumns(getColumns());
    // eslint-disable-next-line
  }, [selectColumnProps]);

  const hasSelect = Boolean(selectColumnProps);

  columns.forEach((column, index) => (columns[index].sortValue = index));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, hasSubRows && useExpanded);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) =>
              params && !excludeSort ? (
                <TableHeaderWithSort
                  {...column}
                  key={index}
                  params={params}
                  setParams={setParams}
                  hasSelect={hasSelect}
                />
              ) : (
                <th {...column.getHeaderProps()} style={{ cursor: "auto" }}>
                  {column.render("Header")}
                </th>
              )
            )}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TableCore;
