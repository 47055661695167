import { errorString } from "../../utils";

// Refer to use case 23:
// https://www.npmjs.com/package/amazon-cognito-identity-js

function AuthResetPasswordSubmitHandler({
  setLoading,
  setError,
  values: { verificationCode, password },
  componentState: { cognitoUser, history },
}) {
  setLoading(true);
  setError(null);
  cognitoUser.confirmPassword(verificationCode, password, {
    onSuccess: () => history.push("/sign-in?success=reset-password"),
    onFailure: (err) => {
      setError(errorString(err));
      setLoading(null);
    },
  });
}

export default AuthResetPasswordSubmitHandler;
