import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Loader, Placeholder } from "../Containers";
import { fetcher, errorString } from "../../utils";
import constants from "../../constants";

const StyledDiv = styled.div`
  flex-grow: 1;
  padding: ${({ hideGutters }) => (hideGutters ? "0" : "15px 25px")};
  display: flex;
  flex-direction: column;
`;

function GetComponent({
  hideGutters,
  params: _params,
  authority,
  resource,
  Component,
  ...rest
}) {
  const [pagePending, setPagePending] = useState(true);
  const [componentPending, setComponentPending] = useState(true);
  const [error, setError] = useState();
  const [params, setParams] = useState(_params);
  const [ignoreRequest, setIgnoreRequest] = useState();
  const [data, setData] = useState();

  const fetchData = () => {
    !data && setPagePending(true);
    setComponentPending(true);
    setError();
    fetcher
      .get({
        authority,
        path: constants.api.path[resource],
        params,
      })
      .then((responseData) => {
        setData(responseData);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        !ignoreRequest && setPagePending();
        !ignoreRequest && setComponentPending();
      });
  };

  useEffect(() => {
    return () => setIgnoreRequest(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [params]);

  const errorTitle =
    "Error: " + (errorString(error) || "Failed to get data") + ".";

  return (
    <StyledDiv hideGutters={hideGutters}>
      {pagePending ? (
        <Loader height={250} width={250} />
      ) : error || !data ? (
        <Placeholder
          title={errorTitle}
          subtitle={
            <Button onClick={fetchData}>Click here to try again.</Button>
          }
        />
      ) : (
        <Component
          fetchData={fetchData}
          data={data}
          setData={setData}
          params={params}
          setParams={setParams}
          pending={componentPending}
          {...rest}
        />
      )}
    </StyledDiv>
  );
}

export default GetComponent;
