import React, {useState} from "react";
import { Button, Loader } from "../Containers";



const FormFileInput = ({
  value,
  name,
  ...rest
}) =>{
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const selectFile = (event) => {
    console.log(event.target.files)
    setSelectedFiles(event.target.files);
  }
 /* options = options.map((option) => ({
    onClick: () => onChange(option),
    label: option, {...rest} 
  }*/ 

return (
  <input type="file" name={name} value={selectedFiles} onChange={selectFile} {...rest}/>
);
}

export default FormFileInput;
