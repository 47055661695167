import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Button } from "../Containers";
import { Form } from "../Form";

const NotesContainer = styled.div`
  padding: 0 20px 20px;
  white-space: break-spaces;
  form#edit-notes div:first-child {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  justify-content: flex-end;
  margin: 15px 15px 30px;
`;

function FacilityMainInformationNotes({ fac_notes, fac_id }) {
  const [showForm, setShowForm] = useState(false);

  const formConfig = {
    id: "edit-notes",
    components: [
      {
        component: "FormTextareaOne",
        name: "notes",
        required: true,
      },
      {
        component: "FormSubmitButtonBlueRed",
        submitButton: {
          label: "SAVE",
          name: "submit_edit_notes",
          onSubmit: {
            authority: "test",
            postAPIKey: "facilities",
            transformValues: (values) => ({
              operation: "Update",
              fac_notes: { fac_id, ...values },
            }),
            onSuccess: () => window.location.reload(),
          },
        },
        redButton: {
          label: "CANCEL",
          name: "cancel_edit_notes",
          onClick: () => setShowForm(false),
        },
      },
      {
        component: "FormMessageError",
      },
    ],
  };

  return (
    <Fragment>
      <StyledButton
        flavor="blueLink"
        onClick={() => (showForm ? setShowForm(false) : setShowForm(true))}
      >
        {showForm ? "Show Notes" : "Edit Notes"}
      </StyledButton>
      <NotesContainer>
        {showForm ? (
          <Form {...{ ...formConfig, initialValues: { notes: fac_notes } }} />
        ) : (
          fac_notes
        )}
      </NotesContainer>
    </Fragment>
  );
}

export default FacilityMainInformationNotes;
