import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import CognitoUserPool from "./AuthAWSCognitoUserPool";
import { errorString } from "../../utils";

// Refer to use case 4:
// https://www.npmjs.com/package/amazon-cognito-identity-js

function AuthFormSchemaSignInOnSubmitHandler({
  setLoading,
  setError,
  values: { email: Username, password: Password },
  componentState: { clearSession, setCognitoUser, history },
}) {
  setLoading(true);
  setError(null);

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username,
    Pool: CognitoUserPool,
  });

  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    { Username, Password }
  );

  function onFailure(err) {
    clearSession();
    setLoading(null);
    setError(errorString(err));
  }

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: () => (window.location = "/"),
    onFailure,
    newPasswordRequired: (userAttributes) =>
      userAttributes &&
      userAttributes.email_verified &&
      userAttributes.email === Username
        ? (setCognitoUser(cognitoUser), history.push("/new-password"))
        : onFailure(
            "Your email has not been verified, please notify your system admin."
          ),
  });
}

export default AuthFormSchemaSignInOnSubmitHandler;
