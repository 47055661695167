import React, { Fragment } from "react";
import {
  Modal,
  LabelTwo,
  PencilWhiteSVG,
  LabelOne,
  Button,
} from "../Containers";
import styled from "styled-components";

const StyledPencilSVG = styled(PencilWhiteSVG)`
  position: absolute;
  right: 25px;
  top: 13px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  margin: 15px 25px 0 auto;
`;

const ColumnsContainer = styled.div`
  display: flex;
`;

const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-right: 140px;
`;

const ColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 90px;
`;

const ColumnTitle = styled(LabelOne)`
  margin-bottom: 8px;
`;

const ColumnValue = styled.span`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #1f1f1f;
  mix-blend-mode: normal;
  opacity: 0.6;
  margin-bottom: 30px;
`;

const rowHeight = 74;

const FacilityMainInformationPOCContactInfo = ({
  data,
  closeModal,
  setShowPOCForm,
}) =>
  data ? (
    <Modal close={closeModal}>
      <LabelTwo>
        Contact Information
        {data.poc_type === "Other" ? (
          <StyledPencilSVG
            onClick={() =>
              setShowPOCForm({
                title: "Edit Contact Information",
                operation: "Update",
                initialValues: data,
                poc_id: data.poc_id
              })
            }
          />
        ) : null}
      </LabelTwo>
      <StyledButton flavor="blueLink" onClick={closeModal}>
        {"< Back"}
      </StyledButton>
      <ColumnsContainer>
        {[
          [
            { label: "POC Type", value: data.poc_type },
            {
              label: "Title",
              value: data.poc_title,
            },
            {
              label: "Email Address",
              value: data.poc_email,
            },
            {
              label: "Secondary Number",
              value: data.poc_secondary_phone,
            },
            {
              label: "Address",
              value: [
                data.poc_addline1,
                data.poc_addline2,
                data.poc_addline3,
                data.poc_city +
                  (data.poc_city && data.poc_state ? ", " : "") +
                  data.poc_state +
                  " " +
                  data.poc_zip,
              ]
                .filter(Boolean)
                .map((i, index) => <span key={index}>{i}</span>),
            },
          ],
          [
            { height: rowHeight },
            {
              label: "Name",
              value: data.poc_full_name,
            },
            {
              label: "Primary Number",
              value: data.poc_primary_phone,
            },
            {
              label: "Fax Number",
              value: data.poc_fax,
            },
          ],
          [
            { height: rowHeight * 2 },
            {
              label: "Ext",
              value: data.poc_primary_phone_extn,
            },
          ],
        ].map((column, columnIndex) => {
          const ColumnWithMargin = !columnIndex ? ColumnOne : ColumnTwo;
          return (
            <ColumnWithMargin key={columnIndex}>
              {column.map((columnField, columnFieldIndex) =>
                columnField.height ? (
                  <span
                    key={columnFieldIndex}
                    style={{ height: columnField.height }}
                  />
                ) : (
                  <Fragment key={columnFieldIndex}>
                    <ColumnTitle>{columnField.label}:</ColumnTitle>
                    <ColumnValue>{columnField.value}</ColumnValue>
                  </Fragment>
                )
              )}
            </ColumnWithMargin>
          );
        })}
      </ColumnsContainer>
    </Modal>
  ) : null;

export default FacilityMainInformationPOCContactInfo;
