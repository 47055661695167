import React from "react";
import {
  PrintShopProgressbarContainer,
  PrintShopProgressBarButton,
} from "./PrintShopProgressBarComponents";
import { FormInputDateSingle } from "../Form";
import { MailSVG, PrintSVG2, SuccessSVG2 } from "../Containers";

const PrintShopProgressBarIsCertificateApplyMailDate = ({
  hasSelected,
  state,
  updateState,
  clickHandler,
}) => (
  <PrintShopProgressbarContainer>
    <FormInputDateSingle
      value={state.mailDate}
      onChange={(mailDate) => updateState({ mailDate })}
    />
    <PrintShopProgressBarButton
      hasSelected={hasSelected}
      SVG={MailSVG}
      onClick={() => clickHandler("set_mail_date", "package")}
      {...state}
    >
      Apply Mail Date
    </PrintShopProgressBarButton>
  </PrintShopProgressbarContainer>
);

const PrintShopProgressBarIsCertificatePrintPackage = ({
  hasSelected,
  clickHandler,
  state,
}) => (
  <PrintShopProgressBarButton
    hasSelected={hasSelected}
    SVG={PrintSVG2}
    onClick={() => clickHandler("print", "package")}
    {...state}
  >
    Print Certificates/Letters
  </PrintShopProgressBarButton>
);

const PrintShopProgressBarIsCertificatePrintLabel = ({
  hasSelected,
  clickHandler,
  state,
}) => (
  <PrintShopProgressBarButton
    hasSelected={hasSelected}
    SVG={PrintSVG2}
    onClick={() => clickHandler("print_label", "package")}
    {...state}
  >
    Print Labels
  </PrintShopProgressBarButton>
);

const PrintShopProgressBarIsCertificateConfirmation = ({
  hasSelected,
  clickHandler,
  state,
}) => (
  <PrintShopProgressBarButton
    hasSelected={hasSelected}
    SVG={SuccessSVG2}
    onClick={() => clickHandler("confirm_success", "package")}
    {...state}
  >
    Confirm
  </PrintShopProgressBarButton>
);

const PrintShopProgressBarIsCertificate = [
  {
    title: "Apply Mail Date",
    helperText: `Select the rows for the items that you want to apply the mail date to and click the "Apply Mail Date" button.`,
    Component: PrintShopProgressBarIsCertificateApplyMailDate,
  },
  {
    title: "Print Certificates/Letters",
    helperText: `Select the certificate packages and letters you want to print and then click the "Print" button.`,
    Component: PrintShopProgressBarIsCertificatePrintPackage,
  },
  {
    title: "Print Labels",
    helperText: `Select the rows that you want to print mailing labels for and then click the "Print" button.`,
    Component: PrintShopProgressBarIsCertificatePrintLabel,
  },
  {
    title: "Confirmation",
    helperText: `Please confirm that all documents and labels were printed successfully by clicking the "Confirm" button.`,
    Component: PrintShopProgressBarIsCertificateConfirmation,
  },
];

export default PrintShopProgressBarIsCertificate;
