import React from "react";

export const EmailSVG = (props) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.84211 0H15.1579C16.7275 0 18 1.25361 18 2.8V11.2C18 12.7464 16.7275 14 15.1579 14H2.84211C1.27246 14 0 12.7464 0 11.2V2.8C0 1.2536 1.27245 0 2.84211 0ZM2.84211 0.933334C2.37952 0.933334 1.95565 1.09665 1.62667 1.36794L9 6.08533L16.3733 1.36794C16.0443 1.09665 15.6205 0.933334 15.1579 0.933334H2.84211ZM1.07413 2.1273L9 7.19814L16.9259 2.1273C17.0077 2.336 17.0526 2.56284 17.0526 2.8V11.2C17.0526 12.231 16.2044 13.0667 15.1579 13.0667H2.84211C1.79567 13.0667 0.947368 12.231 0.947368 11.2V2.8C0.947368 2.56284 0.992255 2.336 1.07413 2.1273Z"
      fill="#3176F2"
    />
  </svg>
);
