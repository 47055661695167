import React from "react";

function TableHeaderWithSort({
  width,
  sortValue,
  params: { sort_column, sort_direction, ...restParams },
  setParams,
  getHeaderProps,
  render,
  hasSelect,
}) {
  hasSelect && (sortValue = sortValue - 1);

  const isSelect = sortValue === -1;

  const isSorted = sortValue === sort_column;

  const isAsc = sort_direction === "asc";

  const activeStyle = isSorted ? { textDecoration: "underline" } : {};

  isSelect && (activeStyle.cursor = "auto");

  const onClick = () =>
    !isSelect &&
    setParams({
      ...restParams,
      sort_column: sortValue,
      sort_direction: isAsc ? "desc" : "asc",
      page: 0,
    });

  const props = { width, style: activeStyle, onClick, ...getHeaderProps() };

  const sortArrow = isSorted ? <span>{isAsc ? " ↓" : " ↑"}</span> : "";

  return (
    <th {...props}>
      {render("Header")}
      {sortArrow}
    </th>
  );
}

export default TableHeaderWithSort;
