import { generateSearchParams } from "../searchParams";
import authorizeAndSendFetchRequest from "./authorizeAndSendFetchRequest";

export default {
  get: ({ path, params, ...rest }) =>
    authorizeAndSendFetchRequest({
      method: "GET",
      path: path + (params ? generateSearchParams(params) : ""),
      ...rest,
    }),
  post: ({ data, ...rest }) =>
    authorizeAndSendFetchRequest({
      method: "POST",
      body: JSON.stringify(data),
      ...rest,
    }),
  s3: (props) =>
    authorizeAndSendFetchRequest({
      isS3: true,
      ...props,
    }),
  delete: ({ data, ...rest }) =>
    authorizeAndSendFetchRequest({
      method: "DELETE",
      body: JSON.stringify(data),
      ...rest,
    }),
};
