import React from "react";
import styled from "styled-components";
import FormSubmitButton from "./FormSubmitButton";
import { Button } from "../Containers";

const StyledDiv = styled.div`
  display: flex;
  margin-right: 0 !important;
  margin-top: 40px;
  width: 100% !important;
  justify-content: flex-end;
`;

const FormSubmitButtonBlueRed = ({ key, submitButton, redButton, ...rest }) => (
  <StyledDiv key={key} className="submit">
    {
      <FormSubmitButton
        altLoader
        flavor="blueButton"
        {...{ ...submitButton, ...rest }}
      />
    }
    {redButton ? (
      <Button
        flavor="redButton"
        onClick={redButton.onClick}
        style={{ marginLeft: 15 }}
      >
        {redButton.label}
      </Button>
    ) : null}
  </StyledDiv>
);

export default FormSubmitButtonBlueRed;
