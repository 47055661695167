import React, { useContext, useEffect } from "react";
import { UserContext } from "../../Auth";
import { fetcher, formatDate } from "../../../utils";
import { Button } from "../../Containers";
import FormInputStandard from "../../Form/FormInputStandard";
import FormDropdownNewNew from "../../Form/FormDropdownNewNew";
import { FormInputDateSingle } from "../../Form";
import FormTextareaOne from "../../Form/FormTextareaOne";

const CreateLogsForm = (props) => {
    const data = props.data;
    const user = useContext(UserContext);
    const[category, setCategory] = React.useState("");
    const[subcategoryCert, setSubcategoryCert] = React.useState("");
    const[subcategoryBill, setSubcategoryBill] = React.useState("");
    const[status, setStatus] = React.useState("");
    const[disposition, setDisposition] = React.useState("");
    const[source, setSource] = React.useState("");
    const[dateCreated, setDateCreated] = React.useState("");
    const[dateClosed, setDateClosed] = React.useState("");
    const[facilityId, setFacilityId] = React.useState("");
    const[facilityInfo, setFacilityInfo] = React.useState();
    const[callerName, setCallerName] = React.useState();
    const[inquirySummary, setInquirySummary] = React.useState("");
    const[reportedBy, setReportedBy] = React.useState("");
    const[pocCallerName, setPocCallerName] = React.useState("");
    const[callerTitle, setCallerTitle] = React.useState(facilityInfo?.poc_list[callerName?.key]?.poc_title || "");
    const[callerPhone, setCallerPhone] = React.useState(facilityInfo?.poc_list[callerName?.key]?.poc_primary_phone || "");
    const[callerEmail, setCallerEmail] = React.useState(facilityInfo?.poc_list[callerName?.key]?.poc_email || "");
    const[error, setError] = React.useState("");

    const callerList = facilityInfo?.poc_list?.map(poc => poc.poc_full_name)
    if(callerList) {
      callerList.push("Add new POC")
    } 
    const name = user && user.username;
    let reported_by = data[0].reported_by_list.sort(function(x,y){ return x === name ? -1 : y === name ? 1 : 0; });
    useEffect(() => {
      setCallerTitle(facilityInfo?.poc_list[callerName?.key]?.poc_title)
      setCallerPhone(facilityInfo?.poc_list[callerName?.key]?.poc_primary_phone)
      setCallerEmail(facilityInfo?.poc_list[callerName?.key]?.poc_email)

    }, [facilityInfo, callerName])
    useEffect(() => {
      setError("");
      if(facilityId.length === 6) {
        fetcher
        .get({
          authority: "test",
          path: "/facilities",
          params: {
            action: "get_facility_info",
            fac_id: Number(facilityId)
          }
        })
        .then((responseData) => {
          console.log(responseData);
          if(responseData.facility_info === undefined) {
            setError("Please enter valid Facility ID")
          }
          else {
            setFacilityInfo(responseData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
      else if(facilityInfo && facilityId.toString().length === 0){
      setCallerName("");
      setCallerTitle("")
      setCallerPhone("")
      setCallerEmail("")
      setFacilityInfo(current => ({...current, 
        facility_info: {
          fac_id: "",
          fac_name: "",
          fac_addline1: "",
          fac_addline2: "",
          fac_addline3: "",
          fac_addline4: "",
          fac_city: "",
          fac_state: "",
          fac_zip: "",
          fac_phone: "",
          fac_addline1_m: "",
          fac_addline2_m: "",
          fac_addline3_m: "",
          fac_addline4_m: "",
          fac_city_m: "",
          fac_state_m: "",
          fac_zip_m: "",
          notes: ""
        }
      }))
      }
    }, [facilityId]);

  const submitForm = (e) => {
    e.preventDefault();

    if((facilityId.length === 0 || facilityId.length === 6) && error === "") {
      fetcher.post({
        authority: "test",
        path: "/certificate",
        data: {
          operation: "create_cert_ticket",		
          cert_ticket_record: {
            tkt_id: data[1].cert_tkt_id,
            category: category.value || "",
            subcategory: subcategoryCert.value || subcategoryBill.value || "",   
            status: status.value || "",
            disposition: disposition.value || "",
            source: source.value || "",
            date_created: formatDate(dateCreated === "" ? new Date() : dateCreated),
            date_closed: formatDate(dateClosed === "" ? new Date() : dateClosed),
            reported_by: reportedBy?.value || "",
            inquiry_summary: inquirySummary || "",
            fac_id: Number(facilityId),
            fac_name: facilityInfo?.facility_info?.fac_name || "",
            fac_addline1: facilityInfo?.facility_info?.fac_addline1 || "",
            fac_addline2: facilityInfo?.facility_info?.fac_addline2 || "",
            fac_city: facilityInfo?.facility_info?.fac_city || "",
            fac_state: facilityInfo?.facility_info?.fac_state || "",
            fac_zip: facilityInfo?.facility_info?.fac_zip || "",
            fac_phone:facilityInfo?.facility_info?.fac_phone || "",
            poc_new: pocCallerName !== "" ? "Y": "N",              
            poc_title: callerTitle || "",
            poc_full_name: pocCallerName !== "" ? pocCallerName : callerName?.value || "",
            poc_id: facilityInfo?.poc_list[callerName?.key]?.poc_id || "",
            poc_primary_phone: callerPhone || "",
            poc_email: callerEmail || "",
          }
        }
      }).then((data) => {
        console.log(data)
        window.location = "/inquiries/certification"
      })
      .catch((responseError) => {
        console.log(responseError);
      });
    }
    else {
      setError("Please put in valid facility ID")
    }
   
};

return (
    <div style={{width: "50%", margin: "auto"}}>
      <h1>Create Logs</h1>
     
      <form onSubmit={submitForm}>
      <FormInputStandard
        label= "Ticket Information"
        input= "headerLabel"
        />
      <FormInputStandard
        label= "Ticket ID"
        name= "ticket_id"
        value={data[1].cert_tkt_id}
        input="number"
        disabled={true}
        style ={{ marginBottom: 10}}
      />
      <FormDropdownNewNew 
        label= "Category"
        options= {data[0].cat_list}
        name= "category"
        value= {category.value}
        onChange={(e) => setCategory(e)}
        style= {{ marginBottom: 10 }}
      />
      {category.value === "Certification" && 
        <FormDropdownNewNew 
        label= "Sub-category"
        options= {data[0].subcat_list.filter(subcat => subcat.cat === "Certification").map(category => category.subcat)}
        name= "subcategory_certification"
        value= {subcategoryCert.value}
        onChange={(e) => setSubcategoryCert(e)}
        style= {{ marginBottom: 10 }}
      />}
      {category.value === "Billing" && 
        <FormDropdownNewNew 
        label= "Sub-category"
        options= {data[0].subcat_list.filter(subcat => subcat.cat === "Billing").map(category => category.subcat)}
        name= "subcategory_billing"
        value= {subcategoryBill.value}
        onChange={(e) => setSubcategoryBill(e)}
        style= {{ marginBottom: 10 }}
      />}
      <FormDropdownNewNew 
        label= "Status"
        options= {data[0].status_list}
        name= "status"
        value= {status.value}
        required= {true}
        onChange={ (e) => setStatus(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormDropdownNewNew 
        label= "Disposition"
        options= {data[0].dispn_list}
        name= "disposition"
        value= {disposition.value}
        onChange={(e) => setDisposition(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormDropdownNewNew 
        label= "Source"
        options= {data[0].source_list}
        name= "source"
        value= {source.value}
        onChange={(e) => setSource(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormInputDateSingle
      value={dateCreated}
      label= "Date Created"
      name= "date_created"
      onChange={(e) => setDateCreated(e)}
      required={true}
      style= {{ marginBottom: 10 }}
    />
    <FormInputDateSingle
      value={dateClosed}
      label= "Date Closed"
      name= "date_closed"
      onChange={(e) => setDateClosed(e)}
      style= {{ marginBottom: 10 }}
    />
     <FormDropdownNewNew 
        label= "Reporter"
        options= {reported_by}
        name= "reporter"
        value= {reportedBy.value}
        onChange={(e) => setReportedBy(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormTextareaOne 
        label="Inquiry Summary"
        name="inquirySummary"
        value={inquirySummary}
        onChange={(e) => setInquirySummary(e.target.value)}
      />
      <FormInputStandard
        label= "Facility Information"
        name= "headerLabel"
        input="headerLabel"
      />
      <FormInputStandard
        label= {error}
        input="errorLabel"
      />
      <FormInputStandard
            label= "Facility Number"
            name= "fac_num"
            value={facilityId}
            onChange={(e) => setFacilityId(e.target.value)}
            style ={{ marginBottom: 10}}
          />
      <FormInputStandard
            label= "Facility Name"
            name= "fac_name"
            value={facilityInfo?.facility_info?.fac_name || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
      <FormInputStandard
            label= "Facility Address 1"
            name= "fac_addline1"
            value={facilityInfo?.facility_info?.fac_addline1 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
      <FormInputStandard
            label= "Facility Address 2"
            name= "fac_addline2"
            value={facilityInfo?.facility_info?.fac_addline2 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Address 3"
            name= "fac_addline3"
            value={facilityInfo?.facility_info?.fac_addline3 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Address 4"
            name= "fac_addline4"
            value={facilityInfo?.facility_info?.fac_addline4 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility City"
            name= "fac_city"
            value={facilityInfo?.facility_info?.fac_city || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility State"
            name= "fac_state"
            value={facilityInfo?.facility_info?.fac_state || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Zip"
            name= "fac_zip"
            value={facilityInfo?.facility_info?.fac_zip || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Phone Number"
            name= "fac_phone_num"
            value={facilityInfo?.facility_info?.fac_phone || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Caller Information"
            input= "headerLabel"
          />
    {(!["0", "N/A", ""].includes(facilityId.toString())) &&
    <FormDropdownNewNew
            label= "Caller Name"
            name= "caller_name"
            value={callerName}
            options={facilityInfo?.poc_list ? callerList : []}
            disabled={facilityInfo?.poc_list ? false : true}
            onChange={(e) => setCallerName(e)}
            style ={{ marginBottom: 10}}
          />
  }

          {(callerName?.value === "Add new POC" || ["0", "N/A", ""].includes(facilityId.toString())) && 
            <FormInputStandard
            label= "Caller Name"
            name= "caller_name"
            value={pocCallerName}
            onChange={(e) => setPocCallerName(e.target.value)}
            style ={{ marginBottom: 10}}
          />}
          <FormInputStandard
            label= "Caller Title"
            name= "caller_title"
            value={callerTitle}
            disabled={(callerName?.value !== "Add new POC" && !["0", "N/A", ""].includes(facilityId.toString()) ) ? true : false}
            onChange={(e) => setCallerTitle(e.target.value)}
            style ={{ marginBottom: 10}}
          />
          <FormInputStandard
            label= "Caller Phone Number"
            name= "caller_phone_num"
            value={callerPhone}
            disabled={(callerName?.value !== "Add new POC" && !["0", "N/A", ""].includes(facilityId.toString()) ) ? true : false}
            onChange={(e) => setCallerPhone(e.target.value)}
            style ={{ marginBottom: 10}}
          />
          <FormInputStandard
            label= "Caller Email"
            name= "caller_email"
            value={callerEmail}
            disabled={(callerName?.value !== "Add new POC" && !["0", "N/A", ""].includes(facilityId.toString()) ) ? true : false}
            onChange={(e) => setCallerEmail(e.target.value)}
            style ={{ marginBottom: 10}}
          />
      <div style={{display: "flex", flexDirection: "row"}}>
      <Button type="submit" flavor="blueButton" style={{marginRight: 10}}>
        Submit
      </Button>
      <Button onClick={() => window.location = "/inquiries/certification"} flavor="redButton">
        Cancel
      </Button>
      </div>
      </form>
    </div>
  );
};

export default CreateLogsForm;
