import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import ThemeProviderConfig from "./ThemeProviderConfig";
import ThemeProviderGlobalStyle from "./ThemeProviderGlobalStyle";

const ThemeProvider = ({ children }) => (
  <StyledThemeProvider theme={ThemeProviderConfig}>
    <ThemeProviderGlobalStyle />
    {children}
  </StyledThemeProvider>
);

export default ThemeProvider;
