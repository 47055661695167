import React, { Fragment, useState } from "react";
import { PageTitle, PageSubtitle } from "../Containers";
import PrintShopLookupSelect from "./PrintShopLookupSelect";
import PrintShopLookupPrint from "./PrintShopLookupPrint";

function PrintShopLookup({ data, params }) {
  const [doc, setDoc] = useState(false);

  const redirectToPrintShop = () => {
    setTimeout(() => {
      window.location = "/printshop";
    }, 1500);
  };
  return (
    <>
      {!data.facility_info ? (
        <h3 style={{ textAlign: "center" }}>
          Error: Invalid or unknown facility ID. Redirecting you back to the
          print shop....
          {redirectToPrintShop()}
        </h3>
      ) : (
        <Fragment>
          <PageTitle>
            Print Shop / Previously Printed Certificates, Letters & Late Notices
          </PageTitle>
          <br />
          <PageSubtitle>Facility ID: #{params.fac_id}</PageSubtitle>
          <PageSubtitle>
            Facility Name: {data.facility_info.fac_name}
          </PageSubtitle>
          <br />
          {!doc || !Object.keys(doc).length ? (
            <PrintShopLookupSelect setDoc={setDoc} data={data} />
          ) : (
            <PrintShopLookupPrint setDoc={setDoc} {...doc} />
          )}
        </Fragment>
      )}
    </>
  );
}

export default PrintShopLookup;
