import React from "react";
import Dropdown from "../Dropdown";
import { StandardInputDiv } from "./FormStyles";
import { DownCaretAltSVG } from "../Containers";
import styled from "styled-components";

const StyledDownCaretSVG = styled(DownCaretAltSVG)`
  position: absolute;
  right: 12px;
  top: 3px;
`;

function FormDropdownInput({
  key,
  options,
  uppercase,
  value,
  onChange,
  ...rest
}) {
  options = options.map((option) => ({
    onClick: () => onChange(option),
    label: option,
  }));

  return (
    <Dropdown
      options={options}
      style={{ flexGrow: "1" }}
      buttonStyle={{ width: "100%" }}
      {...rest}
    >
      <StandardInputDiv style={{ width: "inherit" }}>
        {value && uppercase ? value.toUpperCase() : value}
        <StyledDownCaretSVG />
      </StandardInputDiv>
    </Dropdown>
  );
}

export default FormDropdownInput;
