import { errorString } from "../../utils";

// Refer to use case 23:
// https://www.npmjs.com/package/amazon-cognito-identity-js

function AuthNewPasswordSubmitHandler({
  setLoading,
  setError,
  values,
  componentState: { cognitoUser },
}) {
  setLoading(true);
  setError(null);
  cognitoUser
    ? cognitoUser.completeNewPasswordChallenge(
        values.password,
        { email: cognitoUser.username },
        {
          onSuccess: () => window.location.reload(),
          onFailure: (err) => {
            setError(errorString(err));
            setLoading(null);
          },
        }
      )
    : (() => {
        setError("Cognito user not recognized.");
        setLoading(null);
      })();
}

export default AuthNewPasswordSubmitHandler;
