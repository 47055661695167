import { EmailSVG } from "../Containers";
import AuthForgotPasswordSubmitHandler from "./AuthForgotPasswordSubmitHandler";

const AuthForgotPasswordSchema = ({ componentState }) => ({
  id: "forgot-password",
  components: [
    [
      {
        component: "FormInputOne",
        type: "email",
        name: "email",
        label: "Email Address",
        placeholder: "Enter Email Address",
        SVG: EmailSVG,
        required: true,
      },
      {
        component: "FormSubmitButton",
        flavor: "blueButtonBig",
        label: "SEND RESET CODE",
        name: "submit_forgot_password",
        title: "Submit forgot password form.",
        onSubmit: {
          customHandler: (props) =>
            AuthForgotPasswordSubmitHandler({ ...props, componentState }),
        },
      },
      {
        component: "FormLinkOne",
        to: "/sign-in",
        label: "Remember password?",
      },
      {
        component: "FormMessageError",
      },
    ],
  ],
});

export default AuthForgotPasswordSchema;
