import React from "react";
import { ErrorCircle } from "@styled-icons/boxicons-regular/ErrorCircle";

export const AlertSVG = ErrorCircle;

export const Alert2SVG = (props) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 19.0766V7.90812L7.90812 0H19.0919L27 7.90811V19.0993L19.0993 27H7.9234L0 19.0766ZM12.7059 6.35294H14.2941V14.2941H12.7059V6.35294ZM14.2941 17.4706H12.7059V20.6471H14.2941V17.4706Z"
      fill="#EF3D29"
    />
  </svg>
);
