import React from "react";
import styled from "styled-components";
import {
  Button,
  FacilitySVG,
  PrintSVG,
  HotlineSVG,
  ReportSVG,
} from "../Containers";
import { useLocation } from "react-router-dom";
import constants from "../../constants";

const StyledNav = styled.nav`
  background: #3176f2;
  display: flex;
  flex-wrap: wrap;
`;

const StyledA = styled(Button)`
  display: flex;
  align-items: center;
  height: 50px;
  color: #fff;
  padding: 10px 25px;
  box-sizing: border-box;
  svg {
    margin-right: 15px;
  }
  &.active {
    background: #72a1f6;
    color: #fff;
    opacity: 1;
    z-index: ${({ theme }) => theme.zIndex.activeMenuTab};
  }
  &:hover {
    background: #72a1f6;
  }
`;

function Nav() {
  const { pathname } = useLocation();

  const links = [
    { to: "/facilities", SVG: FacilitySVG },
    { to: "/printshop", SVG: PrintSVG },
    { to: "/inquiries", SVG: HotlineSVG, size: 25 },
    { to: "/reports", SVG: ReportSVG },
  ];

  const splitPath = (path) => path.split("/")[1];

  return (
    <StyledNav>
      {links.map((link, index) => (
        <StyledA
          key={index}
          to={link.to}
          className={splitPath(link.to) === splitPath(pathname) ? "active" : ""}
        >
          {<link.SVG height={link.size || 30} width={link.size || 30} />}
          {constants.labelAlias[splitPath(link.to)]}
        </StyledA>
      ))}
    </StyledNav>
  );
}

export default Nav;
