import React from "react";

const TableErrorMessage = ({ onClick, key, ...rest }) => ({
  Header: "Error Message",
  accessor: (rest && rest.key) || "check_desc",
  width: 200,
  Cell: (e) => <div style={{ color: "red" }}>{e.value} </div>,
});

export default TableErrorMessage;
