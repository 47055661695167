import { PasswordSVG } from "../Containers";
import AuthNewPasswordSubmitHandler from "./AuthNewPasswordSubmitHandler";

const AuthNewPasswordSchema = ({ componentState }) => ({
  name: "new-password",
  components: [
    {
      component: "FormInputOne",
      type: "password",
      name: "password",
      label: "Enter New Password",
      placeholder: "Enter Password",
      autoComplete: "new-password",
      SVG: PasswordSVG,
      minLength: 8,
      required: true,
    },
    {
      component: "FormSubmitButton",
      flavor: "blueButtonBig",
      label: "CREATE PASSWORD",
      name: "submit_new_password",
      title: "Submit new password form.",
      onSubmit: {
        customHandler: (props) =>
          AuthNewPasswordSubmitHandler({ ...props, componentState }),
      },
    },
    {
      component: "FormMessageError",
    },
  ],
});

export default AuthNewPasswordSchema;
