import { TableColumn } from "../Table";
import { number as simpulNumber } from "simpul";

const PrintShopLookupTableColumns = ({ isLookup, isBill, setDoc }) => [
  !isLookup && TableColumn.fac_id(),
  !isLookup && {
    Header: "Facility Name",
    accessor: "fac_name",
    width: 400,
  },
  ...(isBill
    ? isLookup
      ? [
          TableColumn.selectRow({
            onClick: (e) => setDoc(e.cell.row.original),
          }),
          {
            Header: "Original Bill Date",
            accessor: "orig_bill_date",
            width: 225,
          },
          {
            Header: "Type",
            accessor: "item_type",
            width: 225,
          },
          TableColumn.inv_num(),
          {
            Header: "Original Bill Amount",
            accessor: "orig_bill_amt",
            width: 225,
            Cell: (e) =>
              e.value === "" ? "None" : simpulNumber.finance(e.value),
          },
          {
            Header: "Remaining Bill Amount",
            Cell: (e) =>
              e.value === "" ? "None" : simpulNumber.finance(e.value),
            accessor: "remain_bill_amt",
          },
        ]
      : [
          TableColumn.selectRow({
            onClick: (e) => setDoc(e.cell.row.original),
          }),
          {
            Header: "Original Bill Date",
            accessor: "orig_bill_date",
            width: 225,
          },
          {
            Header: "Billing Status",
            accessor: "item_type",
            width: 225,
          },
          {
            Header: "Original Bill Amount",
            accessor: "orig_bill_amt",
            width: 225,
            Cell: (e) => simpulNumber.finance(e.value),
          },
        ]
    : [
        TableColumn.selectRow({
          onClick: (e) => setDoc(e.cell.row.original),
        }),
        {
          Header: "Current Type",
          accessor: "status",
          width: 225,
        },
        {
          Header: "Certificate or Letter Number",
          accessor: "cert_num",
          width: 225,
        },
        {
          Header: isLookup ? "Mailed Date" : "Certificate Mail Date",
          accessor: "mail_date",
          width: 200,
        },
        isLookup && {
          Header: "Copy Number",
          accessor: "copy_nmbr",
          width: 100,
        },
      ]),
];

export default PrintShopLookupTableColumns;
