import React from "react";

export const FacilitySVG = (props) => (
  <svg
    width="43"
    height="51"
    viewBox="0 0 43 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d)">
      <path
        d="M36.7141 41.1178H32.2032V4.59109C32.2032 4.31871 31.9821 4.09766 31.7097 4.09766H10.6333C10.361 4.09766 10.1399 4.31871 10.1399 4.59109V41.1178H5.62797C5.3556 41.1178 5 42.2556 5 42.528C5 42.8004 5.3556 43.5149 5.62797 43.5149H36.7141C36.9865 43.5149 37.3921 42.8004 37.3921 42.528C37.3921 42.2556 36.9865 41.1178 36.7141 41.1178Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7219 30.2588H18.6287C18.9011 30.2588 19.1222 30.4798 19.1222 30.7522V33.659C19.1222 33.9314 18.9011 34.1524 18.6287 34.1524H15.7219C15.4496 34.1524 15.2285 33.9314 15.2285 33.659V30.7522C15.2285 30.4798 15.4496 30.2588 15.7219 30.2588ZM16.2154 33.1656H18.1353V31.2457H16.2154V33.1656Z"
        fill="#3176F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7219 22.9912H18.6287C18.9011 22.9912 19.1222 23.2123 19.1222 23.4846V26.3914C19.1222 26.6638 18.9011 26.8849 18.6287 26.8849H15.7219C15.4496 26.8849 15.2285 26.6638 15.2285 26.3914V23.4846C15.2285 23.2123 15.4496 22.9912 15.7219 22.9912ZM16.2154 25.898H18.1353V23.9781H16.2154V25.898Z"
        fill="#3176F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7219 15.7246H18.6287C18.9011 15.7246 19.1222 15.9452 19.1222 16.218V19.1248C19.1222 19.3972 18.9011 19.6183 18.6287 19.6183H15.7219C15.4496 19.6183 15.2285 19.3972 15.2285 19.1248V16.218C15.2285 15.9457 15.4496 15.7246 15.7219 15.7246ZM16.2154 18.6314H18.1353V16.7115H16.2154V18.6314Z"
        fill="#3176F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7219 8.45801H18.6287C18.9011 8.45801 19.1222 8.67906 19.1222 8.95144V11.8582C19.1222 12.1306 18.9011 12.3517 18.6287 12.3517H15.7219C15.4496 12.3517 15.2285 12.1306 15.2285 11.8582V8.95144C15.2285 8.67906 15.4496 8.45801 15.7219 8.45801ZM16.2154 11.3648H18.1353V9.44487H16.2154V11.3648Z"
        fill="#3176F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7151 30.2588H26.6219C26.8943 30.2588 27.1153 30.4798 27.1153 30.7522V33.659C27.1153 33.9314 26.8943 34.1524 26.6219 34.1524H23.7151C23.4427 34.1524 23.2217 33.9314 23.2217 33.659V30.7522C23.2217 30.4798 23.4427 30.2588 23.7151 30.2588ZM24.2085 33.1656H26.1285V31.2457H24.2085V33.1656Z"
        fill="#3176F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7151 22.9912H26.6219C26.8943 22.9912 27.1153 23.2123 27.1153 23.4846V26.3914C27.1153 26.6638 26.8943 26.8849 26.6219 26.8849H23.7151C23.4427 26.8849 23.2217 26.6638 23.2217 26.3914V23.4846C23.2217 23.2123 23.4427 22.9912 23.7151 22.9912ZM24.2085 25.898H26.1285V23.9781H24.2085V25.898Z"
        fill="#3176F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7151 15.7246H26.6219C26.8943 15.7246 27.1153 15.9452 27.1153 16.218V19.1248C27.1153 19.3972 26.8943 19.6183 26.6219 19.6183H23.7151C23.4427 19.6183 23.2217 19.3972 23.2217 19.1248V16.218C23.2217 15.9457 23.4427 15.7246 23.7151 15.7246ZM24.2085 18.6314H26.1285V16.7115H24.2085V18.6314Z"
        fill="#3176F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7151 8.45801H26.6219C26.8943 8.45801 27.1153 8.67906 27.1153 8.95144V11.8582C27.1153 12.1306 26.8943 12.3517 26.6219 12.3517H23.7151C23.4427 12.3517 23.2217 12.1306 23.2217 11.8582V8.95144C23.2217 8.67906 23.4427 8.45801 23.7151 8.45801ZM24.2085 11.3648H26.1285V9.44487H24.2085V11.3648Z"
        fill="#3176F2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0.597656"
        width="42.3921"
        height="49.4172"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1.5" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.0614672 0 0 0 0 0.352695 0 0 0 0.375464 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
