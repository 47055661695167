import React from "react";

export const SearchSVG = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13C8.1127 13 9.5882 12.4127 10.7243 11.4403L16.3693 17.0853L17.0764 16.3782L11.4321 10.7339C12.4094 9.5965 13 8.1172 13 6.5ZM1 6.5C1 3.46243 3.46243 1 6.5 1C9.5376 1 12 3.46243 12 6.5C12 9.5376 9.5376 12 6.5 12C3.46243 12 1 9.5376 1 6.5Z"
      fill="#A0A0A0"
    />
  </svg>
);
