import React, { Fragment } from "react";
import GetComponent from "../GetComponent";
import styled from "styled-components";
import FacilityTableStyle from "./FacilityTableStyle";
import { TableCore } from "../Table";

const MetaSectionContainer = styled.div`
  display: flex;
  background: #f3f8ff;
  box-shadow: 0px 0.5px 0.255px rgba(0, 0, 0, 0.5);
  padding: 25px 20px;
  margin-top: 25px;
`;

const MetaSectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  div:not(:first-child) {
    margin-top: 15px;
  }
`;

const MetaSectionColumnField = styled.div`
  display: flex;
  align-items: center;
`;

const MetaSectionColumnFieldLabel = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #1f1f1f;
  width: 150px;
`;

const MetaSectionColumnFieldValue = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1f1f1f;
`;

const TableSectionContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 20px;
  ${FacilityTableStyle}
`;

const TableSectionTitle = styled.h2`
  font-family: Lato;
  font-style: bold;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  color: #202020;
  margin-left: 22px;
  margin-bottom: 18px;
`;

function FacilityTemplate({ template, facility_info }) {
  const { fac_id } = facility_info;

  const {
    resource,
    addParams,
    meta: templateMeta,
    table: templateTable,
  } = template;
  return (
    <GetComponent
      hideGutters
      authority="test"
      resource={resource}
      params={{ ...addParams, fac_id }}
      Component={({ data }) => {
        return (
          <Fragment>
            {templateMeta && (
              <MetaSectionContainer>
                {templateMeta.columns.map(
                  (templateMetaColumn, templateMetaIndex) => (
                    <MetaSectionColumn key={templateMetaIndex}>
                      {templateMetaColumn.map(
                        (templateMetaColumnField, templateMetaColumnIndex) => (
                          <MetaSectionColumnField key={templateMetaColumnIndex}>
                            <MetaSectionColumnFieldLabel>
                              {templateMetaColumnField.label}:
                            </MetaSectionColumnFieldLabel>
                            <MetaSectionColumnFieldValue>
                              {data[templateMeta.primaryKey]
                                ? data[templateMeta.primaryKey][
                                    templateMetaColumnField.key
                                  ]
                                : ""}
                            </MetaSectionColumnFieldValue>
                          </MetaSectionColumnField>
                        )
                      )}
                    </MetaSectionColumn>
                  )
                )}
              </MetaSectionContainer>
            )}
            {templateTable && (
              <TableSectionContainer>
                <TableSectionTitle>{templateTable.title}</TableSectionTitle>
                {data[templateTable.primaryKey] ? (
                  <TableCore
                    columns={templateTable.columns}
                    data={data[templateTable.primaryKey]}
                  />
                ) : (
                  ""
                )}
              </TableSectionContainer>
            )}
          </Fragment>
        );
      }}
    />
  );
}

export default FacilityTemplate;
