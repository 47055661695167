import React from "react";
import { DropdownOptionContainer } from "./DropdownContainers";
import { Button } from "../Containers";

const DropdownOption = ({ onClick, setShowDropdown, SVG, label, ...rest }) => (
  <DropdownOptionContainer>
    <Button
      onClick={() => {
        onClick && onClick();
        setShowDropdown(false);
      }}
      {...rest}
    >
      {SVG ? <SVG /> : null}
      {label}
    </Button>
  </DropdownOptionContainer>
);

export default DropdownOption;
