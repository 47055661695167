import React, { useState, Fragment } from "react";
import { LabelOne, Button } from "../Containers";
import styled from "styled-components";
import FacilityMainInformationPOCTable from "./FacilityMainInformationPOCTable";
import FacilityMainInformationPOCForm from "./FacilityMainInformationPOCForm";
import FacilityMainInformationNotes from "./FacilityMainInformationNotes";

const FacilitySectionTitle = styled(LabelOne)`
  text-transform: uppercase;
  height: 50px;
  background: #f3f8ff;
  box-shadow: 0px 0.5px 0.255px rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  padding-left: 20px;
  margin-top: 25px;
`;

const FacilitySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
`;

const FacilitySectionField = styled.div`
  display: flex;
  margin: 12px 0;
`;

const FacilitySectionFieldLabel = styled(LabelOne)`
  width: 200px;
  text-align: right;
`;

const FacilitySectionFieldTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1f1f1f;
`;

const StyledButton = styled(Button)`
  justify-content: flex-end;
  margin: 15px 15px 0;
`;

function FacilityMainInformation({ facility_info = {}, poc_list = [] }) {
  const [showPOCForm, setShowPOCForm] = useState();

  const facilitySectionFieldResolver = [
    {
      label: "Facility ID",
      text: ["fac_id"],
    },
    {
      label: "Facility Name",
      text: ["fac_name"],
    },
    {
      label: "Mailing Address",
      text: [
        "fac_addline1_m",
        "fac_addline2_m",
        "fac_addline3_m",
        "fac_addline4_m",
      ],
    },
    {
      label: "Physical Address",
      text: ["fac_addline1", "fac_addline2", "fac_addline3", "fac_addline4"],
    },
  ];

  return (
    <Fragment>
      <FacilitySectionTitle>Facility</FacilitySectionTitle>
      <FacilitySectionContainer>
        {facilitySectionFieldResolver.map((facilitySectionField, index) => (
          <FacilitySectionField key={index}>
            <FacilitySectionFieldLabel>
              {facilitySectionField.label}:
            </FacilitySectionFieldLabel>
            <FacilitySectionFieldTextContainer>
              {facilitySectionField.text.map(
                (facilitySectionFieldText, index2) =>
                  facility_info[facilitySectionFieldText] ? (
                    <span key={index2}>
                      {facility_info[facilitySectionFieldText]}
                    </span>
                  ) : null
              )}
            </FacilitySectionFieldTextContainer>
          </FacilitySectionField>
        ))}
      </FacilitySectionContainer>
      <FacilitySectionTitle>Point of Contact</FacilitySectionTitle>
      <StyledButton
        flavor="blueLink"
        onClick={() =>
          setShowPOCForm({
            title: "Add New Point of Contact",
            operation: "Create",
          })
        }
      >
        Add New Point of Contact
      </StyledButton>
      <FacilityMainInformationPOCTable
        data={poc_list}
        setShowPOCForm={setShowPOCForm}
      />
      <FacilityMainInformationPOCForm
        fac_id={facility_info.fac_id}
        showPOCForm={showPOCForm}
        setShowPOCForm={setShowPOCForm}
      />
      <FacilitySectionTitle>Notes</FacilitySectionTitle>
      <FacilityMainInformationNotes
        fac_notes={facility_info.notes}
        fac_id={facility_info.fac_id}
      />
    </Fragment>
  );
}

export default FacilityMainInformation;
