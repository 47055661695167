const DashboardCardConfig = {
  cert_ALL_count: {
    label: "Certificates & Letters are ready to print",
    to: "/printshop/All%20Certificates%20and%20Letters",
  },
  letter_exp_count: {
    label: "Expiration Letters are ready to print",
    to: "/printshop",
  },
};

export default DashboardCardConfig;
