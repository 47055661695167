import React from "react";
import GetComponent from "../GetComponent";
import PrintShopDashboard from "./PrintShopDashboard";

const GetPrintShopDashboard = (props) => (
  <GetComponent
    authority="test"
    resource="printShop"
    Component={PrintShopDashboard}
    params={{ action: "get_dashboard_counts" }}
    {...props}
  />
);

export default GetPrintShopDashboard;
