import React from "react";

function TableColumnSelect({ accessor, selected, setSelected, transformKeys }) {
  const selectAllKey = "all";

  const isData = (selection, data) =>
    Object.keys(transformKeys).every(
      (key) => selection[key] === data[transformKeys[key]]
    );

  const isChecked = (data) =>
    selected.some(
      (selection) => selection === selectAllKey || isData(selection, data)
    );

  const toggleChecked = (data) =>
    setSelected((prevSelected) =>
      data === selectAllKey
        ? isChecked(data)
          ? prevSelected.filter((selection) => selection !== data)
          : prevSelected.concat([data])
        : isChecked(data)
        ? prevSelected.filter((selection) => !isData(selection, data))
        : prevSelected.concat(
            Object.keys(transformKeys).reduce((object, key) => {
              object[key] = data[transformKeys[key]];
              return object;
            }, {})
          )
    );

  return {
    accessor,
    sortable: false,
    width: 50,
    Header: () => (
      <input
        type="checkbox"
        checked={isChecked(selectAllKey)}
        onChange={() => toggleChecked(selectAllKey)}
      />
    ),
    Cell: (e) => (
      <input
        type="checkbox"
        checked={isChecked(e.row.original)}
        onChange={() => toggleChecked(e.row.original)}
      />
    ),
  };
}

export default TableColumnSelect;
