import React from "react";
import { Button } from "../Containers";

const TableColumnSF215File = (props) => ({
  Header: "Download File",
  accessor: (props && props.key) || "sf215_file_url",
  width: 225,
  Cell: (e) => (
    <Button
      flavor="blueButton"
      href={e.value}
      style={{backgroundColor:"#3176F2", color: "#FFFFFF", textDecoration: "none"}}
    >
      Download File
    </Button>
  ),
});

export default TableColumnSF215File;
