import React, { Fragment } from "react";
import styled from "styled-components";
import { PageTitle } from "../Containers";
import PageReportsForm from "./PageReportsForm";

const ContentContainer = styled.div`
  background: #fcfcfc;
  box-shadow: 3px 3px 8px rgba(8, 9, 54, 0.298842);
  margin-top: 15px;
  padding: 25px 75px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  h1.subtitle {
    margin: 0 0 40px 0;
  }
  form {
    margin-top: 0;
  }
`;

const PageReports = (props) => {
  return (
    <Fragment>
      <PageTitle>Reports</PageTitle>
      <ContentContainer>
        <PageReportsForm />
      </ContentContainer>
    </Fragment>
  );
};

export default PageReports;