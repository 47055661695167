import React from "react";
import GetComponent from "../GetComponent";
import LogsTable from "./LogsTable";

const PageLogs = (props) => (
  <GetComponent
    authority="test"
    resource="certificate"
    Component={LogsTable}
    params={{ action: "get_cert_ticket_logs" }}
    {...props}
  />
  
);

export default PageLogs;
