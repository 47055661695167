import React from "react";

export const SuccessSVG = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
      fill="#41CD50"
    />
    <path
      d="M22.8191 8.84852L10.9397 20.7279L5 14.7883L5.84853 13.9396L10.9397 19.0309L21.9706 8L22.8191 8.84852Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);
