import React from "react";
import { Button } from "../Containers";

const TableColumnExpand = {
  id: "expander",
  Header: "",
  width: 100,
  Cell: ({ row }) =>
    row.canExpand ? (
      <span
        {...row.getToggleRowExpandedProps({
          style: { paddingLeft: `${row.depth * 2}rem` },
        })}
      >
        {row.isExpanded ? (
          <Button flavor="blueLink" style={{ margin: "0 auto" }}>
            Close
          </Button>
        ) : (
          <Button flavor="blueLink" style={{ margin: "0 auto" }}>
            Expand
          </Button>
        )}
      </span>
    ) : null,
};

export default TableColumnExpand;
