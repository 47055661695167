const ButtonFlavor = {
  blueLink: `
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #2644FF;
  `,
  redLink: `
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #EF3D29;
  `,
  blueButtonBig: `
    width: 90%;
    margin: 0px auto;
    transition: all 0.5s ease 0s;
    background: rgb(49, 118, 242);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.384px;
    height: 58px;
    padding: 0px;
    border: 0px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
  `,
  blueButton: `
    width: fit-content;
    padding: 10px 20px;
    background: #3176F2;
    border-radius: 3.2px;
    font-style: normal;
    font-weight: 500;
    font-size: 12.8px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
  `,
  redButton: `
    width: fit-content;
    padding: 10px 20px;
    background: #EF3D29;
    border-radius: 3.2px;
    font-style: normal;
    font-weight: 500;
    font-size: 12.8px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
  `,
};

export default ButtonFlavor;
