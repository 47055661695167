import React from "react";
import { DashboardForm, LabelOne } from "../Containers";
import { Form } from "../Form";

function PrintShopLookupForm() {

  const formConfig = {
    id: "cert-lookup",
    componentsDirection: "row",
    components: [
      [
        {
          component: "FormInputStandard",
          label: "Facility ID",
          name: "fac_id",
          required: true,
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "SEARCH",
            name: "submit_print_shop_lookup",
            title: "Apply Print Shop lookup form.",
            onSubmit: {
              customHandler: ({ values }) => (window.location = "/printshop/Lookup/" + values.fac_id),
            },
          },
        },
      ],
    ],
  };

  return (
    <DashboardForm width="600px">
      <LabelOne>Lookup Tool</LabelOne>
      <Form {...formConfig} style={{ marginTop: 25 }} />
    </DashboardForm>
  );
}

export default PrintShopLookupForm;
