import React from "react";
import styled from "styled-components";
import constants from "../../constants";
import { Button } from "../Containers";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  background: #ffffff;
  box-shadow: 1.5px 1.5px 5px rgba(73, 73, 73, 0.384451);
  height: fit-content;
  margin-right: 90px;
  margin-bottom: 50px;
`;

const BoxTitle = styled.h1`
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  background: linear-gradient(172.52deg, #7ab3ea 6.54%, #206bf2 85.17%);
  margin: 0;
  align-items: center;
  display: flex;
  padding: 0 30px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  justify-content: space-between;
`;

const BoxRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const BoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  :not(:first-child) {
    margin-top: 30px;
  }
`;

const BoxRowLabel = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #4e4e4e;
  margin: 0;
  padding-right: 15px;
`;

const BoxRowButton = styled(Button)`
  background: linear-gradient(180deg, #3ae94d 0%, #41cd50 100%);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  min-width: 104px;
  height: 27px;
`;

function PrintShopDashboardCards({ data }) {
  const getRows = (type) =>
    constants.printShop
      .map((i) => i.type === type && i.countKey)
      .filter(Boolean);

  const getAlias = (key) =>
    constants.printShop.find((i) => i.countKey === key).cardLabel;
  const getCount = (key) => data[key] || 0;

  const getToList = (type) => "/printshop/" + type.split("_count")[0];

  const config = [
    {
      title: constants.labelAlias.cert,
      rows: getRows("certificate"),
    },
    {
      title: constants.labelAlias.expirationLetters,
      rows: getRows("expirationLetter"),
    },
  ];
  return (
    <Container>
      {config.map((box, index) => (
        <BoxContainer key={index}>
          <BoxTitle>
            {box.title} <span>{getCount(box.rows[0])} items</span>
          </BoxTitle>
          <BoxRowsContainer>
            {box.rows.map((boxRow, boxRowIndex) =>
              parseInt(data[boxRow]) ? (
                <BoxRow key={boxRowIndex}>
                  <BoxRowLabel>{getAlias(boxRow)}</BoxRowLabel>
                  <BoxRowButton to={getToList(getAlias(boxRow))}>
                    View ({getCount(boxRow)})
                  </BoxRowButton>
                </BoxRow>
              ) : null
            )}
          </BoxRowsContainer>
        </BoxContainer>
      ))}
    </Container>
  );
}

export default PrintShopDashboardCards;
