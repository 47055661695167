import React from "react";

export const PrintSVG = (props) => (
  <svg
    width="50"
    height="46"
    viewBox="0 0 50 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d)">
      <path
        d="M35.9727 4.97082V14.9708L37.9727 14.9708C41.2867 14.9708 43.9727 17.6568 43.9727 20.9708V30.9706H35.9727V38.9706H13.9727V30.9706H5.97266V20.9706C5.97266 17.6586 8.66066 14.9707 11.9727 14.9707H13.9727V4.9707L35.9727 4.97082Z"
        fill="#3176F2"
        stroke="white"
        strokeWidth="1.02564"
      />
      <path
        d="M7.97363 20.9746V28.9746H13.9736V24.9746H35.9736V28.9746H41.9736V20.9746C41.9736 18.7654 40.1828 16.9746 37.9736 16.9746H11.9736C9.76449 16.9746 7.97363 18.7654 7.97363 20.9746Z"
        fill="white"
      />
      <path
        d="M33.9736 36.9746V26.9746H15.9736V36.9746H33.9736Z"
        fill="white"
      />
      <path
        d="M15.9736 6.97461V14.9746H33.9736V6.97461H15.9736Z"
        fill="white"
      />
      <path
        d="M37.9727 22.9707C36.8667 22.9707 35.9727 22.0767 35.9727 20.9707C35.9727 19.8647 36.8667 18.9707 37.9727 18.9707C39.0787 18.9707 39.9727 19.8647 39.9727 20.9707C39.9727 22.0767 39.0787 22.9707 37.9727 22.9707Z"
        fill="#3176F2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0.459961"
        y="0.958008"
        width="49.0257"
        height="45.0256"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1.5" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.0614672 0 0 0 0 0.352695 0 0 0 0.375464 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const PrintSVG2 = (props) => (
  <svg
    width="31"
    height="27"
    viewBox="0 0 31 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8236 9.52943e-05V7.94127L25.4118 7.94129C28.0435 7.94129 30.1765 10.0743 30.1765 12.706V20.647H23.8236V27H6.35295V20.647H0V12.7059C0 10.0757 2.13459 7.94119 4.76471 7.94119H6.35295V0L23.8236 9.52943e-05ZM25.4118 14.2944C24.5335 14.2944 23.8236 13.5844 23.8236 12.7061C23.8236 11.8278 24.5335 11.1179 25.4118 11.1179C26.2901 11.1179 27.0001 11.8278 27.0001 12.7061C27.0001 13.5844 26.2901 14.2944 25.4118 14.2944ZM1.58947 19.0618V12.7089C1.58947 10.9545 3.01162 9.5324 4.76594 9.5324H25.413C27.1674 9.5324 28.5895 10.9545 28.5895 12.7089V19.0618H23.8248V15.8853H6.35418V19.0618H1.58947ZM22.2361 25.4147V17.4735H7.94198V25.4147H22.2361ZM7.94198 7.94405V1.59109H22.2361V7.94405H7.94198Z"
      fill="#3176F2"
    />
  </svg>
);
