import React, { Fragment } from "react";
import styled from "styled-components";
import { Button } from "../Containers";
import { useLocation } from "react-router-dom";
import constants from "../../constants";

const StyledNav = styled.nav`
  background: #cccccc73;
  display: flex;
  align-items: center;
`;

const commonStyle = `
  display: flex;
  padding: 10px 15px;
  font-size: 14px;
  color: #161616;
`;

const StyledA = styled(Button)`
  ${commonStyle}
  text-decoration: underline;
`;

const StyledLabel = styled.label`
  ${commonStyle}
  text-decoration: none;
`;

function NavBreadcrumbs() {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/").filter(Boolean);
  const crumbs = splitPathname.map((i, index) => ({
    label: constants.labelAlias[i] || i,
    to: "/" + splitPathname.slice(0, index + 1).join("/"),
  }));
  crumbs.length && crumbs.unshift({ label: "Home", to: "/" });
  return crumbs && crumbs.length ? (
    <StyledNav>
      {crumbs.map(({ label, ...crumb }, index) => (
        <Fragment key={index}>
          {index ? <span>{">"}</span> : ""}
          {crumb.to && index !== crumbs.length - 1 ? (
            <StyledA {...crumb}>{label}</StyledA>
          ) : (
            <StyledLabel>{label}</StyledLabel>
          )}
        </Fragment>
      ))}
    </StyledNav>
  ) : null;
}

export default NavBreadcrumbs;
