import React from "react";
import { Modal, LabelTwo } from "../Containers";
import { Form } from "../Form";
import FacilityMainInformationPOCFormConfig from "./FacilityMainInformationPOCFormConfig";
import styled from "styled-components";

const StyledForm = styled.div`
  form#edit-contact-info {
    padding: 35px 45px;
    box-sizing: border-box;
    div[direction="row"]:not(:last-child) {
      margin-bottom: 10px;
    }
    div[direction="row"] {
      width: 100%;
      div:nth-child(1),
      div:nth-child(2) {
        width: 248px;
        margin-right: 90px;
      }
    }
  }
`;

function FacilityMainInformationPOCContactInfoForm({
  fac_id,
  showPOCForm: formProps,
  setShowPOCForm,
}) {
  formProps = formProps || {};
  const closeForm = () => setShowPOCForm();

  let { initialValues, operation, poc_id } = formProps;

  initialValues
    ? !initialValues.fac_id && (initialValues.fac_id = fac_id)
    : (initialValues = { fac_id });

  const { title } = formProps;
  return title ? (
    <Modal close={closeForm} topMargin={"5%"}>
      <LabelTwo>{title}</LabelTwo>
      <StyledForm>
        <Form
          initialValues={initialValues}
          {...FacilityMainInformationPOCFormConfig({ closeForm, operation, poc_id, fac_id })}
        />
      </StyledForm>
    </Modal>
  ) : null;
}

export default FacilityMainInformationPOCContactInfoForm;
