import React from "react";
import GetComponent from "../GetComponent";
import PageInquiriesLogs from "./PageInquiriesLogs";

const GetInquiriesLogs = (props) => (
  <GetComponent
    authority="test"
    resource="printShop"
    Component={PageInquiriesLogs}
    params={{ action: "get_dashboard_counts" }}
    {...props}
  />
);

export default GetInquiriesLogs;
