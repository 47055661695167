import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import CognitoUserPool from "./AuthAWSCognitoUserPool";
import { errorString } from "../../utils";

// Refer to use case 12:
// https://www.npmjs.com/package/amazon-cognito-identity-js

function AuthForgotPasswordSubmitHandler({
  setLoading,
  setError,
  values: { email: Username },
  componentState: { setCognitoUser, history },
}) {
  setLoading(true);
  setError(null);

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username,
    Pool: CognitoUserPool,
  });

  cognitoUser.forgotPassword({
    onSuccess: () => {
      setCognitoUser(cognitoUser);
      history.push("/reset-password");
    },
    onFailure: (err) => {
      setLoading(null);
      setError(errorString(err));
    },
  });
}

export default AuthForgotPasswordSubmitHandler;
