import React from "react";
import styled from "styled-components";

const FormTextareaOneContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const FormTextareaOneLabel = styled.label`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.288px;
  color: rgb(48, 48, 48);
  margin-bottom: 7px;
`;

const FormTextareaOneTextarea = styled.textarea`
  flex-grow: 1;
  background: unset !important;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #1f1f1f;
  margin: 0;
  padding: 15px 25px;
`;

const FormTextareaOne = ({ key, label, rows, value, ...rest }) => (
  <FormTextareaOneContainer key={key}>
    {label ? <FormTextareaOneLabel>{label}</FormTextareaOneLabel> : ""}
    <FormTextareaOneTextarea rows={rows || 3} value={value || ""} {...rest} />
  </FormTextareaOneContainer>
);

export default FormTextareaOne;
