import { TableColumn } from "../Table";

const FacilityTemplateLetter = {
  resource: "certificate",
  addParams: { action: "get_let_history_for_facility" },
  table: {
    primaryKey: "let_history_list",
    title: "Letters History",
    columns: [
      TableColumn.view({
        condition: (e) =>
        (e.row.original.cert_type_code !== "EX" && e.row.original.item_pdf_url !== "") ||
        (e.row.original.type !== "Pending" && e.row.original.item_pdf_url !== ""),
        onClick: (e) => window.open(e.row.original.item_pdf_url, "_blank"),
      }),
      {
        Header: "Letter Type",
        accessor: "cert_type_code",
      },
      {
        Header: "Letter Mailed Date",
        accessor: "mailed_date",
      },
    ],
  },
};

export default FacilityTemplateLetter;
