import React from "react";
import ButtonFlavor from "./ButtonFlavor";
import styled from "styled-components";
import { Link } from "react-router-dom";

const style = ({ active, align, display, justify, theme, flavor, ...rest }) => `
  background: unset;
  text-decoration: none;
  color: unset;
  display: ${display || "flex"};
  justify-content: ${justify || "center"};
  align-items: ${align || "center"};
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  font-family: ${theme.fontFamily.primary};
  transition: 0.2s;
  &:hover,
  &:focus,
  &:focus-within {
    outline: 0;
    opacity: 0.9;
  };
  ${ButtonFlavor[flavor] || ""}
`;

const StyledA = styled.a(style);

const StyledButton = styled.button(style);

const StyledLink = styled(Link)(style);

const Button = (props) =>
  props.href ? (
    <StyledA target="_blank" rel="noopener noreferrer" {...props} />
  ) : props.to ? (
    <StyledLink {...props} />
  ) : (
    <StyledButton type="button" {...props} />
  );

export default Button;
