import constants from "../../constants";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import sendFetchRequest from "./sendFetchRequest";
import sendS3Request from "./sendS3Request";

const authorizeAndSendFetchRequest = ({ isS3, ...requestProps }) =>
  new Promise((resolve, reject) => {
    const { CognitoUserPoolId, CognitoClientId } = constants.aws;

    const cognitoUserPool = new CognitoUserPool({
      UserPoolId: CognitoUserPoolId,
      ClientId: CognitoClientId,
    });

    const cognitoUser = cognitoUserPool && cognitoUserPool.getCurrentUser();

    const authorizeSession = () =>
      cognitoUser.getSession((err, session) => {
        const Authorization = session.getIdToken().getJwtToken();
        const props = { Authorization, resolve, reject, ...requestProps };
        err
          ? reject(err)
          : !session.isValid()
          ? reject("Invalid session. Please sign-in again for new session.")
          : isS3
          ? sendS3Request(props)
          : sendFetchRequest(props);
      });

    cognitoUser
      ? authorizeSession()
      : reject("Unable to authenticate user session.");
  });

export default authorizeAndSendFetchRequest;
