import React, { useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../Auth";
import { LogoutSVG } from "../Containers";
import Dropdown from "../Dropdown";
import { string as simpulString } from "simpul";

const Circle = styled.p`
  margin: 0;
  margin-right: 10px;
  background: #2d6ee7;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.p`
  color: #161616;
  margin: 0;
`;

function HeaderUserButton() {
  const user = useContext(UserContext);
  const name = user && user.username && user.username.split("@")[0];
  const firstLetter = name && name.slice(0, 1).toUpperCase();
  const options = [
    {
      onClick: () => user && user.clearSession(),
      SVG: LogoutSVG,
      label: "Logout",
    },
  ];
  return name ? (
    <Dropdown options={options}>
      <Circle>{firstLetter}</Circle>
      <Name>{simpulString.capitalize(name)}</Name>
    </Dropdown>
  ) : null;
}

export default HeaderUserButton;
