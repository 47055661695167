import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { PageTitle, Modal, ModalTitle2 } from "../Containers";
import { Table } from "../Table";
import PrintShopProgressBar from "./PrintShopProgressBar";
import PrintShopProgressBarIsCertificate from "./PrintShopProgressBarIsCertificate";
import PrintShopProgressBarIsExp from "./PrintShopProgressBarIsExp";
import PrintShopTableColumns from "./PrintShopTableColumns";
import { fetcher, formatDate } from "../../utils";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledModal = styled(Modal)`
  width: 550px;
  form {
    padding: 45px 40px;
    box-sizing: border-box;
    div {
      flex-direction: row;
      align-items: center;
    }
    div:not(:first-child):not(:last-child) {
      margin-top: 12px;
    }
    label {
      width: 200px;
      padding-right: 25px;
      text-align: right;
    }
    .date,
    input {
      flex-grow: 1;
    }
    .date,
    .date div {
      margin-top: unset !important;
    }
    .submit {
      margin-top: 40px;
      justify-content: center;
      button {
        width: 110px;
      }
    }
  }
`;

function PrintShop({ ui, ...props }) {

  delete props.params["main_search"];

  const [selected, setSelected] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [progressBarState, setProgressBarState] = useState({ currentIndex: 0 });

  const uiConfigs = [
    {
      condition: ui.isCertificate,
      title: "Certificates & Letters",
      progressBar: PrintShopProgressBarIsCertificate,
      accessor: "cert_id",
      accessorType: "cert_type_code",
    },
    {
      condition: ui.isExp,
      title: "Expired Letters",
      progressBar: PrintShopProgressBarIsExp,
      accessor: "let_id",
      accessorType: "let_type_code",
    },
  ];

  const uiConfig = uiConfigs.find((i) => i.condition) || {};

  const { list } = props.match.params;

  const { Component: ProgressBarComponent } =
    (uiConfig.progressBar &&
      uiConfig.progressBar[progressBarState.currentIndex]) ||
    {};

  const progressBarStateProps = {
    state: progressBarState,
    updateState: (update) =>
      setProgressBarState({ ...progressBarState, ...update }),
    clickHandler: (operation, item_type) => {

      let selected_with_codes = [];
      if (selected[0] === "all") {
        if (item_type === "package") {
          selected_with_codes = props.data.current_page.map((select) => ({
            cert_id: select.cert_id,
            cert_type_code: select.cert_type_code,
          }));
        } else if (item_type === "letter" || item_type === "let_package") {
          selected_with_codes = props.data.current_page.map((select) => ({
            let_id: select.let_id,
            let_type_code: select.let_type_code,
          }));
        }
      }
      if (selected[0] !== "all") {
        if (item_type === "package") {
          selected_with_codes = selected.map((select) => ({
            cert_id: select.id,
            cert_type_code: select.code,
          }));
        }  else if (item_type === "letter") {
          selected_with_codes = selected.map((select) => ({
            let_id: select.id,
            let_type_code: select.code,
          }));
        } else if (item_type === "let_package") {
          selected_with_codes = selected.map((select) => ({
            let_id: select.id,
            let_type_code: "Exp",
          }));
        }
      }

      setProgressBarState({ ...progressBarState, status: "pending" });
      const dataToSend = {
        operation: operation,
        item_type: item_type,
        item_list_type: "selected",
        select_item_list: selected_with_codes,
        mail_date: formatDate(new Date()),
        search: "",
        cert_lang: "EN",
      };

      fetcher
        .post({
          authority: "test",
          path: "/printshop",
          data: dataToSend,
        })
        .then((responseData) => {
          console.log(responseData);
          setTimeout(() => {
            setProgressBarState({ ...progressBarState, status: "success" });
            setTimeout(() => {
              setProgressBarState({
                ...progressBarState,
                currentIndex: uiConfig.progressBar[
                  progressBarState.currentIndex + 1
                ]
                  ? progressBarState.currentIndex + 1
                  : progressBarState.currentIndex,
              });
            }, 500);
          }, 500);
          if (operation === "confirm_success") {
            setOpenConfirmationModal(true);
            setTimeout(() => {
              return (window.location = "/printshop");
            }, 2000);
          }
        })
        .catch((responseError) => {
          console.log(responseError);
        });
    },
  };

  return (
    <>
      <Fragment>
        {uiConfig.progressBar ? (
          <PrintShopProgressBar
            progressBar={uiConfig.progressBar}
            {...progressBarStateProps}
          />
        ) : null}
        <Container>
          <PageTitle>
            {uiConfig.title}
            {uiConfig.title && list ? " / " + list : list ? list : ""}
          </PageTitle>
          {ProgressBarComponent ? (
            <ProgressBarComponent
              selected={selected}
              hasSelected={Boolean(selected.length)}
              {...progressBarStateProps}
            />
          ) : null}
        </Container>
        <Table
          includeSearch
          includeCount
          selectColumnProps={{
            accessor: uiConfig.accessor,
            selected,
            setSelected,
            transformKeys: {
              id: uiConfig.accessor,
              code: uiConfig.accessorType,
            },
          }}
          columns={PrintShopTableColumns({
            isExp: ui.isExp,
            isLetter: ui.isLetter,
          })}
          {...props}
        />
      </Fragment>
      {openConfirmationModal && (
        <StyledModal>
          <ModalTitle2>Print Job Succeeded</ModalTitle2>
          <form>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>
                All documents and labels were printed successfully! You will be
                redirected to the print shop page.
              </p>
            </div>
          </form>
        </StyledModal>
      )}
    </>
  );
}

export default PrintShop;
