import React, { Fragment } from "react";
import { Button } from "../Containers";
import styled from "styled-components";
import FormTextareaOne from "../Form/FormTextareaOne";
import { fetcher } from "../../utils";

const NotesContainer = styled.div`
  
  white-space: break-spaces;
  form#edit-notes div:first-child {
    margin-bottom: 0;
  }
`;
/*const commentsFormConfig = {
    id: "add-comments",
    components: [
      {
        component: "FormTextareaOne",
        name: "comment",
        required: true,
      },
      {
        component: "FormSubmitButtonBlueRed",
        submitButton: {
          label: "ADD COMMENT",
          name: "submit_edit_notes",
          onSubmit: {
            authority: "test",
            postAPIKey: "facilities",
            transformValues: (values) => ({
              operation: "Update",
            }),
            onSuccess: () => window.location.reload(),
          },
        },
        redButton: {
          label: "CANCEL",
          name: "cancel_edit_notes",
          onClick: () => setShowForm(false),
        },
      },
      {
        component: "FormMessageError",
      },
    ],
  };*/
export const Comments = ({ comments, tkt_id, username }) => {
    const[showCommentsForm, setShowCommentsForm] = React.useState(false);
    const [commentToAdd, setCommentToAdd] = React.useState("");
    const addNewComment = () => {
        console.log(commentToAdd)
        console.log(tkt_id)
        fetcher.post({
          authority: "test",
          path: "/certificate",
          data: {
            operation: "create_ticket_comment",		
            cert_ticket_comment_record: {
              tkt_id,
              comment: commentToAdd,
              username
              }
          }
        }).then((responseData) => {
          console.log(responseData);
          setCommentToAdd("");
          setShowCommentsForm(false);
          window.location.reload()
        })
        .catch((responseError) => {
          console.log(responseError);
        });
      }
   return ( 
       <>
   <div>Comments</div>
      <Button 
        flavor="blueButton" 
        onClick={() => setShowCommentsForm(!showCommentsForm)}
        >+ Comment</Button>
      <Fragment>
      <NotesContainer>
      {showCommentsForm && (
          <form>
            <FormTextareaOne 
              name={"comment"}
              value={commentToAdd}
              onChange={(e) => setCommentToAdd(e.target.value)}
              required={true}
            />
            <div style={{display: "flex", justifyContent: "flex-start"}}>
            <Button 
              flavor="blueButton" 
              onClick={addNewComment}
              >ADD</Button>
              <Button 
              flavor="redButton" 
              onClick={() => setShowCommentsForm(false)}
              label={"CANCEL"}
              style={{marginLeft: 10}}
              >CANCEL</Button>
              </div>
          </form>
        )}
        {comments.map(comment => 
        <div style={{border: "1px solid black", padding: 10, margin: "10px 0"}}>
            {comment.tkt_comment}
            <br/>
            {comment.tkt_username}
            <br/>
            {comment.tkt_comment_dt}
        </div>)}
      </NotesContainer>
    </Fragment>
    </>
    )
}