import { CodeSVG, PasswordSVG } from "../Containers";
import AuthResetPasswordSubmitHandler from "./AuthResetPasswordSubmitHandler";

const AuthResetPasswordSchema = ({ componentState }) => ({
  id: "reset-password",
  components: [
    [
      {
        component: "FormInputOne",
        name: "verificationCode",
        label: "Verification Code",
        placeholder: "Enter Verification Code",
        SVG: CodeSVG,
        maxLength: 20,
        required: true,
      },
      {
        component: "FormInputOne",
        type: "password",
        name: "password",
        label: "Password",
        placeholder: "Enter Password",
        autoComplete: "new-password",
        SVG: PasswordSVG,
        minLength: 8,
        required: true,
      },
      {
        component: "FormSubmitButton",
        flavor: "blueButtonBig",
        label: "RESET PASSWORD",
        name: "submit_reset_password",
        title: "Submit reset password form.",
        onSubmit: {
          customHandler: (props) =>
            AuthResetPasswordSubmitHandler({ ...props, componentState }),
        },
      },
      {
        component: "FormMessageError",
      },
    ],
  ],
});

export default AuthResetPasswordSchema;
