import { TableColumn } from "../Table";

const FacilitiesColumns = [
  TableColumn.fac_id({ openNewTab: false }),
  {
    Header: "Facility Name",
    accessor: "fac_name",
    width: 400,
  },
  {
    Header: "MPRIS Certificate Point of Contact",
    accessor: "fac_poc",
    width: 200,
  },
  {
    Header: "Current Certification Status",
    accessor: "curr_cert_type",
    width: 210,
  },
  {
    Header: "Certification Expiration Date",
    accessor: "cert_expiry_date",
    width: 125,
  },
];

export default FacilitiesColumns;
