import React, { Fragment } from "react";
import { ArrowLeft2, ArrowRight2 } from "./SVG";

const LeftRightButtons = ({ onLeftClick, onRightClick }) => (
  <Fragment>
    <ArrowLeft2
      role="button"
      style={{ cursor: "pointer" }}
      onClick={onLeftClick}
    />
    <ArrowRight2
      role="button"
      style={{ cursor: "pointer" }}
      onClick={onRightClick}
    />
  </Fragment>
);

export default LeftRightButtons;
