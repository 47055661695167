import React from "react";

export const LogoSVG = (props) => (
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1285 26.274C18.6734 26.274 20.7365 28.3391 20.7365 30.8865C20.7365 33.4339 18.6734 35.499 16.1285 35.499C13.5836 35.499 11.5205 33.4339 11.5205 30.8865C11.5205 28.3391 13.5836 26.274 16.1285 26.274Z"
      fill="#3176F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3196 23.9331C24.0353 22.6853 26.4367 23.066 27.6833 24.7834C28.9299 26.5008 28.5495 28.9046 26.8338 30.1524C25.118 31.4001 22.7166 31.0194 21.47 29.302C20.2235 27.5846 20.6038 25.1808 22.3196 23.9331Z"
      fill="#3176F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5317 18.1675C26.187 16.1485 28.3534 15.0436 30.3703 15.6996C32.3873 16.3556 33.4911 18.5241 32.8358 20.543C32.1804 22.562 30.0141 23.6669 27.9971 23.0109C25.9801 22.3549 24.8763 20.1864 25.5317 18.1675Z"
      fill="#3176F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6103 13.3871C25.2377 12.3889 24.9335 10.4658 25.9307 9.09191C26.928 7.71798 28.8491 7.4134 30.2217 8.41163C31.5943 9.40985 31.8986 11.3329 30.9013 12.7068C29.9041 14.0807 27.9829 14.3853 26.6103 13.3871Z"
      fill="#3176F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2717 9.36191C20.5751 9.36191 19.1997 7.98519 19.1997 6.28691C19.1997 4.58864 20.5751 3.21191 22.2717 3.21191C23.9684 3.21191 25.3438 4.58864 25.3438 6.28691C25.3438 7.98519 23.9684 9.36191 22.2717 9.36191Z"
      fill="#6AD4D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7149 5.84642C15.6854 6.59508 14.2446 6.36665 13.4967 5.3362C12.7487 4.30575 12.9769 2.86349 14.0064 2.11483C15.0358 1.36616 16.4767 1.59459 17.2246 2.62504C17.9725 3.65549 17.7443 5.09775 16.7149 5.84642Z"
      fill="#6AD4D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4074 6.23019C11.0142 7.44155 9.71435 8.10449 8.50416 7.71089C7.29398 7.31729 6.63169 6.01621 7.02491 4.80485C7.41812 3.59348 8.71793 2.93054 9.92811 3.32414C11.1383 3.71774 11.8006 5.01882 11.4074 6.23019Z"
      fill="#6AD4D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.79898 12.3806C6.40576 13.5919 5.10595 14.2549 3.89577 13.8613C2.68558 13.4677 2.02329 12.1666 2.41651 10.9552C2.80972 9.74387 4.10953 9.08094 5.31972 9.47453C6.5299 9.86813 7.19219 11.1692 6.79898 12.3806Z"
      fill="#6AD4D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.76502 18.1109C4.02716 18.9185 3.58563 19.7859 2.77884 20.0483C1.97205 20.3107 1.10551 19.8687 0.84337 19.0612C0.581228 18.2536 1.02275 17.3862 1.82954 17.1238C2.63633 16.8614 3.50287 17.3034 3.76502 18.1109Z"
      fill="#F6D86C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.74268 23.493C5.42898 23.9921 5.58112 24.9536 5.08249 25.6406C4.58387 26.3275 3.6233 26.4798 2.93701 25.9807C2.25071 25.4816 2.09857 24.5201 2.59719 23.8331C3.09582 23.1462 4.05639 22.9939 4.74268 23.493Z"
      fill="#F6D86C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.13208 27.9579C8.47523 28.2075 8.5513 28.6882 8.30199 29.0317C8.05268 29.3752 7.57239 29.4513 7.22925 29.2018C6.8861 28.9522 6.81003 28.4715 7.05934 28.128C7.30865 27.7845 7.78893 27.7084 8.13208 27.9579Z"
      fill="#F6D86C"
    />
  </svg>
);
