import React from "react";
import Dropdown from "../Dropdown";
import {
  StandardContainer,
  StandardLabel,
  StandardInputDiv,
} from "./FormStyles";
import { DownCaretAltSVG } from "../Containers";
import styled from "styled-components";

const StyledDownCaretSVG = styled(DownCaretAltSVG)`
  position: absolute;
  right: 12px;
  top: 3px;
`;

function FormDropdownStandard({
  key,
  options,
  label,
  value,
  onChange,
  style,
  caretSVGStyle,
}) {
  options = options.map((option) => ({
    onClick: () => onChange(option),
    label: option,
  }));

  return (
    <Dropdown key={key} options={options}>
      <StandardContainer>
        <StandardLabel>{label}</StandardLabel>
        <StandardInputDiv style={style}>
          {value}
          <StyledDownCaretSVG style={caretSVGStyle} />
        </StandardInputDiv>
      </StandardContainer>
    </Dropdown>
  );
}

export default FormDropdownStandard;
