import React from "react";
import { isArray } from "simpul";
import FormDropdownStandard from "./FormDropdownStandard";
import FormDropdownNew from "./FormDropdownNew";
import FormDropdownNewNew from "./FormDropdownNewNew";

import * as FormInputDate from "./FormInputDate";
import FormInputOne from "./FormInputOne";
import FormInputStandard from "./FormInputStandard";
import FormLinkOne from "./FormLinkOne";
import { FormMessageSuccess, FormMessageError } from "./FormMessage";
import FormSubmitButton from "./FormSubmitButton";
import FormSubmitButtonBlueRed from "./FormSubmitButtonBlueRed";
import FormTextareaOne from "./FormTextareaOne";
import FormFileInput from "./FormFileInput";
import styled from "styled-components";

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "column"};
  width: ${({ direction }) => (direction ? "fit-content" : "100%")};
`;

function FormComponentResolver({
  components,
  componentsDirection,
  ...formProps
}) {
  const resolver = {
    FormComponentResolver,
    FormDropdownStandard,
    FormInputOne,
    FormLinkOne,
    FormInputStandard,
    FormMessageSuccess,
    FormMessageError,
    FormSubmitButton,
    FormSubmitButtonBlueRed,
    FormTextareaOne,
    FormFileInput,
    FormDropdownNew,
    FormDropdownNewNew,
    ...FormInputDate,
  };

  function returnComponent({ component, ...restComponent }, key) {
    const {
      disabled,
      error,
      values,
      handleChange,
      setOnSubmitConfig,
    } = formProps;
    const commonProps = { key, disabled, ...restComponent };
    const componentProps =
      component === "FormMessageError"
        ? { error, ...commonProps }
        : component === "FormMessageSuccess" || component === "FormLinkOne"
        ? commonProps
        : component === "FormSubmitButton" ||
          component === "FormSubmitButtonBlueRed"
        ? { setOnSubmitConfig, ...commonProps }
        : {
            value: values[restComponent.name],
            onChange: (e) =>
              handleChange({
                [restComponent.name]: e && e.target ? e.target.value : e,
              }),
            ...commonProps,
          };
    !resolver[component] && console.warn("Missing component: " + component);
    return resolver[component] ? resolver[component](componentProps) : null;
  }

  return components.filter(Boolean).map((component, index) =>
    isArray(component) ? (
      <ComponentContainer key={index} direction={componentsDirection}>
        {FormComponentResolver({ ...formProps, components: component })}
      </ComponentContainer>
    ) : (
      returnComponent(component, index)
    )
  );
}

export default FormComponentResolver;
