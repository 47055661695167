import React from "react";
import GetComponent from "../GetComponent";
import PrintShop from "./PrintShop";
import constants from "../../constants";
import { getSearchParams } from "../../utils";

function GetPagePrintShop(props) {
  const { type, getParam, code } =
    constants.printShop.find((i) => i.cardLabel === props.match.params.list) ||
    {};
  // Search param keys are declared in the Print Shop Dashboard
  // Form @ "../PagePrintShopDashboard/PrintShopDashbordForm.js"
  const searchParams = getSearchParams(props.location.search, "object");

  props = {
    ...props,
    ui: {
      isCertificate: type === "certificate" && code !== "Exp",
      isExp: code === "Exp" || searchParams.fac_id,
    },
  };

  const params =
    (props.ui.isCertificate && {
      action: "get_certificate_list",
      cert_type_code: getParam || code,
    }) ||
    (props.ui.isExp && {
      action: "get_letter_list",
      let_type_code: getParam || code,
    });

  return (
    <GetComponent
      authority="test"
      resource="printShop"
      params={{
        page: 0,
        sort_column: 0,
        sort_direction: "asc",
        ...params,
      }}
      Component={PrintShop}
      {...props}
    />
  );
}

export default GetPagePrintShop;
