import React from "react";
import GetMultipleComponent from "../../GetMultipleComponent";
import EditLog from "./EditLog";
const ViewLog = (props) => (

  <GetMultipleComponent
    authority={["test", "test"]}
    resource={["certificate", "certificate"]}
    params={[
      {
        action: "get_cert_ticket_details",
        tkt_id: props.match.params.tkt_id,
      },
      {
        action: "get_cert_ticket_comments",
        tkt_id: props.match.params.tkt_id,
      },
    ]}
    Component={EditLog}
    {...props}
  />
);

export default ViewLog;
