import { fetcher } from "../../../utils";
import constants from "../../../constants";

export const retrieveDropdowns = (setLoading, setDropdownInfo) => {
    setLoading(true);
    fetcher
    .get({
      authority: "test",
      path: constants.api.path.certificate,
      params: {
        action: "get_cert_ticket_dropdowns",
      },
    })
    .then((responseData) => {
      console.log(responseData);
      setDropdownInfo(responseData);
      setLoading(false);
    })
    .catch((responseError) => {
      console.log(responseError);
      setLoading(false);
    });
};

export const formDictionary = {
    tkt_id: "Ticket ID",
    category: "Category",
    subcategory: "Subcategory",
    status: "Status",
    disposition: "Disposition",
    source: "Source",
    date_created: "Date Created",
    date_closed: "Date Closed",
    reported_by: "Reported By",
    inquiry_summary: "Inquiry Summary",
    fac_id: "Facility ID",
    fac_name: "Facility Name",
    fac_addline1: "Facility Address Line 1",
    fac_addline2: "Facility Address Line 2",
    fac_city: "Facility City",
    fac_state: "Facility State",
    fac_zip: "Facility Zip",
    fac_phone: "Facility Phone",
    poc_id: "Caller ID",
    poc_title: "Caller Title",
    poc_full_name: "Caller Full Name",
    poc_email: "Caller Email",
    poc_primary_phone: "Caller Primary Phone"
  }