import React, { Fragment } from "react";
import { Button } from "../Containers";
import styled from "styled-components";

const NavContainer = styled.div`
  display: flex;
  background: linear-gradient(180deg, #7ab3ea 0%, #236bed 100%);
  border-radius: 2px;
  margin-top: 20px;
  align-items: center;
  width: 100%;
`;

const Divider = styled.span`
  background: #fff;
  height: 20px;
  width: 1px;
`;

const StyledButton = styled(Button)`
  padding: 10px 15px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  flex: 1;
  text-transform: uppercase;
  height: 100%;
  ${({ active }) =>
    active === "true"
      ? `
          color: #202020;
          background: #FCFCFC;
          border: 0.5px solid #979797;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
        `
      : ""}
`;

const FacilityMenu = ({ nav, currentTab, setCurrentTab }) => (
  <NavContainer>
    {nav.map((navItem, navItemIndex) => {
      const isActive = navItem.machine_name === currentTab;
      const previousNavItem = nav[navItemIndex - 1];
      const previousIsActive =
        previousNavItem && previousNavItem.machine_name === currentTab;
      return (
        <Fragment key={navItemIndex}>
          {navItemIndex && !isActive && !previousIsActive ? <Divider /> : null}
          <StyledButton
            onClick={() => !navItem.to && setCurrentTab(navItem.machine_name)}
            active={isActive.toString()}
            to={navItem.to}
          >
            {navItem.label}
          </StyledButton>
        </Fragment>
      );
    })}
  </NavContainer>
);

export default FacilityMenu;
