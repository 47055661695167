import { TableColumn } from "../Table";

const LogsColumns = [
  TableColumn.cert_tkt_id({ openNewTab: false }),
  TableColumn.ticket_fac_id({ openNewTab: false }),
  {
    Header: "Facility Name",
    accessor: "fac_name",
    width: 400,
  },
  {
    Header: "Date Created",
    accessor: "date_created",
    width: 125,
  },
  {
    Header: "Date Closed",
    accessor: "date_closed",
    width: 125,
  },
  {
    Header: "Status",
    accessor: "status",
    width: 125,
  },
  {
    Header: "Category",
    accessor: "category",
    width: 125,
  },
  {
    Header: "Subcategory",
    accessor: "subcategory",
    width: 125,
  }
];

export default LogsColumns;
