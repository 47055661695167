import React from "react";
import { Button, Loader } from "../Containers";

const FormSubmitButton = ({
  label,
  onSubmit,
  setOnSubmitConfig,
  altLoader,
  ...rest
}) => (
  <Button type="submit" onClick={() => setOnSubmitConfig(onSubmit)} {...rest}>
    {rest.disabled ? altLoader ? "..." : <Loader /> : label}
  </Button>
);

export default FormSubmitButton;
