import TableColumnExpand from "./TableColumnExpand";
import TableColumnFacId from "./TableColumnFacId";
import TableColumnTicketFacId from "./TableColumnTicketFacId";
import TableColumnCertTicketId from "./TableColumnCertTicketId";
import TableColumnInvoiceNumber from "./TableColumnInvoiceNumber";
import TableColumnPencil from "./TableColumnPencil";
import TableColumnExclamationError from "./TableColumnExclamationError";
import TableColumnErrorMessage from "./TableColumnErrorMessage";
import TableColumnSelect from "./TableColumnSelect";
import TableColumnView from "./TableColumnView";
import TableColumnSF215File from "./TableColumnSF215File";
import TableColumnReconciledPayments from "./TableColumnReconciledPayments";
import TableColumnSelectRow from "./TableColumnSelectRow";
const TableColumn = {
  expand: TableColumnExpand,
  ticket_fac_id: TableColumnTicketFacId,
  fac_id: TableColumnFacId,
  cert_tkt_id: TableColumnCertTicketId,
  inv_num: TableColumnInvoiceNumber,
  download_sf215_file: TableColumnSF215File,
  download_reconciled_payments: TableColumnReconciledPayments,
  pencil: TableColumnPencil,
  exclamation: TableColumnExclamationError,
  errorMessage: TableColumnErrorMessage,
  select: TableColumnSelect,
  view: TableColumnView,
  selectRow: TableColumnSelectRow,
};

export default TableColumn;
