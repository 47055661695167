import React, { useState } from "react";
import { StandardContainer, StandardLabel } from "./FormStyles";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  margin-bottom: 7px;
`;

const StyledInput = styled.input`
  margin-right: 5px;
`;

function FormRadioButtonInput({ key, label, options, ...rest }) {
  const [radioValue, setRadioValue] = useState();
  //console.log(radioValue);
  function handleChange(e) {
    rest.onChange(e.target.value);
    setRadioValue(e.target.value);
  }
  return (
    <StandardContainer className="checkbox-standard-container" key={key}>
      {options.map((option, index) => (
        <StyledDiv key={index}>
          <StyledInput
            type="radio"
            name={option.name}
            value={option.value}
            onChange={handleChange}
          />
          <StandardLabel
            htmlFor={option.value}
            onClick={handleChange}
            excludeColon
          >
            {option.label}
          </StandardLabel>
        </StyledDiv>
      ))}
    </StandardContainer>
  );
}

export default FormRadioButtonInput;
