import React from "react";
import GetComponent from "../GetComponent";
import PageReports from "./PageReports";

const GetReports = (props) => (
  <GetComponent
    authority="test"
    resource="printShop"
    Component={PageReports}
    params={{ action: "get_dashboard_counts" }}
    {...props}
  />
);

export default GetReports;