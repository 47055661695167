import React from "react";
import { Button } from "../Containers";

const TableColumnView = ({ onClick, condition, ...rest }) => ({
  id: "view",
  Cell: (e) => {
    const props = {
      flavor: "blueLink",
      style: { width: "100%" },
      onClick: () => onClick(e),
      ...rest,
    };
    return condition ? (
      condition(e) ? (
        <Button {...props}>View</Button>
      ) : (
        ""
      )
    ) : (
      <Button {...props}>View</Button>
    );
  },
});

export default TableColumnView;
