import React from "react";
import styled from "styled-components";
import { Button } from "../Containers";

const StyledButton = styled(Button)`
  width: fit-content;
  font-weight: 300;
  font-style: italic;
`;

const TableSelectReset = ({ selectProps }) =>
  selectProps && selectProps.selected ? (
    <StyledButton
      disabled={!selectProps.selected.length}
      onClick={() => selectProps.setSelected([])}
    >
      Reset selections?
    </StyledButton>
  ) : (
    ""
  );

export default TableSelectReset;
