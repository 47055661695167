import styled from "styled-components";

export const DashboardForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 30px;
  form {
    margin-top: 25px;
    width: ${({ width }) => width || "550px"};
    background: #ffffff;
    border: 0.5px solid #d2cccc;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    padding: 18px 35px;
    div[direction="row"] {
      width: 100%;
      div {
        flex: 1;
      }
    }
    input {
      border: 0.5px solid #b3b3b3;
    }
  }
`;
