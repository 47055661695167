import React from "react";
import styled from "styled-components";
import { LogoSVG } from "../Containers";
import constants from "../../constants";

const Box = styled.div`
  align-items: center;
  padding: 40px 30px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(75, 99, 131, 0.250027);
  width: 400px;
`;

const BoxTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxTitle = styled.h1`
  font-weight: 300;
  font-size: 38.4px;
  line-height: 46px;
  letter-spacing: 0.768px;
  color: #3176f2;
  opacity: 0.2;
  margin: 0 0 0 23px;
`;

const BoxSubtitle = styled.h2`
  margin: 8px 0;
  font-weight: normal;
  font-size: 19.2px;
  line-height: 23px;
  letter-spacing: 0.384px;
  color: #6a6a6a;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 70px 0;
`;

const AuthBox = (props) => (
  <Box>
    <BoxTitleRow>
      <LogoSVG />
      <BoxTitle>{constants.application.name.short}</BoxTitle>
    </BoxTitleRow>
    <BoxSubtitle>{constants.application.name.long}</BoxSubtitle>
    <Content {...props} />
  </Box>
);

export default AuthBox;
