import React, { useState } from "react";
import Dropdown from "../Dropdown";
import {
  StandardContainer,
  StandardLabel,
  StandardInputDiv,
} from "./FormStyles";
import { DropdownContainer } from "../Dropdown/DropdownContainers";

import { DownCaretAltSVG, DownCaretSVG } from "../Containers";
import styled from "styled-components";
import "./FormDropdownNew.css";
import { FaChevronDown } from "react-icons/fa";
import Select from 'react-select';
const StyledDownCaretSVG = styled(DownCaretAltSVG)`
  position: absolute;
  right: 12px;
  top: 3px;
`;

function FormDropdownNew({
  key,
  options,
  label,
  value,
  onChange,
  style,
  caretSVGStyle,
}) {
  /*options = options.map((option) => ({
    onClick: () => onChange(option),
    label: option,
  }));*/

  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(options);
  const [selectedItem, setSelectedItem] = useState(null);
  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    selectedItem === id ? setSelectedItem(null) : setSelectedItem(id);
    onChange(id);
    setOpen(false);
  };
  return (
    <>
      <div className="dropdown">
        <div className="dropdown-header" onClick={toggleDropdown}>
          {selectedItem
            ? items.find((item) => item.value === selectedItem).label
            : "Select Interim Type"}
          <FaChevronDown className={`icon ${isOpen && "open"}`} />
        </div>
        <div className={`dropdown-body ${isOpen && "open"}`}>
          {items.map((item) => (
            <div
              className="dropdown-item"
              onClick={(e) => handleItemClick(e.target.id)}
              key={item.label}
              id={item.value}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </>
  );
  /*const [currentValue, setCurrentValue] = useState();
  const handleChange = (event) => {
    setCurrentValue(event.target.value);
  };*/
  /*
  <option value="grapefruit">Grapefruit</option>
        <option value="lime">Lime</option>
        <option value="coconut">Coconut</option>
        <option value="mango">Mango</option>
        <DropdownContainer>
        <select
          value={currentValue}
          onChange={handleChange}
          style={{
            position: "relative",
            height: "calc(1.5em + 0.75em + 2px)",
            width: "100%",
            display: "block",
            lineHeight: 1.5,
            verticalAlign: "middle",
            border: "1px solid #ced4da",
            padding: "0.375em 1.75em 0.375rem 0.75rem",
          }}
        >
          {options.map((option) => (
            <option key={option.label} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </DropdownContainer>
      <StandardLabel>{label}</StandardLabel>

  */
}

export default FormDropdownNew;
