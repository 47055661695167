import React, { Fragment } from "react";
import styled from "styled-components";
import { PageTitle, PageSubtitle } from "../Containers";
import PrintShopDashboardCards from "./PrintShopDashboardCards";
import PrintShopLookupForm from "./PrintShopLookupForm";
import PrintShopInterimForm from "./PrintShopInterimForm";

const ContentContainer = styled.div`
  background: #fcfcfc;
  box-shadow: 3px 3px 8px rgba(8, 9, 54, 0.298842);
  margin-top: 15px;
  padding: 25px 75px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  h1.subtitle {
    margin: 0 0 40px 0;
  }
  form {
    margin-top: 0;
  }
`;

const PrintShopDashboard = (props) => {
  return (
    <Fragment>
      <PageTitle>Print Shop</PageTitle>
      <ContentContainer>
        <PageSubtitle>New Certificates and Letters</PageSubtitle>
        <PrintShopDashboardCards {...props} />
        <PrintShopInterimForm labelText={"Create/Print Interim Letter"} />
        <PrintShopLookupForm {...props} />
        <p>
          Reminder: Handle with Care/Do Not Bend labels and Return Address
          labels are printed via Word outside the MQSA system.
        </p>
      </ContentContainer>
    </Fragment>
  );
};

export default PrintShopDashboard;
