import React, { Fragment } from "react";
import { PageTitle } from "../Containers";
import { Table } from "../Table";
import FacilitiesColumns from "./FacilitiesColumns";

const Facilities = (props) => (
  <Fragment>
    <PageTitle>Facilities</PageTitle>
    <p>Search Facility ID or Facility Name</p>
    <Table includeSearch columns={FacilitiesColumns} {...props} />
  </Fragment>
);

export default Facilities;
