import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { ThemeProvider } from "./ThemeProvider";
import { Auth } from "./Auth";
import {
  ApplicationContainerPrimary,
  ApplicationContainerSecondary,
  NotFound,
} from "./Containers";
import Header from "./Header";
import Nav from "./Nav";
import Dashboard from "./PageDashboard";
import Facilities from "./PageFacilities";
import Facility from "./PageFacility";
import PrintShopDashboard from "./PagePrintShopDashboard";
import PrintShopList from "./PagePrintShopList";
import PrintShopLookup from "./PagePrintShopLookup";
import PrintShopInterimLetters from "./PagePrintShopInterimLetter";
import PageReports from "./PageReports";
import PageInquiriesLogs from "./PageInquiriesLogs";
import PageLogs from "./PageLogs";
import CreateLogs from "./PageLogs/CreateLogs/CreateLogs";
import ViewLog from "./PageLogs/ViewEditLogs/ViewLog";

const App = () => (
  <ThemeProvider>
    <Router>
      <Switch>
        <Auth>
          <ApplicationContainerPrimary>
            <Header />
            <Nav />
            <ApplicationContainerSecondary>
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/facilities" component={Facilities} />
                <Route exact path="/facilities/:fac_id" component={Facility} />
                <Route exact path="/printshop" component={PrintShopDashboard} />
                <Route
                  exact
                  path="/printshop/Lookup/:fac_id" // Must be before "/printshop/:list"
                  component={PrintShopLookup}
                />
                <Route
                  exact
                  path="/printshop/:interim_type/:fac_id" // Must be before "/printshop/:list"
                  component={PrintShopInterimLetters}
                />
                <Route
                  exact
                  path="/printshop/:list/"
                  component={PrintShopList}
                />
                <Route exact path="/reports" component={PageReports} />
                <Route exact path="/inquiries" component={PageInquiriesLogs} />
                <Route exact path="/inquiries/certification" component={PageLogs} />
                <Route exact path="/inquiries/certification/create" component={CreateLogs} />
                <Route exact path="/inquiries/certification/:tkt_id" component={ViewLog} />

                <Route component={NotFound} />
              </Switch>
            </ApplicationContainerSecondary>
          </ApplicationContainerPrimary>
        </Auth>
      </Switch>
    </Router>
  </ThemeProvider>
);

export default App;
