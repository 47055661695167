import React from "react";

export const PlusCircleSVG = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.400391 12.2999C0.400391 5.72771 5.7282 0.399902 12.3004 0.399902C18.8726 0.399902 24.2004 5.72771 24.2004 12.2999C24.2004 18.872 18.8726 24.1999 12.3004 24.1999C5.7282 24.1999 0.400391 18.872 0.400391 12.2999ZM11.6004 11.5999H6.00039V12.9999H11.6004V18.5999H13.0004V12.9999H18.6004V11.5999H13.0004V5.99989H11.6004V11.5999Z"
      fill="#0F6CEF"
    />
  </svg>
);
