import { TableColumn } from "../Table";

const FacilityTemplateCertification = {
  resource: "certificate",
  addParams: { action: "get_cert_history_for_facility" },
  table: {
    primaryKey: "cert_history_list",
    title: "Certificates History",
    columns: [ 
      TableColumn.view({
        condition: (e) =>  (e.row.original.cert_type_code !== "EX" && e.row.original.item_pdf_url !== "") ||
        (e.row.original.type !== "Pending" && e.row.original.item_pdf_url !== ""),
        onClick: (e) => window.open(e.row.original.item_pdf_url, "_blank"),
      }),
      {
        Header: "Certificate Number",
        accessor: "cert_num",
      },
      {
        Header: "Certificate Type",
        accessor: "cert_type_code",
      },
      {
        Header: "Certificate Reason",
        accessor: "reason",
      },
      {
        Header: "Mailed Date",
        accessor: "mailed_date",
      },
      {
        Header: "Expiration Date",
        accessor: "expiration_date",
      },
      {
        Header: "Language",
        accessor: "cert_lang",
      },
      {
        Header: "Copy Number",
        accessor: "cert_copy_nmbr",
      },
    ],
  },
};

export default FacilityTemplateCertification;
