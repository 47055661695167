import React from "react";
import styled from "styled-components";

export const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #1e1e1e;
  margin-top: 0;
`;

const StyledPageSubtitle = styled(PageTitle)`
  font-weight: 500;
  font-size: 22px;
`;

export const PageSubtitle = (props) => (
  <StyledPageSubtitle className="subtitle" {...props} />
);
