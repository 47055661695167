import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledFormLinkOne = styled(Link)`
  background: unset;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  border: 0px;
  cursor: pointer;
  font-family: Lato, sans-serif;
  transition: all 0.5s ease 0s;
  color: rgb(106, 106, 106);
  font-weight: 300;
  margin-top: 20px;
`;

const FormLinkOne = ({ label, ...rest }) => (
  <StyledFormLinkOne {...rest}>{label}</StyledFormLinkOne>
);

export default FormLinkOne;
