import React from "react";
import {
  PrintShopProgressbarContainer,
  PrintShopProgressBarButton,
} from "./PrintShopProgressBarComponents";
import { FormInputDateSingle } from "../Form";
import { MailSVG, PrintSVG2, SuccessSVG2, PlusCircleSVG } from "../Containers";

//"add_info", "let_package"
const PrintShopProgressBarIsInterimAddInfo = ({
  hasSelected,
  state,
  updateState,
  clickHandler,
}) => (
  <PrintShopProgressbarContainer>
    <PrintShopProgressBarButton
      hasSelected={hasSelected}
      SVG={PlusCircleSVG}
      onClick={() => clickHandler("print_label", "let_package")}
      {...state}
    >
      Add Info
    </PrintShopProgressBarButton>
  </PrintShopProgressbarContainer>
);

const PrintShopProgressBarIsInterimApplyMailDate = ({
  hasSelected,
  state,
  updateState,
  clickHandler,
}) => (
  <PrintShopProgressbarContainer>
    <FormInputDateSingle
      value={state.mailDate}
      onChange={(mailDate) => updateState({ mailDate })}
    />
    <PrintShopProgressBarButton
      hasSelected={hasSelected}
      SVG={MailSVG}
      onClick={() => clickHandler("set_mail_date", "let_package")}
      {...state}
    >
      Apply Mail Date
    </PrintShopProgressBarButton>
  </PrintShopProgressbarContainer>
);

const PrintShopProgressBarIsInterimPrintPackage = ({
  hasSelected,
  clickHandler,
  state,
}) => (
  <PrintShopProgressBarButton
    hasSelected={hasSelected}
    SVG={PrintSVG2}
    onClick={() => clickHandler("print", "let_package")}
    {...state}
  >
    Print Certificates/Letters
  </PrintShopProgressBarButton>
);

const PrintShopProgressBarIsInterimPrintLabel = ({
  hasSelected,
  clickHandler,
  state,
}) => (
  <PrintShopProgressBarButton
    hasSelected={hasSelected}
    SVG={PrintSVG2}
    onClick={() => clickHandler("print_label", "let_package")}
    {...state}
  >
    Print Labels
  </PrintShopProgressBarButton>
);

const PrintShopProgressBarIsInterimConfirmation = ({
  hasSelected,
  clickHandler,
  state,
}) => (
  <PrintShopProgressBarButton
    hasSelected={hasSelected}
    SVG={SuccessSVG2}
    onClick={() => clickHandler("confirm_success", "let_package")}
    {...state}
  >
    Confirm
  </PrintShopProgressBarButton>
);

const PrintShopProgressBarIsInterim = [
  {
    title: "Add Info",
    helperText: `Add POC, Fax number, and Address information to the letters`,
    Component: PrintShopProgressBarIsInterimAddInfo,
  },
  {
    title: "Apply Mail Date",
    helperText: `Select the rows for the items that you want to apply the mail date to and click the "Apply Mail Date" button.`,
    Component: PrintShopProgressBarIsInterimApplyMailDate,
  },
  {
    title: "Print Certificates/Letters",
    helperText: `Select the certificate packages and letters you want to print and then click the "Print" button.`,
    Component: PrintShopProgressBarIsInterimPrintPackage,
  },
  /*{
    title: "Print Labels",
    helperText: `Select the rows that you want to print mailing labels for and then click the "Print" button.`,
    Component: PrintShopProgressBarIsInterimPrintLabel,
  },*/
  {
    title: "Confirmation",
    helperText: `Please confirm that all documents and labels were printed successfully by clicking the "Confirm" button.`,
    Component: PrintShopProgressBarIsInterimConfirmation,
  },
];

export default PrintShopProgressBarIsInterim;
