import React from "react";

export const ArrowLeft = (props) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.595703 3.81687L3.50031 0.909424V3.31884H11.3804V4.3149H3.50031V6.72432L0.595703 3.81687Z"
      fill="#3176F2"
    />
  </svg>
);

export const ArrowLeft2 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15 18L9 12L15 6" stroke="#767778" strokeWidth="2" />
  </svg>
);
