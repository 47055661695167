import React from "react";

export const DownCaretSVG = (props) => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13 1L7 7L1 1" stroke="white" strokeWidth="2" />
  </svg>
);

export const DownCaretAltSVG = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6 19.2C14.9019 19.2 19.2 14.9019 19.2 9.6C19.2 4.29807 14.9019 0 9.6 0C4.29807 0 0 4.29807 0 9.6C0 14.9019 4.29807 19.2 9.6 19.2Z"
      fill="#006AF6"
    />
    <path
      d="M4 7.11114L9.59998 12.8L15.2 7.11114L14.4999 6.40002L9.59998 11.3778L4.7 6.40002L4 7.11114Z"
      stroke="white"
      strokeWidth="0.96"
    />
  </svg>
);
