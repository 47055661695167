import React from "react";
import GetComponent from "../GetComponent";
import Facilities from "./Facilities";

const GetFacilities = (props) => (
  <GetComponent
    authority="test"
    resource="facilities"
    params={{ page: 0, sort_column: 1, sort_direction: "asc" }}
    Component={Facilities}
    {...props}
  />
);

export default GetFacilities;
