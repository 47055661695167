import styled from "styled-components";

const TableStyled = styled.div`
  background: rgba(49, 118, 242, 0.0001);
  box-shadow: 3px 3px 8px rgba(8, 9, 54, 0.298842);
  margin: 15px 0;
  overflow: auto;

  table,
  tr {
    width: 100% !important;
  }

  table {
    border: 1px solid rgba(211, 211, 211, 0.40259);
    border-spacing: 0;
  }

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    padding: 0 20px;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid rgba(211, 211, 211, 0.40259);
    :last-child {
      border-right: 0;
    }
  }

  thead tr {
    height: 80px;
  }

  thead th {
    font-weight: normal;
    mix-blend-mode: normal;
    font-size: 16px;
    line-height: 19px;
    flex-grow: 1;
    color: #797979;
    opacity: 0.6;
    cursor: pointer;
  }

  tbody tr {
    height: 56px;
  }

  tbody td {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #1f1f1f;
    mix-blend-mode: normal;
    opacity: 0.6;
    text-align: left !important;
  }

  thead th:first-child,
  tbody tr td:first-child {
    padding-left: 40px;
  }
`;

export default TableStyled;
