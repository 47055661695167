import styled from "styled-components";

export const LabelOne = styled.h4`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #202020;
  margin: 0;
`;

export const LabelTwo = styled.h4`
  background: linear-gradient(180deg, #7ab3ea 0%, #236bed 100%);
  margin: 0;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
  text-align: center;
  padding: 8px 0;
`;

export const LabelThree = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #1f1f1f;
`;
