import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CognitoUserPool from "./AuthAWSCognitoUserPool";
import AuthBackground from "./AuthBackground";
import AuthBox from "./AuthBox";
import { Loader } from "../Containers";
import { Form } from "../Form";
import AuthSignInSchema from "./AuthSignInSchema";
import AuthNewPasswordSchema from "./AuthNewPasswordSchema";
import AuthForgotPasswordSchema from "./AuthForgotPasswordSchema";
import AuthResetPasswordSchema from "./AuthResetPasswordSchema";
import { getSearchParams } from "../../utils";

const UserContext = createContext();

function Auth({ children, location }) {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [loading, setLoading] = useState(true);
  const [cognitoUser, setCognitoUser] = useState();
  const [formSchema, setFormSchema] = useState();

  const history = useHistory();

  const redirect = () =>
    (location.pathname === "/forgot-password" ||
      location.pathname === "/new-password" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/sign-in") &&
    history.push("/");

  function clearSession(err) {
    err && console.error(err);
    cognitoUser && cognitoUser.signOut && cognitoUser.signOut();
    localStorage.clear();
    setIsAuthenticated(null);
    setCognitoUser(null);
    setLoading(null);
    history.push("/");
  }

  function authorizeSession(currentUser) {
    setIsAuthenticated(true);
    setCognitoUser(currentUser);
    redirect();
    setLoading(null);
  }

  useEffect(() => {
    const currentUser = CognitoUserPool.getCurrentUser();
    setTimeout(() => {
      currentUser
        ? currentUser.getSession((err) =>
            err ? clearSession(err) : authorizeSession(currentUser)
          )
        : clearSession();
    }, 500);
    // eslint-disable-next-line
  }, []);

  const componentState = { history, cognitoUser, setCognitoUser, clearSession };

  useEffect(() => {
    const { search, pathname } = location;
    const successSource = getSearchParams(search, "success");
    pathname === "/forgot-password"
      ? setFormSchema(AuthForgotPasswordSchema({ componentState }))
      : pathname === "/new-password"
      ? cognitoUser
        ? setFormSchema(AuthNewPasswordSchema({ componentState }))
        : history.push("/")
      : pathname === "/reset-password"
      ? cognitoUser
        ? setFormSchema(AuthResetPasswordSchema({ componentState }))
        : history.push("/")
      : setFormSchema(AuthSignInSchema({ componentState, successSource }));
    isAuthenticated && redirect();
    // eslint-disable-next-line
  }, [location]);

  return !isAuthenticated ? (
    <AuthBackground>
      {loading ? (
        <Loader height="200px" width="200px" />
      ) : (
        <AuthBox>{formSchema && <Form {...formSchema} />}</AuthBox>
      )}
    </AuthBackground>
  ) : (
    <UserContext.Provider value={{ ...cognitoUser, clearSession }}>
      {children}
    </UserContext.Provider>
  );
}

export { Auth, UserContext };
