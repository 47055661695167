import React from "react";
import GetComponent from "../GetComponent";
import PrintShopInterimLetters from "./PrintShopInterimLetters";
import constants from "../../constants";
import { getSearchParams } from "../../utils";

//so I want to get the certificates that have the facility ID
function GetPrintShopInterimLetter(props) {
  let { type, getParam, code } =
    constants.printShop.find(
      (i) =>
        i.cardLabel === props.match.params.interim_type && i.type === "letter"
    ) || {};

  // Search param keys are declared in the Print Shop Dashboard
  // Form @ "../PagePrintShopDashboard/PrintShopDashbordForm.js"
  const searchParams = getSearchParams(props.location.search, "object");
  props = {
    ...props,
    ui: {
      isInterim: type === "letter" || searchParams.fac_id,
    },
  };

  const params = props.ui.isInterim && {
    action: "get_interim_letter_list",
    fac_id: props.match.params.fac_id,
    let_type_code: getParam,
  };

  return (
    <GetComponent
      authority="test"
      resource="printShop"
      params={{
        page: 0,
        sort_column: 0,
        sort_direction: "asc",
        ...params,
      }}
      Component={PrintShopInterimLetters}
      {...props}
    />
  );
}

export default GetPrintShopInterimLetter;
