import React from "react";

export const HotlineSVG = (props) => (
  <svg
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.2138 21.1213L21.6981 18.2625C20.5883 17.6908 19.243 17.9935 18.4694 18.9688L16.8214 21.054C15.2407 20.1796 13.8954 19.2379 12.3147 17.6572C10.7004 16.0428 9.75868 14.6975 8.88424 13.0832L10.9694 11.4688C11.9448 10.6953 12.2475 9.34999 11.6757 8.24013L8.81698 2.72445C8.48066 2.08543 7.87528 1.58095 7.169 1.41279C6.46272 1.24463 5.68918 1.34552 5.0838 1.74911L1.55242 4.10337C0.509818 4.80964 -0.0619293 6.0204 0.00533513 7.2648C0.106232 8.67735 0.240761 9.78721 0.442554 10.5608C1.51879 14.6303 4.07483 18.8343 7.60622 22.3993C11.1712 25.9643 15.3753 28.4867 19.4448 29.563C20.2183 29.7648 21.3282 29.8993 22.7407 30.0002C22.808 30.0002 22.9089 30.0002 22.9761 30.0002C24.1533 30.0002 25.2631 29.3948 25.9021 28.3858L28.1891 24.8208C28.5927 24.2154 28.6936 23.4419 28.5255 22.7693C28.3573 22.063 27.8865 21.4576 27.2138 21.1213Z"
      fill="white"
    />
    <path
      d="M25.499 0H20.017C16.4519 0 13.5596 2.89237 13.5596 6.45739C13.5596 9.82061 16.1829 12.6121 19.4788 12.8811C19.4116 13.3184 19.3107 13.7892 19.1425 14.1928C18.9407 14.7982 19.1089 15.4372 19.6134 15.8408C19.8824 16.0762 20.2188 16.1771 20.5214 16.1771C20.7905 16.1771 21.0259 16.1098 21.2613 15.9753C22.6403 15.1345 23.8174 13.8901 24.6246 12.9148H25.4654C29.0304 12.9148 31.9228 10.0224 31.9228 6.45739C31.9228 2.89237 29.064 0 25.499 0ZM25.499 11.3677H24.2882C24.0528 11.3677 23.851 11.4686 23.6829 11.6368C22.9766 12.5448 21.8667 13.7556 20.5887 14.5628C20.8578 13.7892 20.9923 12.982 21.0259 12.1412C21.0259 11.9394 20.9587 11.7376 20.8241 11.6031C20.6896 11.4686 20.4878 11.3677 20.286 11.3677H20.017C17.2927 11.3677 15.073 9.14797 15.073 6.42376C15.073 3.69955 17.2927 1.47982 20.017 1.47982H25.5326C28.2569 1.47982 30.4766 3.69955 30.4766 6.42376C30.4429 9.1816 28.2232 11.3677 25.499 11.3677Z"
      fill="white"
    />
    <path
      d="M18.9406 7.76882C19.665 7.76882 20.2522 7.18157 20.2522 6.45716C20.2522 5.73276 19.665 5.14551 18.9406 5.14551C18.2162 5.14551 17.6289 5.73276 17.6289 6.45716C17.6289 7.18157 18.2162 7.76882 18.9406 7.76882Z"
      fill="white"
    />
    <path
      d="M22.7404 7.76882C23.4648 7.76882 24.052 7.18157 24.052 6.45716C24.052 5.73276 23.4648 5.14551 22.7404 5.14551C22.016 5.14551 21.4287 5.73276 21.4287 6.45716C21.4287 7.18157 22.016 7.76882 22.7404 7.76882Z"
      fill="white"
    />
    <path
      d="M26.5744 7.76882C27.2988 7.76882 27.886 7.18157 27.886 6.45716C27.886 5.73276 27.2988 5.14551 26.5744 5.14551C25.8499 5.14551 25.2627 5.73276 25.2627 6.45716C25.2627 7.18157 25.8499 7.76882 26.5744 7.76882Z"
      fill="white"
    />
  </svg>
);
