import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormComponentResolver from "./FormComponentResolver";
import constants from "../../constants";
import { fetcher, errorString } from "../../utils";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function Form({
  passFormValues,
  initialValues,
  id,
  style,
  components,
  componentsDirection,
  children,
  childrenPosition,
  typeOfCall,
  errorMessage,
  Component,
}) {
  const initialState = {
    values: initialValues || {},
    onSubmitConfig: {},
    error: "",
    loading: null,
  };
  const [values, setValues] = useState(initialState.values);
  const [error, setError] = useState(initialState.error);
  const [loading, setLoading] = useState(initialState.loading);
  const [returnData, setReturnData] = useState();
  const [onSubmitConfig, setOnSubmitConfig] = useState(
    initialState.onSubmitConfig
  );

  function reset() {
    setValues(initialState.values);
    setOnSubmitConfig(initialState.onSubmitConfig);
    setError(initialState.error);
    setLoading(initialState.loading);
  }

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    passFormValues && passFormValues(values);
  }, [passFormValues, values]);

  function handleChange(newValue) {
    setValues({ ...values, ...newValue });
    setError("");
  }
  /*
  setError(errorString(responseError))
  */
  function handleSubmit(e) {
    e && e.preventDefault();
    const { customHandler, transformValues, authority, postAPIKey, onSuccess } =
      onSubmitConfig || {};

    const hasValues = Object.keys(values).some((i) => values[i]);

    hasValues &&
      customHandler &&
      customHandler({ setLoading, setError, values });
    const path = postAPIKey && constants.api.path[postAPIKey];
    typeOfCall === "GET" &&
      //hasValues &&
      authority &&
      path &&
      (() => {
        setLoading(true);
        fetcher
          .get({
            authority,
            path,
            params: (transformValues && transformValues(values)) || values,
          })
          .then((responseData) => {
            console.log(responseData);
            responseData.length === 0
              ? setError(errorMessage)
              : setReturnData(responseData);
            onSuccess && onSuccess(responseData);
          })
          .catch((responseError) => setError(errorString(responseError)))
          .finally(setLoading);
      })();
      console.log(values)
      console.log(hasValues)
      console.log(authority)
      console.log(path)
      console.log((transformValues && transformValues(values)) || values)
    typeOfCall === "POST" &&
      //hasValues &&
      authority &&
      path &&
      (() => {
        setLoading(true);
        fetcher
          .post({
            authority,
            path,
            data: (transformValues && transformValues(values)) || values,
          })
          .then((responseData) => {
            console.log(responseData);
            onSuccess && onSuccess(responseData);
          })
          .catch((responseError) => setError(errorString(responseError)))
          .finally(setLoading);
      })();
  }

  const FormComponentResolverProps = {
    components,
    componentsDirection,
    disabled: loading,
    setOnSubmitConfig,
    values,
    handleChange,
    error,
  };

  return (
    <div>
      {error ? <h1> {error} </h1> : null}
      <StyledForm id={id} onSubmit={handleSubmit} style={style}>
        {children && childrenPosition === "top" ? children : null}
        <FormComponentResolver {...FormComponentResolverProps} />
        {children && childrenPosition === "bottom" ? children : null}
      </StyledForm>
      {Component && returnData !== undefined ? (
        <Component data={returnData} values={values} />
      ) : null}
    </div>
  );
}

export default Form;
