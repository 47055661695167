import styled from "styled-components";

export const ApplicationContainerPrimary = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const ApplicationContainerSecondary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 0;
`;
