import React from "react";
import GetComponent from "../GetComponent";
import Dashboard from "./Dashboard";

const GetDashboard = (props) => (
  <GetComponent
    authority="test"
    resource="printShop"
    params={{ page: 0, sort_column: 1, sort_direction: "asc", action: "get_dashboard_counts" }}
    Component={Dashboard}
    {...props}
  />
);

export default GetDashboard;
