import React from "react";
import GetComponent from "../GetComponent";
import PrintShopLookup from "./PrintShopLookup";

const GetPrintShopLookup = (props) => {
  return (
    <GetComponent
      authority="test"
      resource="printShop"
      params={{
        action: "get_cert_let_bill_list_for_facility",
        fac_id: props.match.params.fac_id,
      }}
      Component={PrintShopLookup}
      {...props}
    />
  );
};

export default GetPrintShopLookup;
