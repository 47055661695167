import styled from "styled-components";
//width: 100%;
export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownOptionsContainer = styled.ul`
  position: absolute;
  list-style: none;
  margin: 10px 0;
  width: 100%;
  padding: 10px 0;
  background: #ffffff;
  border: rgba(0, 0, 0, 0.2) solid 4px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-width: 130px;
  z-index: ${({ theme }) => theme.zIndex.dropdownOptions};
`;

export const DropdownOptionContainer = styled.li`
  cursor: pointer;
  transition: 0.2s;
  align-items: center;
  display: flex;
  font-size: 15px;
  line-height: 18px;
  color: #444444;
  button {
    width: 100%;
    padding: 5px 10px;
    justify-content: flex-start;
    text-align: left;
  }
  svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
  svg path {
    fill: #006af6;
  }
  &:hover {
    background: #f2f8ff;
  }
`;
