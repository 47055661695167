import React from "react";
import { StandardContainer, StandardLabel, HeaderLabel, ErrorLabel, StandardInput } from "./FormStyles";
import FormDropdownInput from "./FormDropdownInput";
import { FormInputDateInput } from "./FormInputDate";
import FormFileInput from "./FormFileInput";
import FormRadioButtonInput from "./FormRadioButtonInput";

const FormInputStandard = ({
  key,
  label,
  name,
  value,
  input,
  options,
  ...rest
}) => (
  <StandardContainer key={key}>
    {label && (input !== "headerLabel" && input !== "errorLabel") ? <StandardLabel>{label}</StandardLabel> : null}
    {input === "headerLabel" ? (
       <HeaderLabel>{label}</HeaderLabel>
    ) :
    input === "errorLabel" ? (
      <ErrorLabel>{label}</ErrorLabel>
   ) :
    input === "dropdown" ? (
      <FormDropdownInput value={value} options={options} {...rest} />
    ) : input === "date" ? (
      <FormInputDateInput value={value || new Date()} {...rest} />
    ) : input === "file" ? (
      <FormFileInput value={value} {...rest} />
    ) : input === "radio" ? (
      <FormRadioButtonInput
        value={value}
        label={label}
        options={options}
        {...rest}
      />
    ) : (
      <StandardInput value={value || ""} {...rest} />
    )}
  </StandardContainer>
);

export default FormInputStandard;
