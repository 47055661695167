import React, { useEffect, useState } from "react";
import { LabelOne } from "../Containers";
import { Form } from "../Form";
import { fetcher, formatDate } from "../../utils";
import FormDropdownNewNew from "../Form/FormDropdownNewNew";

function PageReportsForm() {
  const [reportType, setReportType] = useState("");
  const [certLetterType, setCertLetterType] = useState([]);
  const [copyType, setCopyType] = useState("");
  const [langType, setLangType] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [status, setStatus] = useState([]);
  const [reporter, setReporter] = useState([]);

  const [reportMonth, setReportMonth] = useState("");
  const [reportYear, setReportYear] = useState("");
  const [numberOfCertificates, setNumberOfCertificates] = useState("");
  const [englishInventoryAdded, setEnglishInventoryAdded] = useState("");
  const [spanishInventoryAdded, setSpanishInventoryAdded] = useState("");
  const [englishVoidedCertificates, setEnglishVoidedCertificates] =
    useState("");
  const [spanishVoidedCertificates, setSpanishVoidedCertificates] =
    useState("");

  const [receivedDate, setReceivedDate] = useState(new Date());
  const [dateRangeFrom, setDateRangeFrom] = useState(new Date());
  const [dateRangeTo, setDateRangeTo] = useState(new Date());

  const [createdDateRangeFrom, setCreatedDateRangeFrom] = useState(new Date());
  const [createdDateRangeTo, setCreatedDateRangeTo] = useState(new Date());

  const [closedDateRangeFrom, setClosedDateRangeFrom] = useState(new Date());
  const [closedDateRangeTo, setClosedDateRangeTo] = useState(new Date());

  const [dropdownInfo, setDropdownInfo] = useState();

  const updateToAndFromDatesFromMonthAndYear = () => {
    if (reportMonth === "" || reportYear === "") return;
    // Convert month name to month number (0-based index)
    const monthNumber = new Date(
      Date.parse(reportMonth + " 1, 1970") //Year is arbitrary here. Just need to parse the month
    ).getMonth();

    // Get the first day of the month
    const startDate = new Date(reportYear, monthNumber, 1);

    // Get the last day of the month
    // We set the day as 0 of the next month, which will give the last day of the previous month
    const endDate = new Date(reportYear, monthNumber + 1, 0);

    setDateRangeFrom(startDate);
    setDateRangeTo(endDate);
  };

  useEffect(() => {
    updateToAndFromDatesFromMonthAndYear();
  }, [reportMonth, reportYear]);

  const getSubcat = () => {
    if (category.length > 0) {
      return category === "Certification"
        ? dropdownInfo?.subcat_list
            .filter((data) => data.cat === "Certification")
            .map((item) => item.subcat) ?? []
        : category === "Billing"
        ? dropdownInfo?.subcat_list
            .filter((data) => data.cat === "Billing")
            .map((item) => item.subcat) ?? []
        : dropdownInfo?.subcat_list.map((item) => item.subcat) ?? [];
    } else return [];
  };
  useEffect(() => {
    if (reportType === "Certificate and Letters Custom") {
      fetcher
        .get({
          authority: "test",
          path: "/report",
          params: {
            action: "get_custom_report_options",
          },
        })
        .then((responseData) => {
          console.log(responseData);
          setDropdownInfo((dropdown) => ({ ...dropdown, ...responseData }));
        })
        .catch((responseError) => {
          console.log(responseError);
        });
    } else if (reportType === "Inquiries and Logs Custom") {
      fetcher
        .get({
          authority: "test",
          path: "/report",
          params: {
            action: "get_inquiries_logs_report_options",
          },
        })
        .then((responseData) => {
          console.log(responseData);
          setDropdownInfo(responseData);
        })
        .catch((responseError) => {
          console.log(responseError);
        });
    } else if (reportType === "Monthly Output Report") {
      fetcher
        .get({
          authority: "test",
          path: "/report",
          params: {
            action: "get_monthly_output_report_options",
          },
        })
        .then((responseData) => {
          console.log(responseData);
          setDropdownInfo(responseData);
        })
        .catch((responseError) => {
          console.log(responseError);
        });
    }
  }, [reportType]);

  const formConfigDailyMPRISFacilities = {
    id: "cert-lookup",
    typeOfCall: "GET",
    components: [
      [
        {
          label: "Received Date",
          component: "FormInputDateSingle",
          name: "receivedDate",
          value: receivedDate,
          style: { width: 500 },
          onChange: (e) => setReceivedDate(e),
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "Generate Report to Download",
            name: "submit_print_shop_lookup",
            title: "Generate Report to Download",
            onSubmit: {
              authority: "test",
              postAPIKey: "report",
              transformValues: (values) => ({
                action: "get_daily_mpris_facilities_report",
                report_date: formatDate(receivedDate),
              }),
              onSuccess: (data) => {
                window.open(data.report_csv_file_url, "_blank");
                console.log(data);
              },
            },
          },
        },
      ],
    ],
  };

  const formConfigNewFacilities = {
    id: "cert-lookup",
    typeOfCall: "GET",
    components: [
      [
        {
          label: "Date Range",
          component: "FormInputDateSingle",
          name: "dateRangeFrom",
          value: dateRangeFrom,
          style: { width: 500 },
          onChange: (e) => setDateRangeFrom(e),
        },
        {
          label: "to",
          component: "FormInputDateSingle",
          name: "dateRangeTo",
          value: dateRangeTo,
          style: { width: 500 },
          onChange: (e) => setDateRangeTo(e),
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "Generate Report to Download",
            name: "submit_print_shop_lookup",
            title: "Generate Report to Download",
            onSubmit: {
              authority: "test",
              postAPIKey: "report",
              transformValues: (values) => ({
                action: "get_new_facilities_report",
                from_date: formatDate(dateRangeFrom),
                to_date: formatDate(dateRangeTo),
              }),
              onSuccess: (data) => {
                window.open(data.report_csv_file_url, "_blank");
                console.log(data);
              },
            },
          },
        },
      ],
    ],
  };
  const formConfigMQSAPrintShop = {
    id: "cert-lookup",
    typeOfCall: "GET",
    components: [
      [
        {
          label: "Date Range",
          component: "FormInputDateSingle",
          name: "dateRangeFrom",
          value: dateRangeFrom,
          style: { width: 500 },
          onChange: (e) => setDateRangeFrom(e),
        },
        {
          label: "to",
          component: "FormInputDateSingle",
          name: "dateRangeTo",
          value: dateRangeTo,
          style: { width: 500 },
          onChange: (e) => setDateRangeTo(e),
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "Generate Report to Download",
            name: "submit_print_shop_lookup",
            title: "Generate Report to Download",
            onSubmit: {
              authority: "test",
              postAPIKey: "report",
              transformValues: (values) => ({
                action: "get_mqsa_printshop_report",
                from_date: formatDate(dateRangeFrom),
                to_date: formatDate(dateRangeTo),
              }),
              onSuccess: (data) => {
                window.open(data.report_csv_file_url, "_blank");
                console.log(data);
              },
            },
          },
        },
      ],
    ],
  };
  const formConfigInquiriesAndLogsCustom = {
    id: "cert-lookup",
    typeOfCall: "POST",
    components: [
      [
        {
          component: "FormDropdownNewNew",
          label: "Category",
          style: { width: 500, marginBottom: 10 },
          name: "category",
          value: category,
          onChange: (e) => setCategory(e.value),
          required: true,
          options: dropdownInfo?.cat_list ?? [],
        },
        category !== "All" && {
          component: "FormDropdownNewNew",
          label: "Sub-Category",
          style: { width: 500, marginBottom: 10 },
          name: "subCategory",
          value: subCategory,
          isMulti: true,
          onChange: (e) => {
            const eWithName = e.map((elem) => elem.value);
            setSubCategory(eWithName);
          },
          required: true,
          options: getSubcat() ?? [],
        },
        {
          component: "FormDropdownNewNew",
          label: "Status",
          style: { width: 500, marginBottom: 10 },
          name: "status",
          value: status,
          isMulti: true,
          onChange: (e) => {
            const eWithName = e.map((elem) => elem.value);
            setStatus(eWithName);
          },
          required: true,
          options: dropdownInfo?.status_list ?? [],
        },
        {
          component: "FormDropdownNewNew",
          label: "Reporter",
          style: { width: 500, marginBottom: 10 },
          name: "reporter",
          value: reporter,
          isMulti: true,
          onChange: (e) => {
            const eWithName = e.map((elem) => elem.value);
            setReporter(eWithName);
          },
          required: true,
          options: dropdownInfo?.reported_by_list ?? [],
        },
        {
          label: "Created Date Range",
          component: "FormInputDateSingle",
          name: "createdDateRangeFrom",
          value: createdDateRangeFrom,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setCreatedDateRangeFrom(e),
        },
        {
          label: "to",
          component: "FormInputDateSingle",
          name: "createdDateRangeTo",
          value: createdDateRangeTo,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setCreatedDateRangeTo(e),
        },
        {
          label: "Closed Date Range",
          component: "FormInputDateSingle",
          name: "closedDateRangeFrom",
          value: closedDateRangeFrom,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setClosedDateRangeFrom(e),
        },
        {
          label: "to",
          component: "FormInputDateSingle",
          name: "closedDateRangeTo",
          value: closedDateRangeTo,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setClosedDateRangeTo(e),
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "Generate Report to Download",
            name: "submit_print_shop_lookup",
            title: "Generate Report to Download",
            onSubmit: {
              authority: "test",
              postAPIKey: "report",
              transformValues: (values) => ({
                operation: "print_inquiries_logs_report",
                cat_list: [category],
                subcat_list: subCategory,
                status_list: status,
                reported_by_list: reporter,
                create_from_date: formatDate(createdDateRangeFrom),
                create_to_date: formatDate(createdDateRangeTo),
                closed_from_date: formatDate(closedDateRangeFrom),
                closed_to_date: formatDate(closedDateRangeTo),
              }),
              onSuccess: (data) => {
                window.open(data.report_csv_file_url, "_blank");
                console.log(data);
              },
            },
          },
        },
      ],
    ],
  };
  const formConfigMonthlyOutput = {
    id: "cert-lookup",
    typeOfCall: "POST",
    components: [
      [
        {
          component: "FormDropdownNewNew",
          label: "Letter Type",
          style: { width: 500, marginBottom: 10 },
          name: "let_type",
          value: certLetterType,
          isMulti: true,
          isRequired: true,
          onChange: (e) => {
            const eWithName = e.map((elem) => elem.value);
            setCertLetterType(eWithName);
          },
          required: true,
          options: dropdownInfo?.let_type ?? [],
        },
        /* {
          component: "FormDropdownNewNew",
          label: "Copy Type",
          style: { width: 500, marginBottom: 10},
          name: "copy_type",
          value: copyType,
          onChange: (e) => setCopyType(e.value),
          options: dropdownInfo?.copy_type ?? [],
        },
        copyType !== "" && {
          component: "FormDropdownNewNew",
          label: "Language Type",
          style: { width: 500, marginBottom: 10},
          isRequired: true,
          name: "language_type",
          value: langType,
          onChange: (e) => setLangType(e.value),
          required: true,
          options: dropdownInfo?.language_type ?? [],
        },*/
        {
          label: "Date range from",
          component: "FormInputDateSingle",
          name: "fromDate",
          value: dateRangeFrom,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setDateRangeFrom(e),
        },
        {
          label: "to ",
          component: "FormInputDateSingle",
          name: "toDate",
          value: dateRangeTo,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setDateRangeTo(e),
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "Generate Report to Download",
            name: "submit_print_shop_lookup",
            title: "Generate Report to Download",
            onSubmit: {
              authority: "test",
              postAPIKey: "report",
              transformValues: (values) => ({
                operation: "print_monthly_output_report",
                let_type_list: certLetterType,
                mail_from_date: formatDate(fromDate),
                mail_to_date: formatDate(toDate),
              }),
              onSuccess: (data) => {
                window.open(data.report_csv_file_url, "_blank");
                console.log(data);
              },
            },
          },
        },
      ],
    ],
  };

  // Monthly MQSA Report
  const formConfigMQSAWorkloadReport = {
    id: "cert-lookup",
    typeOfCall: "GET",
    components: [
      [
        {
          label: "Month",
          component: "FormDropdownNewNew",
          name: "reportMonth",
          value: reportMonth,
          key: "reportMonth",
          required: true,
          style: { width: 500 },
          options: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          onChange: (e) => setReportMonth(e.value),
        },
        {
          label: "Year",
          component: "FormDropdownNewNew",
          name: "reportYear",
          value: reportYear,
          key: "reportYear",
          required: true,
          style: { width: 500, marginBottom: 10 },
          options: Array.from(
            { length: new Date().getFullYear() - 2020 + 1 },
            (_, i) => (2020 + i).toString()
          ),
          onChange: (e) => setReportYear(e.value),
        },
        {
          label: "# of Test Certificates",
          component: "FormInputStandard",
          name: "numberOfCertificates",
          key: "numberOfCertificates",
          value: numberOfCertificates,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => {
            setNumberOfCertificates(e.target.value);
          },
        },
        {
          label: "English Inventory Added",
          component: "FormInputStandard",
          name: "englishInventoryAdded",
          key: "englishInventoryAdded",
          value: englishInventoryAdded,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => {
            setEnglishInventoryAdded(e.target.value);
          },
        },
        {
          label: "Spanish Inventory Added",
          component: "FormInputStandard",
          name: "spanishInventoryAdded",
          key: "spanishInventoryAdded",
          value: spanishInventoryAdded,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => {
            setSpanishInventoryAdded(e.target.value);
          },
        },
        {
          label: "English Voided Certificates",
          component: "FormInputStandard",
          name: "englishVoidedCertificates",
          key: "englishVoidedCertificates",
          value: englishVoidedCertificates,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => {
            setEnglishVoidedCertificates(e.target.value);
          },
        },
        {
          label: "Spanish Voided Certificates",
          component: "FormInputStandard",
          name: "spanishVoidedCertificates",
          key: "spanishVoidedCertificates",
          value: spanishVoidedCertificates,
          style: { width: 500 },
          onChange: (e) => {
            setSpanishVoidedCertificates(e.target.value);
          },
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "Generate Report",
            name: "submit_print_shop_lookup",
            title: "Generate Report",
            onSubmit: {
              authority: "lambda",
              postAPIKey: "report",
              transformValues: (values) => ({
                action: "get_mqsa_workload_report",
                from_date: formatDate(dateRangeFrom),
                to_date: formatDate(dateRangeTo),
                number_of_test_certificates:
                  parseInt(numberOfCertificates) || 0,
                english_inventory_added: parseInt(englishInventoryAdded) || 0,
                spanish_inventory_added: parseInt(spanishInventoryAdded) || 0,
                english_voided_certificates:
                  parseInt(englishVoidedCertificates) || 0,
                spanish_voided_certificates:
                  parseInt(spanishVoidedCertificates) || 0,
              }),
              onSuccess: (data) => {
                window.open(data.report_csv_file_url, "_blank");
                console.log(data);
              },
            },
          },
        },
      ],
    ],
  };
  const formConfig = {
    id: "cert-lookup",
    typeOfCall: "POST",
    components: [
      [
        (reportType === "Certificate and Letters Custom" ||
          reportType === "Monthly Output Report") && {
          component: "FormDropdownNewNew",
          label: "Cert/Letter Type",
          style: { width: 500, marginBottom: 10 },
          name: "cert_letter_type",
          value: certLetterType,
          isMulti: true,
          isRequired: true,
          onChange: (e) => {
            const eWithName = e.map((elem) => elem.value);
            setCertLetterType(eWithName);
          },
          required: true,
          options: dropdownInfo?.cert_let_type ?? [],
        },
        reportType === "Monthly Output Report" && {
          component: "FormDropdownNewNew",
          label: "Copy Type",
          style: { width: 500, marginBottom: 10 },
          name: "copy_type",
          value: copyType,
          onChange: (e) => setCopyType(e.value),
          options: dropdownInfo?.copy_type ?? [],
        },
        reportType === "Monthly Output Report" &&
          copyType !== "" && {
            component: "FormDropdownNewNew",
            label: "Language Type",
            style: { width: 500, marginBottom: 10 },
            isRequired: true,
            name: "language_type",
            value: langType,
            onChange: (e) => setLangType(e.value),
            required: true,
            options: dropdownInfo?.language_type ?? [],
          },
        reportType === "Certificate and Letters Custom" && {
          label: "Mailed Date Range",
          component: "FormInputDateSingle",
          name: "fromDate",
          value: fromDate,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setFromDate(e),
        },
        (reportType === "Certificate and Letters Custom" ||
          reportType === "MQSA Print Shop Report") && {
          label: "to ",
          component: "FormInputDateSingle",
          name: "toDate",
          value: toDate,
          style: { width: 500, marginBottom: 10 },
          onChange: (e) => setToDate(e),
        },
        reportType === "Monthly Output Report" && {
          label: "to",
          component: "FormInputDateSingle",
          name: "dateRangeTo",
          value: dateRangeTo,
          style: { width: 500 },
          onChange: (e) => setDateRangeTo(e),
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "Generate Report to Download",
            name: "submit_print_shop_lookup",
            title: "Generate Report to Download",
            onSubmit: {
              authority: "test",
              postAPIKey: "report",
              transformValues: (values) => ({
                operation: "print_custom_report",
                cert_let_types: certLetterType,
                from_date: formatDate(fromDate),
                to_date: formatDate(toDate),
              }),
              onSuccess: (data) => {
                window.open(data.report_csv_file_url, "_blank");
                console.log(data);
              },
            },
          },
        },
      ],
    ],
  };

  const determineFormConfig = () => {
    switch (reportType) {
      case "Daily MPRIS Facilities Report":
        return formConfigDailyMPRISFacilities;
      case "New Facilities Report":
        return formConfigNewFacilities;
      case "MQSA Print Shop Report":
        return formConfigMQSAPrintShop;
      case "Inquiries and Logs Custom":
        return formConfigInquiriesAndLogsCustom;
      case "Monthly Output Report":
        return formConfigMonthlyOutput;
      case "MQSA Monthly Workload Report":
        return formConfigMQSAWorkloadReport;
      default:
        return formConfig;
    }
  };
  return (
    <>
      <LabelOne>Please select a type of report to generate</LabelOne>
      <br />
      <FormDropdownNewNew
        label="Type of Report To Print"
        style={{ width: 500 }}
        name="report_type"
        value={reportType}
        onChange={(e) => setReportType(e.value)}
        required={true}
        options={[
          "Certificate and Letters Custom",
          "Inquiries and Logs Custom",
          "Daily MPRIS Facilities Report",
          "New Facilities Report",
          "Monthly Output Report",
          "MQSA Print Shop Report",
          "MQSA Monthly Workload Report",
        ]}
      />
      <Form {...determineFormConfig()} style={{ marginTop: 10 }} />
    </>
  );
}

export default PageReportsForm;
