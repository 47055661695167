import styled from "styled-components";

export const DashboardCards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DashboardCard = styled.div`
  border-top: solid 6px #f6d86c;
  border-radius: 3px;
  color: #797979;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  width: 370px;
  height: 230px;
  margin: 0 40px 40px 0;
  padding: 24px 20px;
  box-sizing: border-box;
  background: #fefeff;
  box-shadow: 1.5px 1.5px 5px rgba(73, 73, 73, 0.384451);
`;
