import AWS from "aws-sdk";
import constants from "../../constants";

function sendS3Request({
  Authorization,
  resolve,
  reject,
  APIVersion = 0,
  BucketName = 0,
  Key,
  Method = "getObject",
}) {
  !Key && reject("Missing 'Key' for S3Request.");

  const {
    Region,
    IdentityPoolId,
    CognitoIdentityCredentialsKey,
    S3APIVersion,
    S3BucketName,
  } = constants.aws;

  AWS.config.region = Region;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId,
    Logins: { [CognitoIdentityCredentialsKey]: Authorization },
  });

  function request() {
    const s3 = new AWS.S3({ apiVersion: S3APIVersion[APIVersion] });
    const params = { Bucket: S3BucketName[BucketName], Key };
    s3[Method](params, (err, data) => (err ? reject(err) : resolve(data)));
  }

  AWS.config.credentials.refresh((err) => (err ? reject(err) : request()));
}

export default sendS3Request;
