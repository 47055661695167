import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { PageTitle } from "../Containers";
import { Table } from "../Table";
import PrintShopProgressBar from "../PagePrintShopList/PrintShopProgressBar";
import PrintShopTableColumns from "../PagePrintShopList/PrintShopTableColumns";
import PrintShopProgressBarIsInterim from "../PagePrintShopList/PrintShopProgressBarIsInterim";
import { fetcher, formatDate } from "../../utils";
import { Form } from "../Form";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function PrintShopInterimLetters({ ui, ...props }) {
  delete props.params["main_search"];
  const [selected, setSelected] = useState([]);
  const [progressBarState, setProgressBarState] = useState({ currentIndex: 0 });
  const[contactPerson, setContactPerson] = useState("");
  const[facName, setFacName] = useState("");
  const[addLine1, setAddLine1] = useState("");
  const[addLine2, setAddLine2] = useState("");
  const[addLine3, setAddLine3] = useState("");
  const[city, setCity] = useState("");
  const[state, setState] = useState("");
  const[zip, setZip] = useState("");
  const[phone, setPhone] = useState("");
  const[fax, setFax] = useState("");
  const[effectiveDate, setEffectiveDate] = useState(new Date());
  const[expirationDate, setExpirationDate] = useState(new Date());

  const uiConfigs = [
    {
      condition: ui.isInterim,
      title: "Interim Letters",
      progressBar: PrintShopProgressBarIsInterim,
      accessor: "let_id",
      accessorType: "let_type_code",
    },
  ];

  const uiConfig = uiConfigs.find((i) => i.condition) || {};

  const { list } = props.match.params;

  const { Component: ProgressBarComponent } =
    (uiConfig.progressBar &&
      uiConfig.progressBar[progressBarState.currentIndex]) ||
    {};

  const progressBarStateProps = {
    state: progressBarState,
    updateState: (update) =>
      setProgressBarState({ ...progressBarState, ...update }),
    clickHandler: (operation, item_type) => {
      let selected_with_codes;
      console.log(selected)

      if (item_type === "let_package") {
        selected_with_codes = selected.map((select) => ({
          let_id: select.id,
          let_type_code: select.code,
        }));
      }
     
      setProgressBarState({ ...progressBarState, status: "pending" });
      fetcher
        .post({
          authority: "test",
          path: "/printshop",
          data: progressBarState.currentIndex === 0 ? 
          {
            operation: "save_interim_let_info",
            let_id: props.data.current_page[0].let_id,
            let_fac_id: Number(props.params.fac_id),
            let_fac_name: facName,
            let_add1: addLine1,
            let_add2: addLine2,
            let_add3: addLine3,
            let_city: city,						
            let_state: state,							
            let_zip: zip,						
            let_poc: contactPerson,					
            let_phone: phone,						
            let_fax:fax,						
            let_eff_date: formatDate(effectiveDate),
            let_exp_date: formatDate(expirationDate)
          }
          : {
            operation: operation,
            item_type: item_type,
            item_list_type:
              selected.length === 1 && selected[0] === "all"
                ? "all"
                : "selected",
            select_item_list:
              selected.length === 1 && selected[0] === "all"
                ? []
                : selected_with_codes,
            mail_date: formatDate(new Date()),
          },
        })
        .then((responseData) => {
          console.log(responseData);
          setTimeout(() => {
            setProgressBarState({ ...progressBarState, status: "success" });
            setTimeout(() => {
              setProgressBarState({
                ...progressBarState,
                currentIndex: uiConfig.progressBar[
                  progressBarState.currentIndex + 1
                ]
                  ? progressBarState.currentIndex + 1
                  : progressBarState.currentIndex,
              });
            }, 500);
          }, 500);
          if (operation === "confirm_success") {
            return (window.location = "/printshop");
          }
        })
        .catch((responseError) => {
          console.log(responseError);
        });
    },
  };

  const formConfig = {
    id: "add-interim-info",
    typeOfCall: "POST",
    components: [
      {
        component: "FormInputStandard",
        name: "fac_id",
        label: "Facility ID",
        required: true,
        disabled: true
      },
      {
        component: "FormInputStandard",
        name: "fac_name",
        label: "Facility Name",
        value: facName,
        required: true,
        onChange: (e) => setFacName(e.target.value)

      },
      {
        component: "FormInputStandard",
        name: "address_line_1",
        label: "Address Line 1",
        required: true,
        value: addLine1,
        onChange: (e) => setAddLine1(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "address_line_2",
        label: "Address Line 2",
        required: true,
        value: addLine2,
        onChange: (e) => setAddLine2(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "address_line_3",
        label: "Address Line 3",
        required: true,
        value: addLine3,
        onChange: (e) => setAddLine3(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "city",
        label: "City",
        type: "text",
        required: true,
        value: city,
        onChange: (e) => setCity(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "state",
        label: "State",
        type: "text",
        required: true,
        value: state,
        onChange: (e) => setState(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "zip",
        label: "Zip Code",
        type: "text",
        required: true,
        value: zip,
        onChange: (e) => setZip(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "fax",
        label: "Fax Number",
        type: "text",
        value: fax,
        onChange: (e) => setFax(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "phone_number",
        label: "Phone Number",
        type: "text",
        value: phone,
        onChange: (e) => setPhone(e.target.value)
      },
      {
        component: "FormInputStandard",
        name: "poc",
        label: "Contact Person",
        type: "text",
        required: true,
        value: contactPerson,
        onChange: (e) => setContactPerson(e.target.value)
      },
      {
        component: "FormDropdownStandard",
        label: "Print Type",
        name: "poc_title",
        options: ["Official Print", "Unofficial Print"],
      },
      {
        label: "Effective Date",
        component: "FormInputDateSingle",
        name: "effectiveDate",
        value: effectiveDate,
        onChange: (e) => setEffectiveDate(e)
      },
      {
        label: "Expiration Date",
        component: "FormInputDateSingle",
        name: "expiration_date",
        value: expirationDate,
        onChange: (e) => setExpirationDate(e)
      },
    ],
  };
  
  return (
    <Fragment>
      {uiConfig.progressBar ? (
        <PrintShopProgressBar
          progressBar={uiConfig.progressBar}
          {...progressBarStateProps}
        />
      ) : null}
      <Container>
        <PageTitle>
          {uiConfig.title}
          {uiConfig.title && list ? " / " + list : list ? list : ""}
        </PageTitle>
        {ProgressBarComponent ? (
          <ProgressBarComponent
            selected={selected}
            hasSelected={Boolean(selected.length) || contactPerson !== ""}
            {...progressBarStateProps}
          />
        ) : null}
      </Container>
      {progressBarState.currentIndex === 0 ? (
          <Form {...{ ...formConfig, initialValues: { fac_id: props.params.fac_id } }} />
  
      ) :
      <Table
      includeSearch
      includeCount
      selectColumnProps={{
        accessor: uiConfig.accessor,
        selected,
        setSelected,
        transformKeys: {
          id: uiConfig.accessor,
          code: uiConfig.accessorType,
        },
      }}
      columns={PrintShopTableColumns({ isLetter: true })}
      {...props}
    />
    }
      
    </Fragment>
  );
}

export default PrintShopInterimLetters;
