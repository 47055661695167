import React from "react";
import { PageSubtitle } from "../Containers";
import styled from "styled-components";
import PrintShopLookupTableColumns from "./PrintShopLookupTableColumns";
import { Table } from "../Table";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

function PrintShopLookupSelect({ setDoc, data }) {
  const CertsColumns = PrintShopLookupTableColumns({
    isLookup: true,
    setDoc,
  });

  return (
    <StyledDiv>
      <PageSubtitle>
        Please select which Certificate, Letter or Late Notice you would like to
        print:
      </PageSubtitle>
      <br />
      <h3>Certificates & Letters</h3>
      <Table
        data={{ current_page: data.cert_let_list }}
        columns={CertsColumns}
      />
    </StyledDiv>
  );
}

export default PrintShopLookupSelect;
