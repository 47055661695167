const FacilityMainInformationPOCFormConfig = ({ closeForm, operation, poc_id, fac_id }) => ({
  id: "edit-contact-info",
  typeOfCall: "POST",
  componentsDirection: "row",
  components: [
    [
      {
      component: "FormDropdownStandard",
        label: "Title",
        name: "poc_title",
        options: ["Mr.", "Mrs.", "Ms.", "Dr."],
        style: {
          marginLeft: 45
        }
      },
    ],
    [
      {
        component: "FormInputStandard",
        label: "Full Name",
        name: "poc_full_name",
        type: "text",
        required: true,
      },
    ],
    [
      {
        component: "FormInputStandard",
        label: "Email Address",
        name: "poc_email",
        type: "text",
      },
      {
        component: "FormInputStandard",
        label: "Primary Number",
        name: "poc_primary_phone",
        type: "text",
      },
      {
        component: "FormInputStandard",
        label: "Ext.",
        name: "poc_primary_phone_extn",
        type: "text",
      },
    ],
    [
      {
        component: "FormInputStandard",
        label: "Secondary Number",
        name: "poc_secondary_phone",
        type: "text",
      },
      {
        component: "FormInputStandard",
        label: "Fax Number",
        name: "poc_fax",
        type: "text",
      },
    ],
    [
      {
        component: "FormInputStandard",
        label: "Address",
        name: "poc_addline1",
        type: "text",
      },
    ],
    [
      {
        component: "FormInputStandard",
        name: "poc_addline2",
        type: "text",
      },
    ],
    [
      {
        component: "FormInputStandard",
        name: "poc_addline3",
        type: "text",
      },
    ],
    [
      {
        component: "FormInputStandard",
        name: "poc_city",
        label: "City",
        type: "text",
      },
      {
        component: "FormInputStandard",
        name: "poc_state",
        label: "State",
        type: "text",
      },
      {
        component: "FormInputStandard",
        name: "poc_zip",
        label: "Zip Code",
        type: "text",
      },
    ],
    [
      {
        component: "FormSubmitButtonBlueRed",
        submitButton: {
          label: "SAVE",
          name: "save",
          onSubmit: {
            authority: "test",
            postAPIKey: "facilities",
            transformValues: (values) => {
              
             const returnObj = {
              operation,
              poc_record: {
                fac_id,
                poc_full_name: values.poc_full_name,
                poc_title: values.poc_title || "",
                primary_poc: true,
                poc_email: values.poc_email || "",
                poc_type: "Other",
                poc_primary_phone: values.poc_primary_phone || "",
                poc_primary_phone_extn: values.poc_primary_phone_extn || "",
                poc_secondary_phone: values.poc_secondary_phone || "",
                poc_secondary_phone_extn: "",
                poc_fax: values.poc_fax || "",
                poc_addline1: values.poc_addline1 || "",
                poc_addline2: values.poc_addline2 || "",
                poc_addline3: values.poc_addline3 || "",
                poc_city: values.poc_city || "",
                poc_state: values.poc_state || "",
                poc_zip: values.poc_zip || "",
              }}
              if(operation === "Update") {
                returnObj.poc_record.poc_id = poc_id;
              }
            return returnObj;
            },
            onSuccess: () => {
              console.log("Invoice updated successful!");
              window.location.reload();
            },
          },
        },
        redButton: {
          label: "CANCEL",
          name: "cancel_edit_contact_info",
          onClick: closeForm,
        },
      },
    ],
    [
      {
        component: "FormMessageError",
      },
    ],
  ],
});

export default FacilityMainInformationPOCFormConfig;
