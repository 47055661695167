export default {
  authority: {
    dev: "https://c2a4jpwibk.execute-api.us-east-1.amazonaws.com/dev",
    test: "https://em9beaaaje.execute-api.us-east-1.amazonaws.com/test",
    s3: "http://mqsa-doc-conversion-nodejs.s3-website-us-east-1.amazonaws.com",
    lambda:
      "https://2zzsjqlm3uopmvazd2h6uzhkji0ftokq.lambda-url.us-east-1.on.aws/",
  },
  path: {
    certificate: "/certificate",
    dashboardCounts: "/dashboard-counts",
    facilities: "/facilities",
    pdf: "/pdf",
    pointOfContact: "/point-of-contact",
    printShop: "/printshop",
    payment: "/payment",
    fileUpload: "/file-upload",
    report: "/report",
  },
};
