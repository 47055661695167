import React from "react";
import styled from "styled-components";
import HeaderHomeButton from "./HeaderHomeButton";
import HeaderUserButton from "./HeaderUserButton";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: #fefeff;
  box-shadow: 0.8px 1.5px 5px rgba(62, 69, 81, 0.440013);
  z-index: ${({ theme }) => theme.zIndex.header};
  padding: 0 25px;
`;

const Header = () => (
  <HeaderContainer>
    <HeaderHomeButton />
    <HeaderUserButton />
  </HeaderContainer>
);

export default Header;
