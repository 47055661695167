export default [
  //
  // CERTIFICATES AND LETTERS
  //
  {
    type: "certificate",
    code: "",
    getParam: "ALL",
    countKey: "cert_ALL_count",
    cardLabel: "All Certificates and Letters",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "FC",
    getParam: "",
    countKey: "cert_FC_count",
    cardLabel: "Fully-Certified (FC)",
    tableLabel: "Fully Certified",
  },
  {
    type: "certificate",
    code: "PC",
    getParam: "",
    countKey: "cert_PC_count",
    cardLabel: "Provisional (PC)",
    tableLabel: "Provisional",
  },
  {
    type: "certificate",
    code: "RI",
    getParam: "",
    countKey: "cert_RI_count",
    cardLabel: "Reinstated (RI)",
    tableLabel: "Provisional Reinstate",
  },
  {
    type: "certificate",
    code: "EX",
    getParam: "",
    countKey: "cert_EX_count",
    cardLabel: "Extended (EX)",
    tableLabel: "Extended",
  },
  {
    type: "certificate",
    code: "FN",
    getParam: "",
    countKey: "cert_FN_count",
    cardLabel: "Fully-Certified Name Change (FN)",
    tableLabel: "Fully Certified",
  },
  {
    type: "certificate",
    code: "PN",
    getParam: "",
    countKey: "cert_PN_count",
    cardLabel: "Provisional Name Change (PN)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "EN",
    getParam: "",
    countKey: "cert_EN_count",
    cardLabel: "Extended Name Change (EN)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "RN",
    getParam: "",
    countKey: "cert_RN_count",
    cardLabel: "Reinstated Name Change (RN)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "NC",
    getParam: "",
    countKey: "cert_NC_count",
    cardLabel: "No Longer Certified (NC)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "DI",
    getParam: "",
    countKey: "cert_DI_count",
    cardLabel: "Denied Accreditation (DI)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "DJ",
    getParam: "",
    countKey: "cert_DJ_count",
    cardLabel: "Denied Re-Accreditation (DJ)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "Interim H",
    getParam: "",
    countKey: "",
    cardLabel: "Provisional Reinstatement (Interim H)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "Interim B",
    getParam: "",
    countKey: "",
    cardLabel: "45-day Awaiting Receipt (Interim B)",
    tableLabel: "",
  },
  {
    type: "certificate",
    code: "Interim I",
    getParam: "",
    countKey: "",
    cardLabel: "45-day Undergoing Renewal (Interim I)",
    tableLabel: "",
  },
  {
    type: "expirationLetter", // expirationLetter
    code: "Exp", //Expiration Letter
    getParam: "", //"exp"
    countKey: "letter_exp_count",
    cardLabel: "2-week Expiration (Expiration Letter)",
    tableLabel: "",
  },
  //
  // LETTERS
  //
  {
    type: "letter",
    code: "Interim H",
    getParam: "Int_H",
    countKey: "",
    cardLabel: "Provisional Reinstatement (Interim H)",
    tableLabel: "",
  },
  {
    type: "letter",
    code: "Interim B",
    getParam: "Int_B",
    countKey: "",
    cardLabel: "45-day Awaiting Receipt (Interim B)",
    tableLabel: "",
  },
  {
    type: "letter",
    code: "Interim I",
    getParam: "Int_I",
    countKey: "",
    cardLabel: "45-day Undergoing Renewal (Interim I)",
    tableLabel: "",
  },
];
