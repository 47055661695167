import { useState } from "react";
import { formatDate } from "../../utils";

const convert_cert_letter_names = (name) => {
  let new_name;
  switch (name) {
    case "Copy of Original English Certificate":
      new_name = "en_cert_copy";
      break;
    case "Spanish Translation":
      new_name = "sp_cert_copy";
      break;
    case "Original Letter":
      new_name = "orig_let_copy";
      break;
    case "New Letter":
      new_name = "let_new";
      break;
    case "Fax Sheet":
      new_name = "fax_cvr_copy";
      break;
    case "Labels":
      new_name = "label";
      break;
    default:
      new_name = name;
  }
  return new_name;
};

const PrintShopLookupPrintForm = ({ doc, setOpenConfirmationModal, setOpenSendingModal }) => {
  const [currentValue, setCurrentValue] = useState();

  const copyTypeOptions = ["PC", "DI", "DJ", "NC", "Interim H", "Interim B", "Interim I", "Expiring", "Replacement"];
  const englishCopyTypeOptions = ["", "Unofficial copy", "Replacement copy", "Additional copy"];
  const documentTypeOptions = ["Copy of Original English Certificate", "Spanish Translation", "Original Letter", "New Letter", "Fax Sheet", "Labels"];
  
  return {
    typeOfCall: "POST",
    id: "print-shop-lookup",
    components: [
      {
        component: "FormDropdownStandard",
        label: "Document Type",
        name: "document_type",
        value: currentValue,
        required: true,
        options: documentTypeOptions,
        onChange: (e) => setCurrentValue(e),
      },
      currentValue === "New Letter" && {
        label: "New Letter Date (please select date even if the default if preferred)",
        component: "FormInputDateSingle",
        name: "new_letter_date",
      },
      currentValue === "Copy of Original English Certificate" && {
        label: "English Copy Type",
        component: "FormDropdownStandard",
        options: englishCopyTypeOptions,
        name: "copy_type",
      },
      currentValue === "Spanish Translation" && {
        label: "Spanish Copy Type",
        component: "FormDropdownStandard",
        options: englishCopyTypeOptions,
        name: "copy_type",
      },
      currentValue === "Fax Sheet" && {
        label: "Fax Sheet Copy Type",
        component: "FormDropdownStandard",
        options: copyTypeOptions,
        name: "copy_type",
      },
      currentValue === "Labels" && {
        label: "Labels Copy Type",
        component: "FormDropdownStandard",
        options: ["5161", "5162", "5163"],
        name: "copy_type",
      },
      {
        component: "FormSubmitButtonBlueRed",
        submitButton: {
          label: "SUBMIT",
          name: "submit",
          onSubmit: {
            authority: "test",
            postAPIKey: "printShop",
            transformValues: (values) => {
               setOpenSendingModal(true);
              const objectToSend = {
                operation: "print_option",
                print_option_type: convert_cert_letter_names(currentValue),
                item_id: doc.item_id,
                item_type: doc.item_type,
                copy_type: values.copy_type,
                mail_date: values.new_letter_date || formatDate(doc.mail_date),
              };
              return objectToSend;
            },
            onSuccess: () => {
              setOpenSendingModal(false);
              setOpenConfirmationModal(true);
              setTimeout(() => {
                return (window.location = "/printshop");
              }, 2000);
            },
            onError: (data) => console.log(data),
          },
        },
      },
    ],
  };
};

export default PrintShopLookupPrintForm;
