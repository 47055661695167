import React, { Fragment } from "react";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import { CalendarSVG, LeftRightButtons } from "../Containers";
import { StandardContainer, StandardLabel } from "./FormStyles";
import { StandardInputDiv } from "./FormStyles";
import "react-datepicker/dist/react-datepicker.css";
import "./FormInputDate.css";

const FormInputDateSingleContainer = styled.div`
  display: flex;
  padding: 6px 10px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  border: 0.5px solid #b3b3b3;
  width: 100%;
`;

export const StyledFormInputFont = `
  font-family: inherit;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.256px;
  color: #767778;
`;

const StyledFormInputDatePicker = styled(ReactDatePicker)`
  ${StyledFormInputFont}
  text-transform: uppercase;
  border: 0;
  flex-grow: 1;
  margin: ${({ marginUnset }) => (marginUnset ? "" : "0 10px")};
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

const StyledStandardInputDiv = styled(StandardInputDiv)`
  padding-right: 8px;
  display: flex;
  margin-right: 0 !important;
  margin-top: 40px;
  width:30%;
  justify-content: flex-start;
  // .react-datepicker-wrapper {
  //   z-index: 1;
  // }
  // .react-datepicker__input-container {
  //   margin-top: 2px;
  // }
`;

const StyledSF215InputDiv = styled(StandardInputDiv)`
  padding-right: 8px;
  display: flex;
  margin-right: 0 !important;
  width:20%;
  justify-content: flex-start;
  // .react-datepicker-wrapper {
  //   z-index: 1;
  // }
  // .react-datepicker__input-container {
  //   margin-top: 2px;
  // }
`;

const changeDate = ({ value, onChange }, change) => {
  const d = value ? new Date(value) : new Date();
  onChange(new Date(d.setDate(d.getDate() + change)));
};
const StyledFormInputDateWithButtons = (props) => (
  <Fragment>
    <CalendarSVG />
    <StyledFormInputDatePicker
      selected={props.value || new Date()}
      {...props}
    />
    <LeftRightButtons
      onLeftClick={() => changeDate(props, -1)}
      onRightClick={() => changeDate(props, 1)}
    />
  </Fragment>
);
//with input date single it's not using react-datepicker so I need to add a dateFormat config myself 
export const FormInputDateSingle = ({ key, container, label, style, ...props }) => (
  <StandardContainer key={key} style={style} className="input-date-single-container">
    {label ? <StandardLabel>{label}</StandardLabel> : ""}
    <FormInputDateSingleContainer {...container}>
      <StyledFormInputDateWithButtons {...props} />
    </FormInputDateSingleContainer>
  </StandardContainer>
);

export const FormInputDateInput = ({ key, value, ...rest }) => (
  <StyledStandardInputDiv key={key} className="date">
    <StyledFormInputDatePicker
      marginUnset={true}
      selected={(value && new Date(value)) || new Date()}
      {...rest}
    />
    <CalendarSVG height={19} width={19} />
  </StyledStandardInputDiv>
);

export const FormInputDateSF215 = ({ key, value, ...rest }) => (
  <StyledSF215InputDiv key={key} className="date">
    <StyledFormInputDatePicker
      marginUnset={true}
      selected={(value && new Date(value)) || new Date()}
      {...rest}
    />
    <CalendarSVG height={19} width={19} />
  </StyledSF215InputDiv>
);

