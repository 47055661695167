import React, { Fragment } from "react";
import { PageTitle, Button } from "../Containers";
import { DashboardCards, DashboardCard } from "./DashboardCardStyled";
import DashboardCardConfig from "./DashboardCardConfig";

const Dashboard = ({ data }) => {
  const dataCount = data.cert_ALL_count + data.letter_exp_count;

  return (
    <Fragment>
      <PageTitle>Dashboard</PageTitle>
      {dataCount === 0 ? (
        <div style={{ textAlign: "center" }}>
          <h1>Welcome to the MQSA system!</h1>
          <h1>
            There are no new certificates or letters waiting to be printed.
          </h1>
        </div>
      ) : (
        <DashboardCards>
          {Object.keys(DashboardCardConfig).map((cardKey, index) =>
            data[cardKey] && data[cardKey].length !== 0 ? (
              <Button key={index} to={DashboardCardConfig[cardKey].to}>
                <DashboardCard>
                  {data[cardKey]} {DashboardCardConfig[cardKey].label}
                </DashboardCard>
              </Button>
            ) : null
          )}
        </DashboardCards>
      )}
    </Fragment>
  );
};

export default Dashboard;
