import React, { useState } from "react";
import styled from "styled-components";
import { SearchSVG } from "../Containers";
// import { useAsyncDebounce } from "react-table";

const TableSearchContainer = styled.div`
  background: #fdfdfd;
  border: 0.6px solid #bcbcbc;
  box-sizing: border-box;
  border-radius: 2px;
  height: 35px;
  width: 230px;
  display: flex;
  align-items: center;
`;

const TableSearchInput = styled.input`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  line-height: 18px;
  font-size: 15px;
  border: 0;
  display: flex;
  flex-grow: 1;
  font-weight: 300;
  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    font-style: italic;
    color: #a0a0a0;
  }
`;

function TableSearch({
  params: { search, ...restParams },
  setParams,
  disabled,
}) {
  const [value, setValue] = useState(search || "");
  // const onChange = useAsyncDebounce((value) => {
  //   setParams({ page: 0, search: value });
  // }, 500);
  const onKeyDown = (e) =>
    e.key === "Enter" && setParams({ ...restParams, page: 0, search: value });
  return (
    <TableSearchContainer>
      <SearchSVG style={{ padding: "0 9px 0 16px" }} />
      <TableSearchInput
        type="search"
        placeholder="Search..."
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          // onChange(e.target.value);
        }}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
    </TableSearchContainer>
  );
}

export default TableSearch;
