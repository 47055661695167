import { createGlobalStyle } from "styled-components";

const ThemeProviderGlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    margin: auto;
    display: flex;
  }

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: ${({ theme }) => theme.fontFamily.primary};
    background-color: rgb(251, 253, 255);
  }

  [disabled] {
    opacity: 0.6;
  }
  
  [disabled]:hover,
  [disabled]:focus,
  [disabled]:focus-within {
    opacity: 0.6;
    text-decoration: none !important;
    cursor: auto;
  }
`;

export default ThemeProviderGlobalStyle;
