import React from "react";
import GetMultipleComponent from "../../GetMultipleComponent";
import CreateLogsForm from "./CreateLogsForm";

const CreateLogs = (props) => (
  <GetMultipleComponent
    authority={["test", "test"]}
    resource={["certificate", "certificate"]}
    params={[
      {
        action: "get_cert_ticket_dropdowns",
      },
      {
        action: "get_new_cert_ticket_id",
      },
    ]}
    Component={CreateLogsForm}
    {...props}
  />
);

export default CreateLogs;
