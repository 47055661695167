import React from "react";
import styled from "styled-components";

const FormInputOneContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
`;

const FormInputOneLabel = styled.label`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.288px;
  color: rgb(48, 48, 48);
  margin-bottom: 7px;
`;

const FormInputOneInputContainer = styled.div`
  background: rgb(245, 249, 255);
  border: 0.7px solid rgb(207, 207, 207);
  border-radius: 2px;
  padding: 15px 20px;
  align-items: center;
  display: flex;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.288px;
  svg {
    height: 20px;
    width: 20px;
    fill: rgb(49, 118, 242);
  }
`;

const FormInputOneInput = styled.input`
  margin-left: 15px !important;
  flex-grow: 1;
  background: unset !important;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
`;

const FormInputOne = ({ key, label, SVG, value, ...rest }) => (
  <FormInputOneContainer key={key}>
    <FormInputOneLabel>{label}</FormInputOneLabel>
    <FormInputOneInputContainer>
      <SVG />
      <FormInputOneInput value={value || ""} {...rest} />
    </FormInputOneInputContainer>
  </FormInputOneContainer>
);

export default FormInputOne;
