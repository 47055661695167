import constants from "../constants";

export const getSearchParams = (search, get) => {
  const object = Object.fromEntries(new URLSearchParams(search.split("?")[1]));
  const keys = Object.keys(object);
  const values = Object.values(object);
  const list = values.map((i) => constants.labelAlias[i] || i).join(", ");
  const resolver = { object, keys, values, list };
  return resolver[get] || object[get];
};

export const generateSearchParams = (data) =>
  "?" + new URLSearchParams(data).toString();
