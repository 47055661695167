import React, { Fragment } from "react";
import styled from "styled-components";

export const ModalBackdrop = styled.div`
  position: fixed;
  background-color: black;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
`;
//top: 16%
export const ModalBox = styled.div`
  background: #ffffff;
  mix-blend-mode: normal;
  border: 1px solid #979797;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.modalBox};
  position: fixed;
  width: fit-content;
  height: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  ${({ topMargin }) => (topMargin ? `top: ${topMargin}` : "top: 16%")};
  ${({ presetOne }) =>
    presetOne
      ? `
      display: flex;
      flex-direction: column;
    `
      : ""}
`;

export const Modal = ({ close, ...props }) => (
  <Fragment>
    <ModalBackdrop onClick={close} />
    <ModalBox {...props} />
  </Fragment>
);

export const ModalTitle = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #1e1e1e;
  margin: 0 16px;
  display: flex;
  align-items: center;
`;

export const ModalTitle2 = styled.h1`
  background: linear-gradient(180deg, #7ab3ea 0%, #236bed 100%);
  padding: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
  margin: 0;
  text-align: center;
`;
