import React from "react";
import { Button } from "../Containers";

const TableColumnInvoiceNumber = (props) => ({
  Header: "Invoice Number",
  accessor: (props && props.key) || "invoice_num",
  width: 225,
  Cell: (e) => (
    <Button
      flavor="blueLink"
      target="_blank"
      href={"/invoice/" + e.value}
      style={{ justifyContent: "flex-start" }}
    >
      {e.value}
    </Button>
  ),
});

export default TableColumnInvoiceNumber;
