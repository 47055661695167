import React from "react";
import "./FormDropdownNew.css";
import BaseSelect from 'react-select';
import FixRequiredSelect from "./FixRequiredSelect";

function FormDropdownNewNew({
  key,
  options,
  name,
  label,
  value,
  onChange,
  style,
  caretSVGStyle,
  defaultValue,
  ...rest
}) {
  options = options.map((option, index) => ({
    value: option,
    label: option,
    key: index,
  }))

  return (
    <div style={style}>
    <label htmlFor={name}>{label}</label>
    <FixRequiredSelect
    onChange={onChange}
    {...rest}
    defaultValue={[defaultValue]}
    SelectComponent={BaseSelect}
    options={options}
  />
    </div>
  );
}

export default FormDropdownNewNew;
