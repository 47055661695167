import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { ArrowLeft, ArrowRight } from "../Containers";

const StyledPagination = styled.div`
  ul {
    display: flex;
    list-style: none;
    margin: 25px auto;
    width: 900px;
    justify-content: space-between;
    align-items: center;
  }

  li a:focus {
    outline: 0;
  }

  li a {
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    font-size: 13.77px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 31px;
    color: #52575a;
    cursor: pointer;
  }

  li.active a {
    border: 0.765px solid #3176f2;
  }

  li.previous a,
  li.next a {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.765px solid #94979b;
    box-sizing: border-box;
  }

  li.disabled a {
    opacity: 0.6;
    cursor: auto;
  }
`;

const TablePagination = ({ pageCount, params, setParams }) => (
  <StyledPagination>
    <ReactPaginate
      previousLabel={<ArrowLeft />}
      nextLabel={<ArrowRight />}
      breakLabel="..."
      forcePage={params.page}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={({ selected: newPage }) =>
        setParams({ ...params, page: newPage })
      }
      containerClassName="table-pagination"
      activeClassName="active"
    />
  </StyledPagination>
);

export default TablePagination;
