import { TableColumn } from "../Table";

const PrintShopTableColumns = ({ isExp, isLetter }) => [
  TableColumn.fac_id(),
  {
    Header: "Facility Name",
    accessor: "fac_name",
    width: 400,
  },
  ...(isLetter
    ? [
        {
          Header: "Letter Type",
          accessor: "let_type_code",
          width: 225,
        },
        {
          Header: "Letter Mailed Date",
          accessor: "let_mail_date",
          width: 200,
        },
      ]
    : [
        {
          Header: "Current Certificate Type",
          accessor: "cert_type",
          width: 225,
        },
        {
          Header: "Certificate Type Code",
          accessor: "cert_type_code",
          width: 225,
        },
        {
          Header: "Certificate Number",
          accessor: "cert_num",
          width: 225,
        },
        {
          Header: "Certificate Mail Date",
          accessor: "crt_mail_dt",
          width: 200,
        },
      ]),
  isExp && {
    Header: "Expiration Date",
    accessor: "crt_exp_dt",
    width: 200,
  },
];

export default PrintShopTableColumns;
