import React from "react";

export const LogoutSVG = (props) => (
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2402 22.16H3.4002C2.5202 22.16 1.8002 21.44 1.8002 20.56V3.43999C1.8002 2.55999 2.5202 1.83999 3.4002 1.83999H11.2402C11.6802 1.83999 12.0402 1.47999 12.0402 1.03999C12.0402 0.59999 11.6802 0.23999 11.2402 0.23999H3.4002C1.6402 0.23999 0.200195 1.67999 0.200195 3.43999V20.56C0.200195 22.32 1.6402 23.76 3.4002 23.76H11.2402C11.6802 23.76 12.0402 23.4 12.0402 22.96C12.0402 22.52 11.6802 22.16 11.2402 22.16Z"
      fill="white"
    />
    <path
      d="M20.5601 11.8801C20.5601 11.8401 20.5601 11.8401 20.5201 11.8001C20.5201 11.7601 20.5201 11.7201 20.4801 11.7201C20.4401 11.7201 20.4801 11.6801 20.4401 11.6401C20.4401 11.6001 20.4401 11.6001 20.4001 11.6001V11.5201L15.2801 5.96009C14.9601 5.64009 14.4401 5.64009 14.1201 5.92009C13.8001 6.24009 13.8001 6.72009 14.0801 7.04009L17.9601 11.2001H6.88008C6.44008 11.2001 6.08008 11.5601 6.08008 12.0001C6.08008 12.4401 6.44008 12.8001 6.88008 12.8001H17.9601L14.0801 16.9601C13.8001 17.2801 13.8001 17.7601 14.1201 18.0801C14.2801 18.2401 14.4801 18.3201 14.6801 18.3201C14.9201 18.3201 15.1201 18.2001 15.2801 18.0401L20.3601 12.5601V12.4801C20.4001 12.4801 20.4001 12.4801 20.4001 12.4401C20.4801 12.4001 20.4801 12.4001 20.4801 12.3601C20.5201 12.3601 20.5201 12.3201 20.5201 12.2801C20.5601 12.2401 20.5601 12.2401 20.5601 12.2001C20.5601 12.1601 20.6001 12.0801 20.6001 12.0401C20.6001 12.0001 20.5601 11.9201 20.5601 11.8801Z"
      fill="white"
    />
  </svg>
);
