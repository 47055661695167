import React from "react";
import { Button } from "../Containers";
import constants from "../../constants";

const TableColumnTicketFacId = (props) => ({
  Header: constants.labelAlias.fac_id,
  accessor: (props && props.key) || "fac_id",
  width: 100,
  Cell: (e) => {
    if(e.value === 0) {
      return "N/A"
    }
    else return (
    <Button
      flavor="blueLink"
      target={props && props.openNewTab === false ? "_self" : "_blank"}
      href={"/facilities/" + e.value}
      style={{ justifyContent: "flex-start" }}
    >
      {e.value}
    </Button>);
  },
});

export default TableColumnTicketFacId;
