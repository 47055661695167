import React, { useContext, useEffect } from "react";
import { Button } from "../../Containers";
import { UserContext } from "../../Auth";
import { fetcher, formatDate } from "../../../utils";
import FormInputStandard from "../../Form/FormInputStandard";
import { Loader } from "../../Containers";
import { Comments } from "../Comments";
import { retrieveDropdowns, formDictionary } from "./utils";

import FormDropdownNewNew from "../../Form/FormDropdownNewNew";
import { FormInputDateSingle } from "../../Form";
import FormTextareaOne from "../../Form/FormTextareaOne";

const EditLog = (props) => {
  
    const data = props.data[0].cert_ticket_details;
    const user = useContext(UserContext);

    const[loading, setLoading] = React.useState(false);
    const[dropdownInfo, setDropdownInfo] = React.useState();
    const[category, setCategory] = React.useState({value: data.category, label: data.category});
    const[subcategoryCert, setSubcategoryCert] = React.useState({value: data.subcategory, label: data.subcategory});
    const[subcategoryBill, setSubcategoryBill] = React.useState({value: data.subcategory, label: data.subcategory});
    const[status, setStatus] = React.useState({value: data.status, label: data.status, key: 0 });
    const[disposition, setDisposition] = React.useState({value: data.disposition, label: data.disposition});
    const[source, setSource] = React.useState({value: data.source, label: data.source});
    const[dateCreated, setDateCreated] = React.useState(new Date(data.date_created));
    const[dateClosed, setDateClosed] = React.useState(new Date(data.date_closed));
    const[facilityId, setFacilityId] = React.useState(data.fac_id || 0);
    const[facilityInfo, setFacilityInfo] = React.useState();
    const[callerName, setCallerName] = React.useState({value: "", label: "", key: 0});
    const callerNameIndex = facilityInfo?.poc_list.findIndex((elem) => elem.poc_full_name === callerName.value)
    const[inquirySummary, setInquirySummary] = React.useState(data.inquiry_summary);
    const[reportedBy, setReportedBy] = React.useState({value: data.reported_by, label: data.reported_by});
    const[pocCallerName, setPocCallerName] = React.useState(data.poc_full_name || "");
    const[callerTitle, setCallerTitle] = React.useState(data.poc_title);
    const[callerPhone, setCallerPhone] = React.useState(data.poc_primary_phone);
    const[callerEmail, setCallerEmail] = React.useState(data.poc_email);

    const [showForm, setShowForm] = React.useState(false);
    const[error, setError] = React.useState("");
    

    const callerList = facilityInfo?.poc_list?.map(poc => poc.poc_full_name)
    if(callerList) {
      callerList.push("Add new POC")
    } 
    const name = user && user.username;
    let reported_by = dropdownInfo?.reported_by_list.sort(function(x,y){ return x === name ? -1 : y === name ? 1 : 0; });
  const setCallerMisc = () => {
    setCallerTitle((callerNameIndex !== -1 && callerNameIndex !== undefined) ? facilityInfo?.poc_list[callerNameIndex]?.poc_title : data.poc_title)
    setCallerPhone((callerNameIndex !== -1 && callerNameIndex !== undefined) ? facilityInfo?.poc_list[callerNameIndex]?.poc_primary_phone : data.poc_primary_phone)
    setCallerEmail((callerNameIndex !== -1 && callerNameIndex !== undefined) ? facilityInfo?.poc_list[callerNameIndex]?.poc_email : data.poc_email)
  }

  const resetFacilityInfo = () => {
    setFacilityInfo(current => ({...current, 
      facility_info: {
        fac_id: "",
        fac_name: "",
        fac_addline1: "",
        fac_addline2: "",
        fac_addline3: "",
        fac_addline4: "",
        fac_city: "",
        fac_state: "",
        fac_zip: "",
        fac_phone: "",
        fac_addline1_m: "",
        fac_addline2_m: "",
        fac_addline3_m: "",
        fac_addline4_m: "",
        fac_city_m: "",
        fac_state_m: "",
        fac_zip_m: "",
        notes: ""
      }
    }))
    

  }
  useEffect(() => {
    if(data.fac_id === facilityId) {
      const callerNameIndexFromApiData = facilityInfo?.poc_list.findIndex((elem) => elem.poc_full_name === data.poc_full_name)
      if(callerNameIndexFromApiData === undefined) {
      setCallerName({value: data.poc_full_name, label: data.poc_full_name, key: 0})
      setCallerTitle(data.poc_title)
      setCallerPhone(data.poc_primary_phone)
      setCallerEmail(data.poc_email)
      }
      else {
        setCallerName(callerNameIndexFromApiData !== -1 ? {value: facilityInfo?.poc_list[callerNameIndexFromApiData]?.poc_full_name, label: facilityInfo?.poc_list[callerNameIndexFromApiData]?.poc_full_name, key: callerNameIndexFromApiData} : {value: "", label: "", key: 0})
        setCallerTitle(callerNameIndexFromApiData !== -1 ? facilityInfo?.poc_list[callerNameIndexFromApiData]?.poc_title : "")
        setCallerPhone(callerNameIndexFromApiData !== -1 ? facilityInfo?.poc_list[callerNameIndexFromApiData]?.poc_primary_phone : "")
        setCallerEmail(callerNameIndexFromApiData !== -1 ? facilityInfo?.poc_list[callerNameIndexFromApiData]?.poc_email : "")
      }

    }

  }, [facilityInfo])

  useEffect(() => {
      setCallerMisc();
  }, [callerName])

    useEffect(() => {
      setError("");
      if(showForm && facilityId.toString().length === 6) {
        fetcher
        .get({
          authority: "test",
          path: "/facilities",
          params: {
            action: "get_facility_info",
            fac_id: Number(facilityId)
          }
        })
        .then((responseData) => {
          console.log(responseData);
          if(responseData.facility_info === undefined) {
            setError("Please enter valid Facility ID")
          }
          else {
            setFacilityInfo(responseData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
      else if(showForm && facilityId.toString().length === 0) {
        resetFacilityInfo();
      }
    }, [showForm, facilityId]);

      //<Form {...{ ...formConfig }} />
      const submitForm = (e) => {
        e.preventDefault();
    
        if((facilityId.toString().length === 0 || facilityId.toString().length === 6 || facilityId === 0) && error === "") {
          fetcher.post({
            authority: "test",
            path: "/certificate",
            data: {
              operation: "update_cert_ticket",
              cert_ticket_record: {
                tkt_id: data.tkt_id,
                category: category.value || "",
                subcategory: subcategoryCert.value || subcategoryBill.value || "",   
                status: status.value || "",
                disposition: disposition.value || "", 
                source: source.value || "",
                date_created: formatDate(dateCreated),
                date_closed: formatDate(dateClosed),
                reported_by: reportedBy.value || "",
                inquiry_summary: inquirySummary || "",
                fac_id: Number(facilityId),
                fac_name: facilityInfo?.facility_info?.fac_name || "",
                fac_addline1: facilityInfo?.facility_info?.fac_addline1 || "",
                fac_addline2: facilityInfo?.facility_info?.fac_addline2 || "",
                fac_city: facilityInfo?.facility_info?.fac_city || "",
                fac_state: facilityInfo?.facility_info?.fac_state || "",
                fac_zip: facilityInfo?.facility_info?.fac_zip || "",
                fac_phone:facilityInfo?.facility_info?.fac_phone || "",
                poc_new: (!["0", "N/A", ""].includes(facilityId.toString())) && callerName !== "Add new POC" ? "N": "Y",              
                poc_title: callerTitle || "",
                poc_full_name: (!["0", "N/A", ""].includes(facilityId.toString())) && callerName !== "Add new POC" ? callerName?.value : pocCallerName,
                poc_id: facilityInfo?.poc_list[callerName?.key]?.poc_id || "",
                poc_primary_phone: callerPhone || "",
                poc_email: callerEmail || "",
              }
          }}).then((data) => {
            console.log(data)
            window.location = "/inquiries/certification"
          })
          .catch((responseError) => {
            console.log(responseError);
          });
        }
        else {
          setError("Please put in valid facility ID")
        }
       
    };
  return (
    <div style={{width: "50%", margin: "auto"}}>
      {loading ? <Loader height="200px" width="200px" />
      :
      showForm && loading === false ? 
        <>
        <h1>Edit Log</h1>
        <form onSubmit={submitForm}>
      <FormInputStandard
        label= "Ticket Information"
        input= "headerLabel"
        />
      <FormInputStandard
        label= "Ticket ID"
        name= "ticket_id"
        value={data.tkt_id}
        input="number"
        disabled={true}
        style ={{ marginBottom: 10}}
      />
      <FormDropdownNewNew 
        label= "Category"
        options= {dropdownInfo?.cat_list ?? []}
        name= "category"
        value= {category}
        defaultValue={category}
        onChange={(e) => setCategory(e)}
        style= {{ marginBottom: 10 }}
      />
      {category === "Certification" && 
        <FormDropdownNewNew 
        label= "Sub-category"
        options= {dropdownInfo?.subcat_list.filter(subcat => subcat.cat === "Certification").map(category => category.subcat) ?? []}
        name= "subcategory_certification"
        defaultValue={subcategoryCert}
        value= {subcategoryCert}
        onChange={(e) => setSubcategoryCert(e)}
        style= {{ marginBottom: 10 }}
      />}
      {category === "Billing" && 
        <FormDropdownNewNew 
        label= "Sub-category"
        options= {dropdownInfo?.subcat_list.filter(subcat => subcat.cat === "Billing").map(category => category.subcat) ?? []}
        name= "subcategory_billing"
        value= {subcategoryBill}
        defaultValue={subcategoryBill}
        onChange={(e) => setSubcategoryBill(e)}
        style= {{ marginBottom: 10 }}
      />}
      <FormDropdownNewNew 
        label= "Status"
        options= {dropdownInfo?.status_list ?? []}
        name= "status"
        value= {status}
        defaultValue={status}
        required= {true}
        onChange={ (e) => setStatus(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormDropdownNewNew 
        label= "Disposition"
        options= {dropdownInfo?.dispn_list ?? []}
        name= "disposition"
        value= {disposition}
        defaultValue={disposition}
        onChange={(e) => setDisposition(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormDropdownNewNew 
        label= "Source"
        options= {dropdownInfo?.source_list ?? []}
        name= "source"
        value= {source}
        defaultValue={source}
        onChange={(e) => setSource(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormInputDateSingle
      value={dateCreated}
      label= "Date Created"
      name= "date_created"
      onChange={(e) => setDateCreated(e)}
      required={true}
      style= {{ marginBottom: 10 }}
    />
    <FormInputDateSingle
      value={dateClosed}
      label= "Date Closed"
      name= "date_closed"
      onChange={(e) => setDateClosed(e)}
      style= {{ marginBottom: 10 }}
    />
     <FormDropdownNewNew 
        label= "Reporter"
        options= {reported_by}
        name= "reporter"
        value= {reportedBy}
        defaultValue={reportedBy}
        onChange={(e) => setReportedBy(e)}
        style= {{ marginBottom: 10 }}
      />
      <FormTextareaOne 
        label="Inquiry Summary"
        name="inquirySummary"
        value={inquirySummary}
        onChange={(e) => setInquirySummary(e.target.value)}
      />
      <FormInputStandard
        label= "Facility Information"
        name= "headerLabel"
        input="headerLabel"
      />
      <FormInputStandard
        label= {error}
        input="errorLabel"
      />
      <FormInputStandard
            label= "Facility Number"
            name= "fac_num"
            value={facilityId}
            onChange={(e) => setFacilityId(e.target.value)}
            style ={{ marginBottom: 10}}
          />
      <FormInputStandard
            label= "Facility Name"
            name= "fac_name"
            value={facilityInfo?.facility_info?.fac_name || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
      <FormInputStandard
            label= "Facility Address 1"
            name= "fac_addline1"
            value={facilityInfo?.facility_info?.fac_addline1 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
      <FormInputStandard
            label= "Facility Address 2"
            name= "fac_addline2"
            value={facilityInfo?.facility_info?.fac_addline2 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Address 3"
            name= "fac_addline3"
            value={facilityInfo?.facility_info?.fac_addline3 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Address 4"
            name= "fac_addline4"
            value={facilityInfo?.facility_info?.fac_addline4 || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility City"
            name= "fac_city"
            value={facilityInfo?.facility_info?.fac_city || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility State"
            name= "fac_state"
            value={facilityInfo?.facility_info?.fac_state || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Zip"
            name= "fac_zip"
            value={facilityInfo?.facility_info?.fac_zip || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Facility Phone Number"
            name= "fac_phone_num"
            value={facilityInfo?.facility_info?.fac_phone || ""}
            disabled={true}
            style ={{ marginBottom: 10}}
          />
    <FormInputStandard
            label= "Caller Information"
            input= "headerLabel"
          />
    {(!["0", "N/A", ""].includes(facilityId.toString())) && 
    <FormDropdownNewNew
            label= "Caller Name"
            name= "caller_name"
            value={callerName}
            defaultValue={callerName}
            options={facilityInfo?.poc_list ? callerList : []}
            disabled={facilityInfo?.poc_list ? false : true}
            onChange={(e) => setCallerName(e)}
            style ={{ marginBottom: 10}}
          />
  }
  {(callerName?.value === "Add new POC" || ["0", "N/A", ""].includes(facilityId.toString())) &&
            <FormInputStandard
            label= "Caller Name"
            name= "caller_name"
            value={pocCallerName}
            onChange={(e) => setPocCallerName(e.target.value)}
            style ={{ marginBottom: 10}}
          />}
          <FormInputStandard
            label= "Caller Title"
            name= "caller_title"
            value={callerTitle}
            disabled={(callerName?.value !== "Add new POC" && !["0", "N/A", ""].includes(facilityId.toString()) )? true : false}
            onChange={(e) => setCallerTitle(e.target.value)}
            style ={{ marginBottom: 10}}
          />
          <FormInputStandard
            label= "Caller Phone Number"
            name= "caller_phone_num"
            value={callerPhone}
            disabled={(callerName?.value !== "Add new POC" && !["0", "N/A", ""].includes(facilityId.toString()) ) ? true : false}
            onChange={(e) => setCallerPhone(e.target.value)}
            style ={{ marginBottom: 10}}
          />
          <FormInputStandard
            label= "Caller Email"
            name= "caller_email"
            value={callerEmail}
            disabled={(callerName?.value !== "Add new POC" && !["0", "N/A", ""].includes(facilityId.toString()) ) ? true : false}
            onChange={(e) => setCallerEmail(e.target.value)}
            style ={{ marginBottom: 10}}
          />
      <div style={{display: "flex", flexDirection: "row"}}>
      <Button type="submit" flavor="blueButton" style={{marginRight: 10}}>
        Submit
      </Button>
      <Button onClick={() => window.location = "/inquiries/certification"} flavor="redButton">
        Cancel
      </Button>
      </div>
      </form>
        </>
      :
    <>
      <div style={{display: "flex", flexDirection: "row" }}>
      <h1>Log Preview</h1>
      <Button 
          flavor="blueButton" 
          onClick={() => { setShowForm(!showForm); retrieveDropdowns(setLoading, setDropdownInfo);}}
          style={{marginTop: "auto", marginBottom: "auto", marginLeft: 10}}
          >{showForm ? "Save" : "Edit"}</Button>
        </div>
        <form>
            {Object.entries(data).map(([key, value]) => (
                  <FormInputStandard
                  name={key}
                  label={formDictionary[key]}
                  value={value}
                  disabled={showForm ? false : true}
                  style={{marginBottom: 10}}
                />)
              )}
        </form>
        <Comments comments={props.data[1].cert_ticket_comments} tkt_id={data.tkt_id} username={name}/>
      </>
    }
    </div>
  );
};

export default EditLog;
