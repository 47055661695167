const ThemeProviderConfig = {
  fontFamily: {
    primary: "Lato, sans-serif",
    secondary: "sans-serif",
  },
  zIndex: {
    blueBannerOne: 1,
    header: 3,
    activeMenuTab: 2,
    dropdownOptions: 3,
    modalBackdrop: 4,
    modalBox: 5,
  },
};

export default ThemeProviderConfig;
