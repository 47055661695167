import React from "react";

export const CalendarSVG = (props) => (
  <svg
    width="22"
    height="25"
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.12 1.28e-05H6.4C7.10692 1.28e-05 7.68 0.573082 7.68 1.28001V2.56H14.08V1.28C14.08 0.573069 14.6531 0 15.36 0H16.64C17.3469 0 17.92 0.573069 17.92 1.28V2.56C20.0408 2.56 21.76 4.27923 21.76 6.4V20.48C21.76 22.6008 20.0408 24.32 17.92 24.32H3.84C1.71923 24.32 0 22.6008 0 20.48V6.4C0 4.27923 1.71922 2.56 3.84 2.56V1.28001C3.84 0.573082 4.41307 1.28e-05 5.12 1.28e-05ZM15.36 2.56H16.64V1.27999H15.36V2.56ZM6.4 1.28V2.56H5.12V1.28H6.4ZM3.84 3.84001C2.42615 3.84001 1.28 4.98616 1.28 6.40001V7.68001H20.48V6.40001C20.48 4.98616 19.3339 3.84001 17.92 3.84001H3.84ZM3.84 23.04C2.42615 23.04 1.28 21.8939 1.28 20.48V8.96001H20.48V20.48C20.48 21.8939 19.3339 23.04 17.92 23.04H3.84ZM11.52 14.08H17.92V20.48H11.52V14.08ZM12.8 19.2V15.36H16.64V19.2H12.8Z"
      fill="#3176F2"
    />
  </svg>
);
