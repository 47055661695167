import constants from "../../constants";

const sendFetchRequest = ({
  authority,
  path,
  Authorization,
  resolve,
  reject,
  ...config
}) =>
  fetch(constants.api.authority[authority] + path, {
    ...config,
    headers: { ...config.headers, Authorization },
  })
    .then((response) => {
      if (!response.ok) {
        console.log(response)
        throw Error(response.statusText || response.status.toString());
      }
      return response.text();
    })
    .then((responseText) => {
      try {
        const data = JSON.parse(responseText);
        resolve(data);
      } catch {
        try {
          const data2 = JSON.parse(
            responseText.substring(1, responseText.length - 1)
          );
          resolve(data2);
        } catch {
          resolve(responseText);
        }
      }
    })
    .catch(reject);

export default sendFetchRequest;
