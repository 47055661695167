import React, { Fragment } from "react";
import styled from "styled-components";
import { Button, NextSVG, InfoSVG } from "../Containers";

const color = "#6AD4D0";

const Container = styled.div`
  display: flex;
  svg {
    fill: ${color};
  }
`;

const StyledButton = styled(Button)`
  flex: 1;
  opacity: ${({ mockDisable }) => (mockDisable ? "0.5" : "")};
  font-size: 17px;
  color: #fff;
  background-color: ${color};
  padding: 10px 0;
  box-sizing: border-box;
  border-radius: 10px;
`;

const StyledP = styled.p`
  display: flex;
  background-color: #eee;
  padding: 10px 15px;
  line-height: 20px;
  opacity: 0.6;
  font-size: 13px;
  margin: 29px 0;
  border-radius: 5px;
  svg {
    margin-right: 5px;
  }
`;

const PrintShopProgressBar = ({
  progressBar,
  state: { currentIndex },
  updateState,
}) => (
  <div>
    <Container>
      {progressBar.map(({ title, helperText }, index) => {
        const mockDisable = index > currentIndex;
        return (
          <Fragment key={index}>
            {index ? (
              <NextSVG height={50} width={50} disabled={mockDisable} />
            ) : (
              ""
            )}
            <StyledButton
              mockDisable={mockDisable}
              onClick={() => updateState({ currentIndex: index })}
            >
              {index + 1}. {title}
            </StyledButton>
          </Fragment>
        );
      })}
    </Container>
    {progressBar[currentIndex] ? (
      <StyledP>
        <InfoSVG height={20} width={20} />
        {progressBar[currentIndex].helperText}
      </StyledP>
    ) : null}
  </div>
);

export default PrintShopProgressBar;
