import React from "react";
import styled from "styled-components";

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: rgb(243, 248, 255);
`;

const BackgroundBlueBannerTop = styled.div`
  background: #3176f2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 80px;
  width: 100%;
`;

const BackgroundBlueBannerBottom = styled.div`
  background: #3176f2;
  height: 24px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const AuthBackground = (props) => (
  <BackgroundContainer>
    <BackgroundBlueBannerTop />
    <Content {...props} />
    <BackgroundBlueBannerBottom />
  </BackgroundContainer>
);

export default AuthBackground;
