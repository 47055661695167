import React from "react";
import styled from "styled-components";
import { SuccessSVG, AlertSVG } from "../Containers";

const FormMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-weight: 400;
  border-radius: 4px;
  width: 100%;
  word-break: break-word;
  font-size: 15px;
  box-sizing: border-box;
  svg {
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;
    margin-right: 10px;
  }
`;

const StyledFormMessageSuccess = styled(FormMessage)`
  background-color: #98fb984d;
  color: #227d22;
  margin-bottom: 20px;
`;

const StyledFormMessageError = styled(FormMessage)`
  background-color: #ff000021;
  color: #8e0000;
  margin-top: 20px;
`;

export const FormMessageSuccess = ({ message, ...rest }) =>
  message ? (
    <StyledFormMessageSuccess className="message-success" {...rest}>
      <SuccessSVG />
      {message}
    </StyledFormMessageSuccess>
  ) : null;

export const FormMessageError = ({ error: message, ...rest }) =>
  message ? (
    <StyledFormMessageError className="message-error" {...rest}>
      <AlertSVG />
      {message}
    </StyledFormMessageError>
  ) : null;
