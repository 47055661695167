import React, { useRef, useEffect } from "react";
import { DropdownOptionsContainer } from "./DropdownContainers";
import DropdownOption from "./DropdownOption";

function DropdownOptions({ options, setShowDropdown }) {
  const ref = useRef();
  const handleClick = (e) => setShowDropdown(ref.current.contains(e.target));
  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  });
  return (
    <DropdownOptionsContainer ref={ref}>
      {options.map((option, index) => (
        <DropdownOption
          key={index}
          setShowDropdown={setShowDropdown}
          {...option}
        />
      ))}
    </DropdownOptionsContainer>
  );
}

export default DropdownOptions;
