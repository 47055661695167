import React from "react";

export const PasswordSVG = (props) => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.288 6.14399C13.9846 6.14399 15.36 7.51938 15.36 9.21598V17.408C15.36 19.1046 13.9846 20.48 12.288 20.48H3.072C1.37538 20.48 0 19.1046 0 17.408V9.21598C0 7.51938 1.37538 6.14399 3.072 6.14399V4.60799C3.072 2.06306 5.13506 0 7.68 0C10.2249 0 12.288 2.06306 12.288 4.60799V6.14399ZM3.072 7.16799C1.94092 7.16799 1.024 8.08491 1.024 9.21598V17.408C1.024 18.5391 1.94092 19.456 3.072 19.456H12.288C13.4191 19.456 14.336 18.5391 14.336 17.408V9.21598C14.336 8.08491 13.4191 7.16799 12.288 7.16799H3.072ZM11.264 4.60798V6.14398H4.096V4.60798C4.096 2.6286 5.70061 1.02399 7.68 1.02399C9.65939 1.02399 11.264 2.6286 11.264 4.60798ZM7.68 12.288C6.83172 12.288 6.144 12.9757 6.144 13.824C6.144 14.6723 6.83172 15.36 7.68 15.36C8.52828 15.36 9.216 14.6723 9.216 13.824C9.216 12.9757 8.52828 12.288 7.68 12.288ZM10.24 13.824C10.24 12.4101 9.09384 11.264 7.68 11.264C6.26616 11.264 5.12 12.4101 5.12 13.824C5.12 15.2378 6.26616 16.384 7.68 16.384C9.09384 16.384 10.24 15.2378 10.24 13.824Z"
      fill="#3176F2"
    />
  </svg>
);
