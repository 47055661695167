import React, { Fragment } from "react";
import TableSearch from "./TableSearch";
import { TableCounter } from "./TableCounter";
import TableSelectReset from "./TableSelectReset";
import TableStyled from "./TableStyled";
import TableCore from "./TableCore";
import TablePagination from "./TablePagination";
import { Loader, Placeholder } from "../Containers";
import styled from "styled-components";

const TableTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const Table = ({
  excludePlaceholder,
  excludeSort,
  columns = [],
  selectColumnProps,
  params,
  data: { current_page: data = [], page_count: pageCount = 0, total_count = 0 },
  includeSearch,
  setParams,
  pending,
}) => (
  <Fragment>
    <TableTop>
      {includeSearch ? (
        <TableSearch params={params} setParams={setParams} disabled={pending} />
      ) : null}
      {total_count ? (
        <TableCounter
          pending={pending}
          page={params.page}
          rows={data.length}
          total_count={total_count}
        />
      ) : null}
    </TableTop>
    <TableSelectReset selectProps={selectColumnProps} />
    {data && data.length ? (
      <TableStyled>
        <TableCore
          excludeSort={excludeSort}
          columns={columns}
          selectColumnProps={selectColumnProps}
          data={data}
          params={params}
          setParams={setParams}
          pending={pending}
        />
        {pending && <Loader id="table-loader" />}
      </TableStyled>
    ) : (
      !excludePlaceholder && (
        <Placeholder
          title="No results."
          subtitle="No items were found matching your search request."
          pending={pending}
        />
      )
    )}
    {pageCount ? (
      <TablePagination
        pageCount={pageCount}
        params={params}
        setParams={setParams}
      />
    ) : null}
  </Fragment>
);

export default Table;
