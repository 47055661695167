export const formatDate = (date) => {
    if (typeof date === "string" && date.indexOf("/") !== -1) {
      let ff = date.split("/");
      return `${ff[2]}-${ff[0]}-${ff[1]}`;
    }
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    console.log([year, month, day].join("-"));
    return [year, month, day].join("-");
  };