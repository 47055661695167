import React, { useState } from "react";
import { DropdownContainer } from "./DropdownContainers";
import { Button, DownCaretSVG, DownCaretAltSVG } from "../Containers";
import DropdownOptions from "./DropdownOptions";

function Dropdown({
  disabled,
  buttonStyle,
  caret,
  caretAlt,
  options,
  children,
  ...rest
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const caretStyle = { position: "absolute", right: "15px" };
  return (
    <DropdownContainer {...rest}>
      <Button
        disabled={disabled}
        onClick={() => setShowDropdown(!showDropdown)}
        style={{ position: "relative", ...buttonStyle }}
        className="dropdown"
      >
        {children}
        {caret ? (
          <DownCaretSVG style={caretStyle} />
        ) : caretAlt ? (
          <DownCaretAltSVG style={caretStyle} />
        ) : (
          ""
        )}
      </Button>
      {!disabled && showDropdown && options && options.length && (
        <DropdownOptions options={options} setShowDropdown={setShowDropdown} />
      )}
    </DropdownContainer>
  );
}

export default Dropdown;
