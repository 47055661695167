import React from "react";
import styled from "styled-components";

const MAX_PAGE_SIZE = 12; // This should be consistent with the back-end

const StyledDiv = styled.div`
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #b8b8b8;
`;

export function TableCounter({ pending, page, rows, total_count }) {
  const prevItemTotal = MAX_PAGE_SIZE * page;
  return rows ? (
    <StyledDiv>
      {pending
        ? "Loading..."
        : `Showing ${prevItemTotal + 1} - ${prevItemTotal +
            rows} of ${total_count} items`}
    </StyledDiv>
  ) : null;
}
