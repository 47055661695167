import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LogoSVG } from "../Containers";
import constants from "../../constants";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #3176f2 !important;
  text-decoration: none;
`;

const StyledLogoSVG = styled(LogoSVG)`
  margin-right: 10px;
  height: 30px;
  width: 30px;
`;

const StyledHeader = styled.h1`
  font-weight: 300;
  font-size: 24.576px;
  line-height: 29px;
  text-align: justify;
  letter-spacing: 0.49152px;
  margin: 0;
`;

const HeaderHomeButton = () => (
  <StyledLink to="/" title="Dashboard">
    <StyledLogoSVG />
    <StyledHeader>{constants.application.name.short}</StyledHeader>
  </StyledLink>
);

export default HeaderHomeButton;
