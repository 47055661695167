import React from "react";
import GetComponent from "../GetComponent";
import Facility from "./Facility";

const GetFacilities = (props) => (
  <GetComponent
    authority="test"
    resource="facilities"
    params={{
      action: "get_poc_for_facility",
      fac_id: props.match.params.fac_id,
    }}
    Component={Facility}
    {...props}
  />
);

export default GetFacilities;
