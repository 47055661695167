import React from "react";
import { Button } from "../Containers";

const TableColumnReconciledPayments = (props) => {
  console.log("props");
  console.log(props);
  return {
    Header: "Download File",
    accessor: (props && props.key) || "file_url",
    width: 225,
    Cell: (e) => (
      <Button
        flavor="blueButton"
        href={e.value}
        style={{
          backgroundColor: "#3176F2",
          color: "#FFFFFF",
          textDecoration: "none",
        }}
      >
        Download File
      </Button>
    ),
  };
};

export default TableColumnReconciledPayments;
