import React from "react";
import { Button } from "../Containers";

const TableColumnSelectRows = ({ onClick, ...rest }) => {
  return {
    id: "selectRow",
    Cell: (e) => (
      <Button
        onClick={() => onClick(e)}
        flavor="blueButton"
        href={e.value}
        style={{
          backgroundColor: "#3176F2",
          color: "#FFFFFF",
          textDecoration: "none",
        }}
      >
        Select Row
      </Button>
    ),
  };
};

export default TableColumnSelectRows;
