import React, { useState } from "react";
import { DashboardForm, LabelOne } from "../Containers";
import { Form } from "../Form";

function PrintShopDashboardForm({ labelText }) {
  const [interimType, setInterimType] = useState("");
  const PrintShopLookupFormConfig = {
    id: "interim-cert",
    componentsDirection: "row",
    components: [
      [
        {
          component: "FormInputStandard",
          label: "Facility ID",
          name: "fac_id",
          required: true,
        },
      ],
      [
        {
          component: "FormDropdownNew",
          label: "Interim Type",
          name: "interim_type",
          required: true,
          style: { width: 525, fontSize: 14, marginLeft: 0 },
          caretSVGStyle: { top: 0 },
          options: [
            {
              value: "45-day Awaiting Receipt (Interim B)",
              label: "45-day Reinstated - Interim B",
            },
            {
              value: "Provisional Reinstatement (Interim H)",
              label: "On-Site Survey (SOSS) - Interim H",
            },
            {
              value: "45-day Undergoing Renewal (Interim I)",
              label: "45-day Reaccrediting - Interim I",
            },
          ],
          onChange: (e) => setInterimType(e),
        },
      ],
      [
        {
          component: "FormSubmitButtonBlueRed",
          submitButton: {
            label: "CREATE INTERIM CERTIIFCATE",
            name: "submit_print_shop_interim_cert",
            title: "Submit Interim Certificate Form",
            onSubmit: {
              customHandler: ({ values }) =>
                (window.location =
                  "/printshop/" + interimType + "/" + values.fac_id),
            },
          },
        },
      ],
    ],
  };

  return (
    <DashboardForm width="600px">
      <LabelOne>{labelText}</LabelOne>
      <Form {...PrintShopLookupFormConfig} style={{ marginTop: 25 }}></Form>
    </DashboardForm>
  );
}

export default PrintShopDashboardForm;
