import React from "react";
import styled from "styled-components";
import Loader from "./Loader";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  position: relative;
`;

const StyledHeader = styled.h1`
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #3176f2;
`;

const StyledSubHeader = styled.h2`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
  margin-top: 30px;
`;

export const Placeholder = ({ title, subtitle, pending }) => (
  <StyledDiv>
    <StyledHeader>{title}</StyledHeader>
    <StyledSubHeader>{subtitle}</StyledSubHeader>
    {pending && <Loader style={{ position: "absolute", top: "140px" }} />}
  </StyledDiv>
);

export const NotFound = () => (
  <Placeholder title="404" subtitle="Page not found." />
);
