import React, { useState } from "react";
import styled from "styled-components";
import { PageSubtitle, Button, Modal, ModalTitle2 } from "../Containers";
import { number as simpulNumber } from "simpul";
import { Form } from "../Form";
import PrintShopLookupPrintForm from "./PrintShopLookupPrintForm";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  form {
    margin-bottom: 40px;
    .checkbox-standard-container,
    .checkbox-standard-label,
    .input-date-single-container,
    .dropdown {
      margin: 10px 0;
    }
    .input-date-single-container,
    .dropdown,
    .dropdown div {
      width: 200px;
    }
    button[type="submit"] {
      margin-top: 30px;
    }
  }
`;

const MetaTitle = styled.h2`
  font-weight: normal;
  margin: 0;
  margin-bottom: 10px;
  border-bottom: solid 1px;
  padding-bottom: 9px;
`;

const StyledDiv2 = styled.div`
  background-color: #eee;
  padding: 10px 20px;
  margin: 20px 0;
  div {
    display: flex;
    p {
      margin: 10px 0;
    }
    p:first-child {
      width: 200px;
      font-weight: bold;
    }
  }
`;

const StyledModal = styled(Modal)`
  width: 550px;
  form {
    padding: 45px 40px;
    box-sizing: border-box;
    div {
      flex-direction: row;
      align-items: center;
    }
    div:not(:first-child):not(:last-child) {
      margin-top: 12px;
    }
    label {
      width: 200px;
      padding-right: 25px;
      text-align: right;
    }
    .date,
    input {
      flex-grow: 1;
    }
    .date,
    .date div {
      margin-top: unset !important;
    }
    .submit {
      margin-top: 40px;
      justify-content: center;
      button {
        width: 110px;
      }
    }
  }
`;

function PrintShopLookupPrint({ setDoc, ...doc }) {
  const metaTitle = doc.cert_num ? "Certificate/Letter" : "";

  const labelKeys = {
    cert_type: "Current Certificate Type",
    item_type: "Item Type",
    cert_num: "Certificate Number",
    mail_date: "Mailed Date",
    copy_number: "Copy Number",
    orig_bill_date: "Original Bill Mailed Date",
    invoice_num: "Invoice Number",
    orig_bill_amt: "Bill Amount",
    rem_amt: "Remaining Amount",
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openSendingModal, setOpenSendingModal] = useState(false);

  return (
    <StyledDiv>
      <PageSubtitle>
        Please confirm document information and select your printing options
        before submitting your print job.
      </PageSubtitle>
      <br />
      <Button
        flavor="blueLink"
        style={{ width: "fit-content" }}
        onClick={() => setDoc()}
      >
        {"<"} Go Back
      </Button>
      <br />
      <StyledDiv2>
        <MetaTitle>
          {metaTitle ? metaTitle + " " : metaTitle}Information
        </MetaTitle>
        {Object.keys(labelKeys).map(
          (labelKey, key) =>
            doc[labelKey] && (
              <div key={key}>
                <p>{labelKeys[labelKey] + ": "}</p>
                <p>
                  {labelKey.includes("amt")
                    ? simpulNumber.finance(doc[labelKey])
                    : doc[labelKey]}
                </p>
              </div>
            )
        )}
      </StyledDiv2>
      <MetaTitle>
        {metaTitle ? metaTitle + " " : metaTitle} Printing Options:
      </MetaTitle>
      <Form
        {...{
          ...PrintShopLookupPrintForm({ doc, setOpenConfirmationModal, setOpenSendingModal }),
        }}
      />
      {openConfirmationModal && (
        <StyledModal>
          <ModalTitle2>Successful Print Copy</ModalTitle2>
          <form>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>
                The {doc.item_type} copy was printed successfully! You will be
                redirected to the print shop page.
              </p>
            </div>
          </form>
        </StyledModal>
      )}
      {openSendingModal && (
        <StyledModal>
          <ModalTitle2>Sending Copy Print Request to Printer</ModalTitle2>
          <form>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>
              Your request to print the {doc.item_type} copy was sent to the printer. Waiting for response.....
              </p>
            </div>
          </form>
        </StyledModal>
      )}
    </StyledDiv>
  );
}

export default PrintShopLookupPrint;
