import React, { Fragment, useState } from "react";
import { Button } from "../Containers";
import { Table } from "../Table";
import LogsColumns from "./LogsColumns";
import FormInputStandard from "../Form/FormInputStandard";
import { FormInputDateSingle } from "../Form";
import { fetcher, formatDate } from "../../utils";
import { Loader } from "../Containers";

const LogsTable = (props) => {

  const [ticketIdFilter, setTicketIdFilter] = useState("");
  const [facilityIdFilter, setFacilityIdFilter] = useState("");
  const [dateCreatedFilter, setDateCreatedFilter] = useState("");
  const [dateClosedFilter, setDateClosedFilter] = useState("");
  
  const [loading, setLoading] = useState(false);
  
 const createParamsObject = () => {
   const params = { action: "get_cert_ticket_logs"}
    if(ticketIdFilter !== "") {
      params.tkt_id = Number(ticketIdFilter)
    }
    if(facilityIdFilter !== "") {
      params.fac_id = Number(facilityIdFilter)
    }

    if(dateCreatedFilter !== "") {
      console.log(dateCreatedFilter)
      params.date_created = formatDate(dateCreatedFilter)
    }

    if(dateClosedFilter !== "") {
      params.date_closed = formatDate(dateClosedFilter)
    }
    return params
 }
  const applyFilters = () => {
    setLoading(true);
    fetcher
        .get({
          authority: "test",
          path: "/certificate",
          params: createParamsObject()
        })
        .then((responseData) => {
          console.log(responseData);
          props.setData(responseData)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
  }
  return (
    <Fragment>
      <Button flavor="blueButton" to="/inquiries/certification/create">+ Create New Log</Button>
      <p>Filter by Ticket ID, Facility ID, Date Created or Date Closed</p>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      <FormInputStandard 
        name="ticket-id-filter" 
        label="Ticket ID"
        value={ticketIdFilter}
        onChange={(e) => setTicketIdFilter(e.target.value)}
              />
      <FormInputStandard 
        name="facility-id-filter" 
        label="Facility ID"
        value={facilityIdFilter}
        onChange={(e) => setFacilityIdFilter(e.target.value)}
              />
      <div style={{display: "block"}}>
      <FormInputDateSingle
      label="Date Created"
      value={dateCreatedFilter}
      onChange={(e) => setDateCreatedFilter(e)}
    />
    <Button 
        flavor="blueButton" 
        onClick={() => setDateCreatedFilter("")}
        style={{marginTop: 10 }}
        >Reset Date Created</Button>
        </div>
    <div style={{display: "block"}}>
    <FormInputDateSingle
      label="Date Closed"
      value={dateClosedFilter}
      onChange={(e) => setDateClosedFilter(e)}
    />
    <Button 
        flavor="blueButton" 
        onClick={() => setDateClosedFilter("")}
        style={{marginTop: 10 }}
        >Reset Date Closed</Button>
    </div>
    <Button 
        flavor="blueButton" 
        onClick={applyFilters}
        style={{marginTop: 21, marginBottom: "auto"}}
        >Apply Filters</Button>
    </div>
    {loading ? <Loader height="200px" width="200px" />
    : <Table columns={LogsColumns} {...props} />}
    
    </Fragment>
  );
};

export default LogsTable;
